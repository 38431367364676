import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import { Typography, Box, Grid, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { EstFinancialSummaryModal } from "./estimator-financial-summary-modal";
import { AppDispatch } from "../../../shared/state/store";
import { setEstimator } from "../../state/estimator-slice";
import useIsClientConfiguredForNewEstimate from "src/admissions-advisor/utils/useClientConfigHook";

export function SidebarTotalEstimatedPfr() {

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    newEstimate: useSelector((state: RootState) => {
      return state.admissionsAdvisorContext.estimatorContext.newEstimate
    }),
  };

	const {
		vobState,
		estimatorState,
    newEstimate
	} = state;

  const isClientConfiguredForNewEstimates = useIsClientConfiguredForNewEstimate()

	const [financialSummaryModalOpen, setFinancialSummaryModalOpen] = useState(false);
	const [totalEstimatedPfrSelected, setTotalEstimatedPfrSelected] = useState(false);
	const [totalPFR, setTotalPFR] = useState<number>(0.00);
	const dispatch = useDispatch<AppDispatch>();
	const PFRName = "Total Estimated PFR";

	useEffect(() => {
    getPFR();
    if (totalPFR !== estimatorState.totalEstimatedPfr) {
      let estimatorStateCopy = {...estimatorState};
      estimatorStateCopy.totalEstimatedPfr = totalPFR;
      dispatch(setEstimator(estimatorStateCopy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState, newEstimate]);

	const handleTotalEstimatedPfrSelected = () => {
    setTotalEstimatedPfrSelected(!totalEstimatedPfrSelected);
		if (estimatorState.selectedPFRName !== PFRName) {
			let estimatorStateCopy = { ...estimatorState };
			estimatorStateCopy.selectedPFRName = PFRName;
			dispatch(setEstimator(estimatorStateCopy));
		}
  }

	const getSideBarColor = (): string => {
    let sideBarColor = (estimatorState.selectedPFRName === PFRName)? "#6EACDE" : "#999999";
    return sideBarColor;
  };

	const handleFinSumModalCancel = () => {
    setFinancialSummaryModalOpen(false);
  };

	const openFinancialSummaryModal = () => {
		setFinancialSummaryModalOpen(true);
	}

  const getPFR = () => {
    const financialSummary = isClientConfiguredForNewEstimates && newEstimate?.summary ? newEstimate.summary : admissionsAdvisorUtils.calculateFinancialSummary(estimatorState, vobState);
    if (financialSummary.totalPFR !== totalPFR) {
      setTotalPFR(financialSummary.totalPFR);
    }
  }

  const summaryExists = (estimatorState?.selectedLevelsOfCare?.length > 0 && !estimatorState.planYearCrossover?.hasPlanYearCrossover) || (isClientConfiguredForNewEstimates && newEstimate?.facilityLevelOfCare && newEstimate?.facilityLevelOfCare?.length > 0)

	return (
		<>

			{summaryExists && (
				<>
          <Box
            border={1}
            borderColor={getSideBarColor()}
						style={{
							backgroundColor: "white",
							marginBottom: "1.5em",
						}}
          >
            <Grid container direction="row">
              <Grid item direction="column" md={12}></Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
                item
                direction="column"
                md={12}
								xs={12}
                style={{
                  color: "white",
                  backgroundColor: getSideBarColor(),
                  padding: "0.25em",
                }}
              >
                Patient Financial Responsibility
              </Grid>
              <Grid
                item
                direction="column"
                md={12}
                style={{
                  padding: "1em",
                }}
              >
                <Typography variant="body2" style={{ float: "left" }}>
                  Total Estimated PFR
                </Typography>

                <FormControlLabel
                  checked={estimatorState.selectedPFRName === PFRName}
                  control={<Checkbox color="primary" />}
                  id="totalPFR"
                  name="totalPFR"
                  label={<strong>{formatNumberToUSD(totalPFR)}</strong>}
                  labelPlacement="start"
                  style={{ float: "right" }}
                  onChange={() => {
                    handleTotalEstimatedPfrSelected();
                  }}
                />

              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
								item
								direction="column"
								md={12}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<IconButton
									color="primary"
                  title="Open Details"
                  style={{ fontSize: "1.5rem" }}
                  onClick={openFinancialSummaryModal}
                >
                  <OpenInNewIcon fontSize="small" /> Open Details
                </IconButton>
							</Grid>
            </Grid>
          </Box>

					{financialSummaryModalOpen && (
						<EstFinancialSummaryModal
							open={financialSummaryModalOpen}
							handleFinancialSummaryModalCancel={handleFinSumModalCancel }
						/>
					)}

				</>

      )}

		</>
	)
	


}