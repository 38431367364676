import { Button, TextField } from "@finpay-development/shared-components";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
  Card,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { configGetPayers } from "../../../admin-configuration/state/admin-configuration-thunk";
import { getClientFacilities } from "../../../implementation-specialist/state/facility/implementation-facility-thunk";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { Utils } from "../../../shared/utils";
import { PfrEstimator } from "../../models/estimator";
import {
  patientIocResult,
  patientSearchResult,
  vobPatientPostBody,
} from "../../models/patient";
import { Vob, VobPostBody } from "../../models/vob";
import { clearEstimator, setEstimator } from "../../state/estimator-slice";
import { clearPatient, setPatient } from "../../state/vob-patient-slice";
import { searchAAPatient } from "../../state/vob-patient-thunk";
import { clearVOB, setVOB } from "../../state/vob-slice";
import { clearRiskAssessment } from "../../state/risk-assessment-slice";
import { getEstimatorFacilityPayers } from "../../state/vob-thunk";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import classes from "../patient-search/patient-search.module.css";
import { formatNumberToUSD } from "../../../shared/calculators";
import moment from "moment";
import { patientService } from "../../../patient/services/patient-service";
import { ClientCrm, ClientStatusCardViewModel } from "../../../shared/model/client-status-card";

interface AApatientSearchModalProps {
  open: boolean;
  handlePatSearchModalCancel: () => void;
  handleUpdatePatientFormik: (patientState: vobPatientPostBody) => void;
  updateVOBOnPatientSelection: (clientId: number | undefined) => void
  handleUpdateVobFormik: (vobBody: Vob) => void;
  handleEstimatorTabClick: (tab: number) => void;
}

interface patientSelection {
  dataId: string;
  index: string;
  patientId: number;
  vobId?: number;
  estimateId?: number;
  iocId?: number;
}

export function AApatientSearchModal(props: AApatientSearchModalProps) {
  const {
    open,
    handlePatSearchModalCancel,
    handleUpdatePatientFormik,
    updateVOBOnPatientSelection,
    handleUpdateVobFormik,
    handleEstimatorTabClick,
  } = props;
  const [patientsList, setPatientsList] = useState<any[]>([]);
  const [patientsCount, setPatientsCount] = useState(0);
  const [prvSearchTerm, setPrvSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [openSubTable, setOpenSubTable] = React.useState("");
  const [selectIconId, setSelectIconId] = React.useState("");

  const dispatch = useDispatch<AppDispatch>();
  const paramId: number = -2;

  const iocIcon = (
    <MedicalServicesIcon
      sx={{ fontSize: "1.1em", display: "inline" }}
      style={{ color: "blue" }}
    />
  );
  const estIcon = (
    <TaskAltIcon
      sx={{ fontSize: "1.1em", display: "inline" }}
      color="success"
    />
  );
  const vobIcon = (
    <AssignmentIcon
      sx={{ fontSize: "1.1em", display: "inline" }}
      style={{ color: "black" }}
    />
  );

  const iocIconLarge = (
    <MedicalServicesIcon
      sx={{ fontSize: "1.5em", display: "inline" }}
      style={{ color: "blue" }}
    />
  );
  const estIconLarge = (
    <TaskAltIcon
      sx={{ fontSize: "1.5em", display: "inline" }}
      color="success"
    />
  );
  const liveVobIconLarge = (
    <PermPhoneMsgIcon
      sx={{ fontSize: "1.5em", display: "inline" }}
      style={{ color: "black" }}
    />
  );
  const manualVobIconLarge = (
    <TouchAppIcon
      sx={{ fontSize: "1.5em", display: "inline" }}
      style={{ color: "black" }}
    />
  );

  const aaPatientSearchSchema = Yup.object({
    patientSearch: Yup.string().required("Required"),
  });

  function checkIsFormValid(value: {}) {
    aaPatientSearchSchema.validate(value);
  }

  const aaPatientSearchFormik = useFormik({
    initialValues: {
      patientSearch: "",
    },
    validate: checkIsFormValid,
    validationSchema: aaPatientSearchSchema,
    onSubmit: () => {},
  });

  const state = {
    patientSearchResults: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.patientSearchResults
    ),
    isLoadingSearchPatient: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.isPatientLoading
          .searchPatient
    ),
    isLoadingFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.isLoading
    ),
    isLoadingFacilityLocPayers: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobContext.isLoading
          .isLoadingFacilityLocPayers
    ),
    payers: useSelector(
      (state: RootState) => state.adminContext.adminConfigurationContext?.payers
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
    allClients: useSelector(
      (state: RootState) =>
          state.implementationContext?.implementationSpecialistClient
              .allClientsWithFacillities
    ),
  };

  const {
    patientSearchResults,
    payers,
    isLoadingFacilities,
    isLoadingFacilityLocPayers,
    isLoadingSearchPatient,
    estimatorState,
    vobPatientState,
    allClients
  } = state;

  useEffect(() => {
    dispatch(configGetPayers(paramId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patientSearchResults) {
      if (patientSearchResults?.data.length > 0) {
        setPatientsList(patientSearchResults?.data);
      } else {
        setPatientsList([]);
      }
      setPatientsCount(patientSearchResults.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSearchResults]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (searchTerm !== prvSearchTerm) {
          setPrvSearchTerm(searchTerm);
          controller.page = 0;
        }
        if (searchTerm !== "" && searchTerm.length >= 3) {
          let config = {
            paramId: paramId,
            page: controller.page * controller.rowsPerPage,
            limit: controller.rowsPerPage,
            searchTerms: searchTerm,
          };
          dispatch(searchAAPatient(config));
        } else {
          setPatientsList([]);
          setPatientsCount(0);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [controller, dispatch, paramId, searchTerm, prvSearchTerm]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleHasPriorIOC = (patientEncounterId: number) => {
    let estimatorStateCopy = { ...estimatorState };
    const finPayObject = {
      patientEncounterId: patientEncounterId,
      isExistingIOC: true,
    };
    estimatorStateCopy.finPay = finPayObject;
    dispatch(setEstimator(estimatorStateCopy));
  };

  function handleCancelCallback() {
    handlePatSearchModalCancel();
  }

  const debouncedChangeHandler = useMemo(() => {
    return debounce(santizeSearchString, 750);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function santizeSearchString(e: Event) {
    const searchString = (e.target as HTMLInputElement).value;
    const sanitizedInput = admissionsAdvisorUtils.sanitizeInput(searchString);
    setSearchTerm(sanitizedInput);
  }

  const loadSelectedPatientOnly = async (selectedPatient: patientSelection) => {
    const selectedPatientData = patientSearchResults.data?.find(
      (data) => data._id === selectedPatient.dataId
    );

    if (selectedPatientData) {
      dispatch(clearVOB());
      dispatch(clearEstimator());
      dispatch(clearRiskAssessment());

      // handle Admission Advisor Patient
      if (selectedPatient.index === "advisor_patient") {
        if (selectedPatient.patientId) {
          const patientState: vobPatientPostBody = {
            firstName: selectedPatientData._source.firstName,
            lastName: selectedPatientData._source.lastName,
            birthDate: selectedPatientData._source.birthDate,
            advisorPatientId: selectedPatientData._source.advisorPatientId,
            fpPatientId: selectedPatientData._source.fpPatientId,
            clientId: selectedPatientData._source.clientId
          };

          if (selectedPatientData._source.advisorPatientBody) {
            patientState.advisorPatientBody =
              selectedPatientData._source.advisorPatientBody;
          }
          dispatch(setPatient(patientState));
          handleUpdatePatientFormik(patientState);
          updateVOBOnPatientSelection(patientState.clientId)
        }
      } else {
        const iocs = selectedPatientData._source.iocs!
        const clientIdFromIOC = iocs.length === 0 ? undefined : iocs[0].clientId

        const patientState: vobPatientPostBody = {
          firstName: selectedPatientData._source.firstName,
          lastName: selectedPatientData._source.lastName,
          birthDate: selectedPatientData._source.birthDate,
          fpPatientId: selectedPatientData._source.patientId,
          clientId: clientIdFromIOC,
          advisorPatientBody: {
            email: selectedPatientData._source.email!,
            primaryAddress: {
              streetAddress1:
                selectedPatientData._source.address?.streetAddress1!,
              streetAddress2:
                selectedPatientData._source.address?.streetAddress2!,
              city: selectedPatientData._source.address?.city!,
              state: {
                stateCode:
                  selectedPatientData._source.address?.state?.stateCode,
                stateId: selectedPatientData._source.address?.state?.stateId,
                stateName:
                  selectedPatientData._source.address?.state?.stateName,
              },
              zipCode: selectedPatientData._source.address?.zipCode,
            },
            phoneNumber: selectedPatientData._source.phoneNumber!,
          },
        };
        if (selectedPatientData._source.advisorPatientId) {
          patientState.patientId = selectedPatientData._source.advisorPatientId;
        }
        dispatch(setPatient(patientState));
        handleUpdatePatientFormik(patientState);
        updateVOBOnPatientSelection(patientState.clientId)
      }
      // close search modal
      handlePatSearchModalCancel();
    }
  }

  let patientObj: vobPatientPostBody;

  async function loadSelectedPatient(selectedPatient: patientSelection) {
    const selectedPatientData = patientSearchResults.data?.find(
      (data) => data._id === selectedPatient.dataId
    );

    if (selectedPatientData) {
      dispatch(clearVOB());
      dispatch(clearEstimator());
      dispatch(clearRiskAssessment());

      // handle Admission Advisor Patient
      if (selectedPatient.index === "advisor_patient") {
        if (selectedPatient.patientId) {
          const patientState: vobPatientPostBody = {
            firstName: selectedPatientData._source.firstName,
            lastName: selectedPatientData._source.lastName,
            birthDate: selectedPatientData._source.birthDate,
            advisorPatientId: selectedPatientData._source.advisorPatientId,
            fpPatientId: selectedPatientData._source.fpPatientId,
            clientId: selectedPatientData._source.clientId
          };

          // dispatch(setPatient()) does not update Redux store immediately.
          // Saving patient state locally so subsequent operations can have access to it
          patientObj = patientState;

          if (selectedPatientData._source.advisorPatientBody) {
            patientState.advisorPatientBody =
              selectedPatientData._source.advisorPatientBody;
          }
          dispatch(setPatient(patientState));
          handleUpdatePatientFormik(patientState);
          if (selectedPatient.vobId) {
            let savedVob = selectedPatientData._source?.vobs?.find(
              (vob) => vob.vobId === selectedPatient.vobId
            );
            if (savedVob) {
              let vobToSave = Utils.deepClone(savedVob);
              const config: any = {
                paramId: paramId,
                masterListPayers: payers,
                facilityId: vobToSave?.vobBody?.facility?.facilityId,
              };
              vobToSave.vobBody.vobId = savedVob.vobId;
              dispatch(setVOB(vobToSave?.vobBody));
              await dispatch(
                getClientFacilities(vobToSave?.vobBody?.client?.clientId!)
              );
              await dispatch(getEstimatorFacilityPayers(config));
              handleUpdateVobFormik(vobToSave?.vobBody);
              if (selectedPatient.estimateId === undefined) {
                handleEstimatorTabClick(0);
              }
            }
          }
          if (selectedPatient.estimateId) {
            let vobEstimates: PfrEstimator[] = [];
            selectedPatientData?._source.vobs?.forEach((vob: VobPostBody) => {
              vob.estimates?.forEach((est: PfrEstimator) => {
                vobEstimates.push(est);
              });
            });
            let savedEstimate = vobEstimates.find(
              (estimate) =>
                estimate.pfrEstimateId === selectedPatient.estimateId
            );
            if (savedEstimate) {
              let estimateToSave = Utils.deepClone(savedEstimate);
              estimateToSave.estimateBody.pfrEstimateId =
                estimateToSave.pfrEstimateId;
              dispatch(setEstimator(estimateToSave.estimateBody));
            }
            handleEstimatorTabClick(1);
          }
        }
      } else {
        const iocs = selectedPatientData._source.iocs!
        const clientIdFromIOC = iocs.length === 0 ? undefined : iocs[0].clientId

        const patientState: vobPatientPostBody = {
          firstName: selectedPatientData._source.firstName,
          lastName: selectedPatientData._source.lastName,
          birthDate: selectedPatientData._source.birthDate,
          fpPatientId: selectedPatientData._source.patientId,
          clientId: clientIdFromIOC,
          advisorPatientBody: {
            email: selectedPatientData._source.email!,
            primaryAddress: {
              streetAddress1:
                selectedPatientData._source.address?.streetAddress1!,
              streetAddress2:
                selectedPatientData._source.address?.streetAddress2!,
              city: selectedPatientData._source.address?.city!,
              state: {
                stateCode:
                  selectedPatientData._source.address?.state?.stateCode,
                stateId: selectedPatientData._source.address?.state?.stateId,
                stateName:
                  selectedPatientData._source.address?.state?.stateName,
              },
              zipCode: selectedPatientData._source.address?.zipCode,
            },
            phoneNumber: selectedPatientData._source.phoneNumber!,
          },
        };
        if (selectedPatientData._source.advisorPatientId) {
          patientState.patientId = selectedPatientData._source.advisorPatientId;
        }
        dispatch(setPatient(patientState));
        handleUpdatePatientFormik(patientState);

        let facilityId = null;
        let facilityName = undefined;
        let clientId = null;
        let clientName = undefined;

        if (selectedPatient.iocId) {
          const selectedIoc = selectedPatientData._source.iocs?.find(
            (ioc) => ioc.patientEncounterId === selectedPatient.iocId
          );
          if (selectedIoc) {
            facilityId = selectedIoc.facilityId;
            facilityName = selectedIoc.facilityName;
            clientId = selectedIoc.clientId;
            clientName = selectedIoc.clientName;
          }
        } else {
          if (
            selectedPatientData._source.associatedClients?.length === 1 &&
            selectedPatientData._source.associatedFacilities?.length === 1
          ) {
            facilityId = parseInt(
              selectedPatientData._source.associatedFacilities[0].facilityId
            );
            facilityName =
              selectedPatientData._source.associatedFacilities[0].facilityName;
            clientId = parseInt(
              selectedPatientData._source.associatedClients[0].clientId
            );
            clientName =
              selectedPatientData._source.associatedClients[0].clientName;
          }
        }

        if (facilityId && clientId) {
          const vob = {} as Vob;
          const vobClient = {
            clientId: clientId,
            clientName: clientName,
          };
          const vobFacility = {
            facilityId: facilityId,
            facilityName: facilityName,
          };
          vob.client = vobClient;
          vob.facility = vobFacility;
          vob.groupNum = "";
          vob.policyNum = "";
          dispatch(setVOB(vob));
          await dispatch(getClientFacilities(clientId));
          const config: any = {
            paramId: paramId,
            masterListPayers: payers,
            facilityId: vob.facility.facilityId,
          };
          await dispatch(getEstimatorFacilityPayers(config));
        }
      }
      // close search modal
      handlePatSearchModalCancel();
    }
  }

  const handleExpandSubTable = (patientId: string) => {
    if (openSubTable === patientId) {
      setOpenSubTable("");
    } else {
      setOpenSubTable(patientId);
    }
  };

  const getLevelOfCareFromEstimate = (estimate: any) => {
    var list = "";
    for (
      let i = 0;
      i < estimate.estimateBody?.selectedLevelsOfCare?.length;
      i++
    ) {
      let loc = estimate.estimateBody.selectedLevelsOfCare[i];
      list += loc.facilityLevelOfCareCode;
      list += ", ";
    }
    if (list.length > 0) {
      list = list.substring(0, list.length - 2);
    } else {
      list = "";
    }
    return list;
  };

  const getPfrFromEstimate = (estimate: any) => {
    let pfr = admissionsAdvisorUtils.getSelectedPFR(estimate.estimateBody);
    if (!pfr) {
      pfr = 0;
    }
    return formatNumberToUSD(pfr);
  };

  const getStatusFromIoc = (ioc: any) => {
    return ioc.workflow?.workflowStatus?.workflowStatusDesc
      ? ioc.workflow?.workflowStatus?.workflowStatusDesc
      : "";
  };

  const isIntegrationClient = (vob: any): boolean => {
      const clientItem = allClients?.find(
          (client: ClientStatusCardViewModel) =>
              client.clientId === vob.fpClientId
      );
      const clientCrm: ClientCrm[] = clientItem?.clientCrm || [];
      return clientCrm.length > 0;
  };

  const patientDocumentDetails = (patient: patientSearchResult) => {
    const vobLength =
      patient._source?.vobs?.length! > 0 ? patient._source?.vobs?.length : 0;
    let estimateLength = 0;
    if (vobLength! > 0) {
      patient?._source.vobs?.forEach((vob: VobPostBody) => {
        vob.estimates?.forEach((est: PfrEstimator) => {
          estimateLength++;
        });
      });
    }

    const iocLength =
      patient._source?.iocs?.length! > 0 ? patient._source?.iocs?.length : 0;

    return (
      <div style={{ display: "table", width: "100%" }}>
        <div style={{ float: "left", width: "30%", textAlign: "left" }}>
          {vobLength! > 0 ? (
            <Typography
              sx={{
                fontSize: "1.0em",
                display: "inline",
                marginRight: "0.5em",
              }}
            >
              {vobIcon}&nbsp;
              {vobLength} VOB
              {vobLength === 1 ? (
                <span style={{ color: "transparent" }}>s</span>
              ) : (
                <span>s</span>
              )}
            </Typography>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        <div style={{ float: "left", width: "40%", textAlign: "center" }}>
          {estimateLength! > 0 ? (
            <Typography sx={{ fontSize: "1.0em", display: "inline" }}>
              {estIcon}&nbsp;
              {estimateLength} Estimate
              {estimateLength === 1 ? (
                <span style={{ color: "transparent" }}>s</span>
              ) : (
                <span>s</span>
              )}
            </Typography>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        <div style={{ float: "left", width: "30%", textAlign: "right" }}>
          {iocLength! > 0 ? (
            <Typography sx={{ fontSize: "1.0em", display: "inline" }}>
              {iocIcon}&nbsp;
              {iocLength} IOC
              {iocLength === 1 ? (
                <span style={{ color: "transparent" }}>s</span>
              ) : (
                <span>s</span>
              )}
            </Typography>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    );
  };

  const findEstimateIoc = (
    iocId: number,
    patient: patientSearchResult,
    dataId: string,
    sourceIndex: string,
    butStyleOverride: any,
    vobId: number | undefined,
    estimateId: number | undefined
  ) => {
    const ioc = patient?._source?.iocs?.find(
      (x) => x.patientEncounterId === iocId
    );
    if (ioc && ioc.pfrEstimateId === estimateId) {
      return (
        <TableRow>
          <TableCell
            colSpan={2}
            style={{
              paddingLeft: "50px",
              borderBottomWidth: "0px",
              paddingRight: "0px",
            }}
          >
            {iocDetail(
              ioc,
              patient,
              dataId,
              sourceIndex,
              butStyleOverride,
              vobId,
              estimateId
            )}
          </TableCell>
        </TableRow>
      );
    } else {
      return <></>;
    }
  };

  const showIocIfNotPreviouslyShown = (
    ioc: patientIocResult,
    patient: patientSearchResult,
    dataId: string,
    sourceIndex: string,
    butStyleOverride: any
  ) => {
    const hasBeenShown = iocExistsInEstimate(ioc.patientEncounterId, patient);
    return hasBeenShown ? (
      <></>
    ) : (
      iocDetail(
        ioc,
        patient,
        dataId,
        sourceIndex,
        butStyleOverride,
        undefined,
        undefined
      )
    );
  };

  const iocExistsInEstimate = (iocId: number, patient: patientSearchResult) => {
    const vob = patient?._source?.vobs?.find((x) =>
      x.estimates?.find(
        (y) => y.estimateBody?.finPay?.patientEncounterId === iocId
      )
    );
    return !!vob;
  };

  const iocDetail = (
    ioc: patientIocResult,
    patient: patientSearchResult,
    dataId: string,
    sourceIndex: string,
    butStyleOverride: any,
    vobId: number | undefined,
    estimateId: number | undefined
  ) => {
    return (
      <>
        <hr className={classes.hrSeperator} />
        <Table
          size="small"
          width={"100%"}
          className={classes.lightSectionBackground}
        >
          <TableBody>
            <TableRow>
              <TableCell width="90%" style={{ borderBottomWidth: "0px" }}>
                {iocIconLarge}
                &nbsp;IOC #{ioc.patientEncounterId}
                &nbsp;&nbsp;&nbsp;{getStatusFromIoc(ioc)}
              </TableCell>
              <TableCell
                width="10%"
                rowSpan={2}
                style={{ borderBottomWidth: "0px" }}
                className="p-1"
              >
                {/* We will bring this back but for now must disable it */}
                {ioc.workflow.workflowName !== "Converted" && (
                  <Button
                    type="secondary"
                    style={butStyleOverride}
                    onClick={async () => {
                      setSelectIconId(`${dataId}.c.${ioc.patientEncounterId}`);
                      loadSelectedPatient({
                        dataId: dataId,
                        index: sourceIndex,
                        patientId: patient._source.advisorPatientId
                          ? patient._source.advisorPatientId
                          : patient._source.patientId,
                        vobId: vobId,
                        estimateId: estimateId,
                        iocId: ioc.patientEncounterId,
                      });
                      handleHasPriorIOC(ioc.patientEncounterId);
                      const instanceOfCare =
                        await patientService.getPatientInstanceOfCare({
                          patientId: 0,
                          encounterId: ioc.patientEncounterId,
                        });
                      dispatch(
                        setPatient({
                          ...patientObj,
                          selectedIOC: instanceOfCare.entity,
                        })
                      );
                    }}
                    spinnerLeftPosition={1}
                    loading={
                      selectIconId ===
                        `${dataId}.c.${ioc.patientEncounterId}` &&
                      (isLoadingFacilityLocPayers || isLoadingFacilities)
                    }
                  >
                    Select
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                width="90%"
                style={{ borderBottomWidth: "0px", paddingLeft: "50px" }}
              >
                <span>
                  <span className={classes.propertyName}>
                    Creation Date:&nbsp;
                  </span>
                  {admissionsAdvisorUtils.formatDateTime(
                    new Date(ioc.instanceCreateDt)
                  )}
                </span>
                {ioc.admissionDt && (
                  <span>
                    <br />
                    <span className={classes.propertyName}>
                      Admission Date:&nbsp;
                    </span>
                    {ioc.admissionDt
                      ? admissionsAdvisorUtils.formatDateTime(
                          new Date(ioc.admissionDt)
                        )
                      : "unknown"}
                  </span>
                )}
                {ioc.clientsPatientAccountId && (
                  <span>
                    <br />
                    <span className={classes.propertyName}>
                      CRM Account ID:&nbsp;
                    </span>
                    {ioc.clientsPatientAccountId}
                  </span>
                )}
                {ioc.clientsPatientIOCId && (
                  <span>
                    <br />
                    <span className={classes.propertyName}>
                      CRM Opportunity/Lead ID:&nbsp;
                    </span>
                    {ioc.clientsPatientIOCId}
                  </span>
                )}
                {(ioc.pfrAmt || ioc.levelOfCare) && (
                  <span>
                    <br />
                    {ioc.pfrAmt && (
                      <>
                        <span className={classes.propertyName}>
                          PFR Amount:&nbsp;
                        </span>
                        {ioc.pfrAmt ? formatNumberToUSD(ioc.pfrAmt) : "unknown"}
                        &nbsp;&nbsp;&nbsp;
                      </>
                    )}
                    {ioc.levelOfCare && (
                      <>
                        <span className={classes.propertyName}>
                          Level Of Care:&nbsp;
                        </span>
                        {ioc.levelOfCare}
                      </>
                    )}
                  </span>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };

  const patientDetails = (patient: patientSearchResult) => {
    const sourceIndex = patient._index;
    const dataId = patient._id;
    const butStyleOverride = {
      border: "1px solid #557DBC",
      fontWeight: "normal",
      fontSize: "1em",
      margin: "0",
      padding: "0",
    };

    return (
      <TableCell
        colSpan={3}
        style={{
          marginLeft: "30px",
          paddingTop: "0px",
          marginTop: "0px",
          backgroundColor: "transparent",
          paddingBottom: "0px",
          marginBottom: "0px",
        }}
      >
        <Collapse
          in={openSubTable === dataId}
          timeout="auto"
          unmountOnExit
          className={"m-3 p-1 " + classes.darkSectionBackground}
          style={{ marginLeft: "80px", marginTop: "0px" }}
        >
          <>
            <Typography variant="body2">Patient Information</Typography>
            <Table
              size="small"
              width={"100%"}
              className={classes.noSectionBackground}
            >
              <TableBody>
                <TableRow>
                  <TableCell width="90%" style={{ borderBottomWidth: "0px" }}>
                    <Typography variant="body2">
                      <>
                        <span className={classes.propertyName}>DOB:&nbsp;</span>
                        {moment(patient._source?.birthDate).format("l")}
                      </>
                    </Typography>
                  </TableCell>
                  <TableCell
                    width="10%"
                    align="right"
                    className="p-1"
                    style={{ borderBottomWidth: "0px" }}
                  >
                    <Button
                      type="secondary"
                      style={butStyleOverride}
                      onClick={() => {
                        setSelectIconId(
                          `${dataId}.v.${patient._source.advisorPatientId}`
                        );
                        loadSelectedPatientOnly({
                          dataId: dataId,
                          index: sourceIndex,
                          patientId: patient._source.advisorPatientId
                            ? patient._source.advisorPatientId
                            : patient._source.patientId,
                          vobId: undefined,
                          estimateId: undefined,
                          iocId: undefined,
                        });
                      }}
                      spinnerLeftPosition={1}
                      loading={
                        selectIconId ===
                          `${dataId}.v.${patient._source.advisorPatientId}` &&
                        (isLoadingFacilityLocPayers || isLoadingFacilities)
                      }
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {patient._source?.vobs?.map((vob: VobPostBody) => (
              <>
                <hr className={classes.hrSeperator} />
                <Table
                  size="small"
                  width={"100%"}
                  className={classes.lightSectionBackground}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="90%"
                        style={{ borderBottomWidth: "0px" }}
                      >
                        {vob.vobBody?.liveVOB ? (
                          <>
                            {liveVobIconLarge} Live VOB #{vob.vobId}
                          </>
                        ) : (
                          <>
                            {manualVobIconLarge} Manual VOB #{vob.vobId}
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        width="10%"
                        rowSpan={2}
                        style={{ borderBottomWidth: "0px" }}
                        className="p-1"
                      >
                        {!isIntegrationClient(vob) && 
                            <Button
                              type="secondary"
                              style={butStyleOverride}
                              onClick={() => {
                                setSelectIconId(`${dataId}.v.${vob.vobId}`);
                                loadSelectedPatient({
                                  dataId: dataId,
                                  index: sourceIndex,
                                  patientId: patient._source.advisorPatientId
                                    ? patient._source.advisorPatientId
                                    : patient._source.patientId,
                                  vobId: vob.vobId,
                                  estimateId: undefined,
                                  iocId: undefined,
                                });
                              }}
                              spinnerLeftPosition={1}
                              loading={
                                selectIconId === `${dataId}.v.${vob.vobId}` &&
                                (isLoadingFacilityLocPayers || isLoadingFacilities)
                              }
                              test-id={`${vob.vobId}-vob-select`}
                            >
                              Select
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="100%"
                        style={{
                          borderBottomWidth: "0px",
                          paddingLeft: "50px",
                        }}
                      >
                        {vob.createDt && (
                          <span>
                            <span className={classes.propertyName}>
                              Date Pulled:&nbsp;
                            </span>
                            {admissionsAdvisorUtils.formatDateTime(
                              new Date(vob.createDt)
                            )}
                          </span>
                        )}
                        {(vob.vobBody?.payer?.payorName ||
                          vob.vobBody?.plan?.planName) && (
                          <span>
                            <br />
                            <span className={classes.propertyName}>
                              Payer/Plan:&nbsp;
                            </span>
                            {vob.vobBody?.payer?.payorName
                              ? vob.vobBody?.payer?.payorName
                              : "unknown"}
                            &nbsp;/&nbsp;
                            {vob.vobBody?.plan?.planName
                              ? vob.vobBody?.plan?.planName
                              : "unknown"}
                          </span>
                        )}
                        {(vob.vobBody?.policyBeginDate ||
                          vob.vobBody?.policyEndDate) && (
                          <span>
                            <br />
                            <span className={classes.propertyName}>
                              Policy Begin/End:&nbsp;
                            </span>
                            {vob.vobBody.policyBeginDate
                              ? moment(vob.vobBody.policyBeginDate).format("l")
                              : "unknown"}
                            &nbsp;-&nbsp;
                            {vob.vobBody.policyEndDate
                              ? moment(vob.vobBody.policyEndDate).format("l")
                              : "unknown"}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>

                    {vob.estimates?.map((est: PfrEstimator) => (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            width="100%"
                            style={{
                              borderBottomWidth: "0px",
                              paddingLeft: "50px",
                              paddingRight: "0px",
                            }}
                          >
                            <hr className={classes.hrSeperator} />
                            <Table size="small">
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    width="90%"
                                    style={{ borderBottomWidth: "0px" }}
                                  >
                                    {estIconLarge} &nbsp;Estimate #
                                    {est.pfrEstimateId}
                                  </TableCell>
                                  <TableCell
                                    width="10%"
                                    rowSpan={2}
                                    style={{ borderBottomWidth: "0px" }}
                                    className="p-1"
                                  >
                                    <Button
                                      type="secondary"
                                      style={butStyleOverride}
                                      onClick={async () => {
                                        setSelectIconId(
                                          `${dataId}.e.${est.pfrEstimateId}`
                                        );
                                        loadSelectedPatient({
                                          dataId: dataId,
                                          index: sourceIndex,
                                          patientId: patient._source
                                            .advisorPatientId
                                            ? patient._source.advisorPatientId
                                            : patient._source.patientId,
                                          vobId: vob.vobId,
                                          estimateId: est.pfrEstimateId,
                                          iocId: undefined,
                                        });
                                        if (
                                          est.estimateBody?.finPay
                                            ?.patientEncounterId
                                        ) {
                                          handleHasPriorIOC(
                                            est.estimateBody.finPay
                                              .patientEncounterId
                                          );
                                          const instanceOfCare =
                                            await patientService.getPatientInstanceOfCare(
                                              {
                                                patientId: 0,
                                                encounterId:
                                                  est.estimateBody?.finPay
                                                    ?.patientEncounterId,
                                              }
                                            );
                                          dispatch(
                                            setPatient({
                                              ...patientObj,
                                              selectedIOC:
                                                instanceOfCare.entity,
                                            })
                                          );
                                        } else {
                                          dispatch(
                                            setPatient({
                                              ...patientObj,
                                              clientId: patient._source.clientId,
                                            })
                                          );
                                        }                                 
                                      }}
                                      spinnerLeftPosition={1}
                                      loading={
                                        selectIconId ===
                                          `${dataId}.e.${est.pfrEstimateId}` &&
                                        (isLoadingFacilityLocPayers ||
                                          isLoadingFacilities)
                                      }
                                      test-id={`${est.pfrEstimateId}-estimate-select`}
                                    >
                                      Select
                                    </Button>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      paddingLeft: "50px",
                                      borderBottomWidth: "0px",
                                    }}
                                  >
                                    <span>
                                      <span className={classes.propertyName}>
                                        Creation Date:&nbsp;
                                      </span>
                                      {admissionsAdvisorUtils.formatDateTime(
                                        new Date(est.createDt)
                                      )}
                                    </span>
                                    {est.estimateBody.selectedPFRName && (
                                      <span>
                                        <br />
                                        <span className={classes.propertyName}>
                                          PFR:&nbsp;
                                        </span>
                                        {getPfrFromEstimate(est)}
                                      </span>
                                    )}
                                    {est.estimateBody?.selectedLevelsOfCare &&
                                      est.estimateBody?.selectedLevelsOfCare
                                        .length > 0 && (
                                        <span>
                                          <br />
                                          <span
                                            className={classes.propertyName}
                                          >
                                            LOCs:&nbsp;
                                          </span>
                                          {getLevelOfCareFromEstimate(est)}
                                        </span>
                                      )}
                                  </TableCell>
                                </TableRow>
                                {est.estimateBody?.finPay?.patientEncounterId &&
                                  findEstimateIoc(
                                    est.estimateBody?.finPay
                                      ?.patientEncounterId,
                                    patient,
                                    dataId,
                                    sourceIndex,
                                    butStyleOverride,
                                    vob.vobId,
                                    est.pfrEstimateId
                                  )}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </>
            ))}
            {patient._source?.iocs?.map((ioc: patientIocResult) =>
              showIocIfNotPreviouslyShown(
                ioc,
                patient,
                dataId,
                sourceIndex,
                butStyleOverride
              )
            )}
          </>
        </Collapse>
      </TableCell>
    );
  };

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Search for Patient</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleCancelCallback}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} direction="column">
            <TextField
              label="Search Patient"
              type="text"
              placeholder="Enter Search Text"
              test-id="patient-search-modal-input"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              value={
                aaPatientSearchFormik.values.patientSearch
                  ? aaPatientSearchFormik.values.patientSearch
                  : null
              }
              name="patientSearch"
              onChange={(e: Event) => {
                aaPatientSearchFormik.handleChange(e);
                debouncedChangeHandler(e);
              }}
              onBlur={aaPatientSearchFormik.handleBlur}
              error={
                aaPatientSearchFormik.touched["patientSearch"] &&
                aaPatientSearchFormik.errors["patientSearch"]
                  ? aaPatientSearchFormik.errors["patientSearch"]
                  : ""
              }
              endAdornment={
                state.isLoadingSearchPatient &&
                aaPatientSearchFormik.values.patientSearch.length > 2 && (
                  <InputAdornment position="end">
                    <CircularProgress style={{ padding: "5px" }} />
                  </InputAdornment>
                )
              }
            />
          </Grid>
        </Grid>

        <Card className="mt-4">
          <Table size="small">
            <TableBody>
              {patientsList.map((patient: patientSearchResult) => {
                const bottomBorderStyle = { borderBottom: "none" };
                const dataId = patient._id;
                return (
                  <>
                    <TableRow key={patient._id} sx={bottomBorderStyle}>
                      <TableCell
                        sx={bottomBorderStyle}
                        align="right"
                        width="10%"
                      >
                        <IconButton
                          sx={{ border: "1px solid #557DBC", borderRadius: 1 }}
                          size="small"
                          onClick={() => handleExpandSubTable(dataId)}
                          style={{ fontSize: "1.5rem" }}
                          test-id={`${patient._id}-patient-open`}
                        >
                          {openSubTable === dataId ? (
                            <>
                              Close <KeyboardArrowUpIcon />
                            </>
                          ) : (
                            <>
                              Open
                              <KeyboardArrowDownIcon />
                            </>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={bottomBorderStyle} width="35%">
                        <strong>
                          {patient._source?.firstName}{" "}
                          {patient._source?.lastName}
                        </strong>
                        {openSubTable !== dataId ? (
                          <>
                            <br /> DOB:{" "}
                            {moment(patient._source?.birthDate).format("l")}
                          </>
                        ) : (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={bottomBorderStyle} width="55%">
                        <div style={{ textAlign: "left" }}>
                          {patient._source?.email ? (
                            patient._source?.email
                          ) : (
                            <i>email unavailable</i>
                          )}
                        </div>
                        {openSubTable !== dataId ? (
                          patientDocumentDetails(patient)
                        ) : (
                          <br />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                      key={`${patient._id}-r2`}
                    >
                      {patientDetails(patient)}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
          {patientsCount > 0 && (
            <TablePagination
              component="div"
              onPageChange={handlePageChange}
              page={controller.page}
              count={patientsCount}
              rowsPerPage={controller.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
        {patientsCount === 0 &&
          aaPatientSearchFormik.values.patientSearch &&
          aaPatientSearchFormik.values.patientSearch.length > 2 &&
          !isLoadingSearchPatient && (
            <div style={{ textAlign: "center" }}>
              <b>No Matching Results To Show</b>
            </div>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          loading={isLoadingFacilityLocPayers || isLoadingFacilities}
          onClick={() => handleCancelCallback()}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
