import '../../scss/components/mobile-payments.scss';
import { Button } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

interface MobileInstallmentDocModalProps {
  isOpen: boolean;
  handleModalCancel: () => void;
}

export function MobileInstallmentDocModal(props: MobileInstallmentDocModalProps) {

  const { isOpen, handleModalCancel } = props;

  return (
    <Dialog
      scroll="paper"
      className="modal user-modal mobile-modal"
      open={isOpen}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
    >
      <DialogTitle className="spa-modal-title">
        Installment Document
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" >
          <Grid item md={12} xs={12} className="spa-content">
            <h3 style={{ textAlign: "center" }}>Payment Program Authorization</h3>
            <p>By providing my credit card or debit card information, I hereby authorize FinPay and/or my treatment provider, to initiate on my behalf, preauthorized payments from the funding sources linked to my FinPay Account on the FinPass or FinMobile Platform.  I understand that these transactions from my linked funding sources may be drafted from my account as early as today and that payment(s) may be a single payment or recurring payments to make payment on the payment due date to designated merchant(s)provider(s) (“Auto Pay”) pursuant to the terms of my chosen payment program and conditions set forth in the Governing Agreements (defined below).</p>
            <p>I acknowledge that FinPay and/or my treatment provider has provided me an estimate of my patient financial responsibility for healthcare services with my designated merchant(s)provider(s) and I have voluntarily enrolled in a payment program of my choice to pay my obligations.  I hereby authorize FinPay to auto adjust (credit or debit) the principal balance of my chosen payment program with my designated merchant(s)provider(s) once my final billing statement is determined, or if applicable, upon the final adjudication of any health insurance claim(s) with my designated merchant(s)provider(s).  I have provided FinPay a valid email address where FinPay shall notify me of any adjustments to the principal balance of my chosen payment program (credit or debit) and FinPay shall not be responsible if the email account provided to FinPay is changed or canceled without my prior written notification to FinPay.  I acknowledge that FinPay will provide me access to my FinPay Account where I can make changes to my linked funding sources and make requests to change the terms of my chosen payment program.</p>
            <p>I understand my right to cancel. My authorization for Autopay shall remain in full force and effect until terminated by FinPay or revoked by me pursuant to the terms and conditions of the Governing Agreements (defined below). I acknowledge and understand I have the right to terminate my authorization for Autopay at any time but that any termination shall be in writing and require sufficient time for processing. Any termination will only apply to future replenishments or payments from the time the termination request is received by FinPay with a reasonable opportunity to act. FinPay reserves the right to cancel this Service for any reason and may terminate it if:
              <li className="pl-3">Your automated payment is returned by your financial institution because of “Authorization Revoked” or “Account Closed”;</li>
              <li className="pl-3">Three automated payments are returned unpaid for insufficient funds within a consecutive 12-month period; or</li>
              <li className="pl-3">You do not otherwise comply with the User Agreement terms and conditions.</li>
            </p>
            <p>I authorize FinPay to share my information. I hereby acknowledge and agree that in order for FinPay to provide the services contemplated herein, to manage my selected payment program, and by the Governing Agreements (defined below), including my Auto-Pay authorization, it may be necessary for FinPay to disclose my personal information to its network merchants, partners and providers. I hereby authorize FinPay to disclose my personal information with any of FinPay’s partners, merchant(s)provider(s) or provider(s) as may be required from time to time in the normal course of FinPay’s business.</p>
            <p>I agree to the terms & conditions. I hereby acknowledge and agree my use of FinPay’s services and website shall be governed by these authorizations as well as the terms and conditions of the FinPay Website User Agreement, as amended, and any other terms and conditions governing services offered by FinPay, all of which are hereby incorporated herein by reference (collectively referred to as the “Governing Agreements”). I further acknowledge and agree that by selecting the “I Agree” checkbox below, I will be bound by these authorizations as well as the Governing Agreements and my electronic acceptance satisfies any legal requirement that my authorizations and acceptance of the Governing Agreements be in writing and is fully enforceable. I am able and consent to receive the Governing Agreements and any disclosures, notices, or other communications related to my use of the FinPay Services electronically. To withdraw my consent, I must exit this session prior to agreeing to the terms and conditions. In the event I exit this session prior to agreeing to the terms and conditions, I may not use the FinPay’s services or website. I further opt-in for FinPay to communicate with me electronically, including but not limited to chat, text, email, or phone as further described in the User Agreement.</p>
            <h3 style={{ textAlign: "center" }}>Installment Agreement</h3>

            <p>On today’s date, hereinafter known as the "Effective Date", I, hereinafter known as the “Patient/ Guarantor”, agrees and promises to all patient financial responsibility (“PFR”) as summarized in Exhibit A and under the terms of same.</p>

            <p><strong>DEFINITIONS.</strong><br />
            (a)	“Governing Agreements” means any Credit Card Authorization, Installment Agreement, Authorization, Disclosure, FinPay Website User Agreement,  and all other documents or disclosures at any time executed in connection herewith or therewith or related hereto or thereto or otherwise related to the services provided to the Patient by the Provider and evidenced by this Agreement, or any prior Agreements in each case as the same may have been amended, modified, supplemented, extended, renewed, restated or replaced from time to time</p>
            <p>(b)	“FinPay” shall mean FinPay, LLC, which is the owner of the FinPass Platform and the patient financial management company chosen by the Patient, Guarantor, and Provider to facilitate and manage payments made under this agreement.</p>
            <p>(c)	“Patient” shall mean the individual receiving healthcare services from the Provider.</p>

            <p>(d)	“Provider” shall mean the individuals, organizations, and facilities providing medical and healthcare services to the Patient.</p>

            <p>(e)	“Guarantor” shall mean the individual(s) accepting financial responsibility for the charges and costs associated with services provided to the Patient by the Provider.</p>

            <p>(f)	“Down Payment” shall mean any payment towards the PFR, in whole or in part, made on or about the Effective date.</p>

            <p>(g)	“Due Date” shall mean the recurring date chosen by the Patient or Guarantor for payments to be processed on a monthly or weekly basis in accordance with the Patient’s or Guarantor’s chosen Payment Program, which is accessible in the Patient or Guarantor online FinPay Account.</p>

            <p>(h)	“Payment Program” means any single payment, monthly installment plan, weekly Pay As You Go Plan, or medical loan facilitated and managed by FinPay on behalf of the Patient, the Guarantor, and/or the Provider to facilitate the payment of the PFR owed by the Patient and Guarantor to the Provider.</p>

            <p>(i)	“PFR” shall mean all patient financial responsibility, liabilities and indebtedness of every kind, nature and description owed by the Patient and Guarantor, as applicable, to Provider for medical services rendered, including any principal balance after insurance, applicable interest, charges, fees, costs, expenses, and self-pay amounts however evidenced, arising under this Agreement or the Governing Agreements.</p>

            <p><strong>PAYMENT AUTHORIZATION.</strong> Patient and Guarantor, as applicable, hereby authorize FinPay, LLC to charge the Patient’s and Guarantor’s credit card, and/or debit card that is linked to the applicable online FinPay Account on a weekly or monthly basis and in accordance with services received and the Patient’s or Guarantor’s chosen Payment Program as defined in Exhibit A.  The Patient and Guarantor, as applicable, further authorize FinPay to make payments from any other payment source provided by Patient or Guarantor in the event any linked funding sources are declined for any reason.</p>

            <p><strong>APPLICATION OF PAYMENTS.</strong> Monies received by Patient/Guarantor from any source for application toward payment of the PFR shall be applied to the PFR in any manner or order deemed appropriate by Provider in its sole and absolute discretion, including; (i) Insurance related costs (Deductible(s), Copay(s), Coinsurance , (ii)Self-Pay Costs associated with ancillary or uncovered services, premiums or travel related charges, and (iii) Fees or non-refundable charges for Missed session(s), appointment(s), late cancellations, admission assessments, or late payments.</p>

            <p><strong>NON-REFUNDABLE CHARGES.</strong> Patient and Guarantor, as applicable, acknowledge and agree that Provider may, at its sole discretion, charge a non-refundable fee for missed session(s), appointment(s), or late cancellation(s).  Any waiver(s) of any non-refundable charge(s) shall not be deemed a waiver of Provider’s right to collect such non-refundable charge or to collect a non-refundable charge for any subsequent missed session(s), appointment(s), or late cancellation(s).  Patient and Guarantor, as applicable, acknowledge and agree that any admissions assessment fee that is included in the estimate provided herein may be refundable, at the discretion of Provider, once the Patient is formally admitted as a patient at the Provider’s Healthcare Facility(s).  If the Patient fails to be admitted, the admission assessment fee is not refundable and may be set off against any other refunds owed to the Patient or Guarantor, as applicable, by the Provider.</p>

            <p><strong>PFR ADJUSTMENTS:</strong> Patient/Guarantor, as applicable, acknowledges that the initial PFR Amount as defined in Exhibit A is based on an estimate of Patient’s PFR for healthcare services with Provider and subject to adjustment. Patient and Guarantor, as applicable, acknowledge that the PFR Amount in Exhibit A may increase if there is a change to the level(s) of care, duration of services, the purchase of additional medical services, or due to uncovered ancillary services. Patient/Guarantor, as applicable, hereby authorizes Provider to auto adjust (credit or debit) the PFR Amount, as defined in Exhibit A herein, once the final patient billing statement is determined, or if applicable, upon the final adjudication of any health insurance claim(s) with Provider.  In the event there is an increase of the PFR Amount under this agreement, Patient and Guarantor, as applicable, authorize Provider to extend the term of the payment program in Exhibit A for any number of months necessary to keep the Patient Payment Program’s recurring payments unchanged.</p>

            <p>Provider will give Patient and Guarantor, as applicable, no less than 10 days advance notice of such change(s).  Unless Patient/Guarantor contacts FinPay to make alternative payment arrangements during those 10 days, the extension of the payment program term will go into effect.  The failure to contact Provider to make alternative payment arrangements will result in the immediate implementation of the new payments terms and that my account will be adjusted accordingly.</p>

            <p>Patient and Guarantor, as applicable, have given Provider a valid email address where Provider shall send notice of any adjustments to the PFR Amount (credit or debit) and Provider shall not be responsible if the email account provided to Provider is changed or canceled without prior written notification to Provider.  Patient and Guarantor, as applicable, have access to a FinPay Account on the FinPass Platform where Patient and Guarantor, as applicable, can manage the payment program in Exhibit A, make changes to linked funding sources, and make requests to change the payment program terms. Patient and Guarantor, as applicable, agree that Provider may extend, modify or renew this Agreement or make a novation of the Governing Agreements, evidenced by this Agreement, without notice to or consent of Patient or Guarantor, as applicable, and other such person, and without affecting the liability of the Patient or Guarantor, as applicable, and other such person.</p>

            <p><strong>ATTORNEYS’ FEES AND OTHER COLLECTION COSTS.</strong> Patient and Guarantor, as applicable, shall pay expenses incurred by Provider to enforce or collect any of the PFR Amount including, without limitation, arbitration expenses and reasonable attorneys’ and experts’ fees and costs, whether incurred without the commencement of a suit, in any trial, arbitration, or administrative proceeding or in any appellate or bankruptcy proceeding.</p>

            <p>
              <strong>DEFAULT.</strong> If any of the following occurs, a default (“Default”) under this Agreement shall exist, whatever the reason therefor:
              <p className="pl-3"><strong>Nonpayment; Nonperformance.</strong>  The failure to timely pay or perform any of the PFR as and when due or required under this Agreement or any of the other Governing Agreements.</p>
              <p className="pl-3"><strong>False Warranty.</strong>  A warranty or representation made or deemed made in the Governing Agreements or to Provider in connection with the Governing Agreement(s) proves materially false, or if of a continuing nature, becomes materially false and is not cured within thirty (30) days.
              </p>
              <p className="pl-3">
                <strong>REMEDIES UPON DEFAULT.</strong>  If a Default occurs under this Agreement or any of the other Governing Agreements, Provider may at any time thereafter, take the following actions:
                <p className="pl-3"><strong>Acceleration Upon Default.</strong>  Accelerate the maturity of this Agreement and the PFR Amount.</p>
                <p className="pl-3"><strong>Cumulative.</strong>  In addition to all rights specifically granted to Provider under this Agreement and the other Governing Agreements, Provider will have and be entitled to exercise all rights and remedies under applicable law.</p>
              </p>
            </p>

            <p><strong>WAIVERS AND AMENDMENTS.</strong> No waivers, amendments, or modifications of this Agreement or any of the other Governing Agreements shall be valid unless in writing and signed by an officer of Provider.  No waiver by Provider of any Default shall operate as a waiver of any other Default or the same Default on a future occasion.  Neither the failure nor any delay on the part of Provider in exercising any right, power or remedy under this Agreement or any other Installment Agreement Documents shall operate as a waiver thereof, nor shall a single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any other right, power or remedy.</p>

            <p>Except to the extent otherwise provided by the Governing Agreements, as applicable, or prohibited by law, Patient and Guarantor, as applicable, and each other person liable under this Agreement waives presentment, protest, notice of dishonor, demand for payment, notice of intention to accelerate maturity, notice of acceleration of maturity, notice of sale and all other notices of any kind.</p>

            <p>
              <strong>MISCELLANEOUS PROVISIONS.</strong><br />
              <p className="pl-3">
                <strong>Assignment.</strong> This Agreement and the other Governing Agreements shall inure to the benefit of and be binding upon the parties and their respective heirs, legal representatives, successors, and assigns.  Provider’s interests in and rights under this Agreement and the other Governing Agreements are freely assignable, in whole or in part, by Provider.  In addition, nothing in this Agreement or any of the other Governing Agreements shall prohibit Provider from pledging or assigning this Agreement or any of the other Governing Agreements or any interest therein to any other provider.  Patient/Guarantor shall not assign its rights and interest hereunder or under any of the other Governing Agreements without the prior written consent of Provider, and any attempt by Patient/Guarantor to assign without Provider’s prior written consent is null and void.  Any assignment shall not release Patient/Guarantor from the PFR.
              </p>
              <p className="pl-3">
                <strong>Applicable Law; Conflict Between Documents.</strong>  This Agreement shall be governed by and interpreted in accordance with federal law and, except as preempted by federal law, the laws of Delaware without regard to that state’s conflict of laws principles.
              </p>
              <p className="pl-3">
                <strong>Jurisdiction.</strong>  Patient/Guarantor irrevocably agrees to non-exclusive personal jurisdiction of the state and federal courts of Delaware, provided that nothing shall prohibit Provider from commencing any action or proceeding in any other jurisdiction which Provider deems necessary for enforcement and collection of the PFR.
              </p>

              <p className="pl-3">
                <strong>Severability.</strong>  If any provision of this Agreement or any of the other Installment Agreement Documents shall be prohibited or invalid under applicable law, such provision shall be ineffective but only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or the remaining provisions of this Agreement or such other Governing Agreements.
              </p>

              <p className="pl-3">
                <strong>Payments.</strong>  The Patient and Guarantor, as applicable, agrees to register and maintain a FinPay Account for managing and making payments covered under this Agreement.  Automated and self-service payments shall be made through the Patient and Guarantor’s, as applicable, FinPay Account. In consideration for the services provided to Patient by Provider, Patient and Guarantor, as applicable, hereby authorizes FinPay to initiate preauthorized payments from the Patient and Guarantor’s, as applicable,  funding sources linked to the applicable FinPay Account.  FinPay shall use the linked funding sources to make payment on the payment Due Date or Installment Date as defined in Exhibit A (“Auto Pay”) pursuant to the terms of this Agreement and conditions set forth in FinPay’s account terms & conditions.
              </p>

              <p className="pl-3">
                <strong>Plural; Captions.</strong>  All references in the Governing Agreements to Patient/Guarantor, guarantor, person, document, or other nouns of reference mean both the singular and plural form, as the case may be, and the term “person” shall mean any individual, person or entity.  The captions contained in the Governing Agreements are inserted for convenience only and shall not affect the meaning or interpretation of the Governing Agreements.
              </p>

              <p className="pl-3">
                <strong>Posting of Payments.</strong>  All payments received during normal banking hours after 2:00 p.m. local time at the address for payments set forth above shall be deemed received at the opening of the next banking day.
              </p>

              <p className="pl-3">
                <strong>Joint and Several PFR.</strong>  The Patient and Guarantor are jointly and severally obligated together with all other parties obligated for the PFR.
              </p>

              <p className="pl-3">
                <strong>Final Agreement.</strong>  This Agreement and the other Governing Agreements represent the final agreement between the parties and may not be contradicted by evidence of prior, contemporaneous or subsequent agreements of the parties.  There are no unwritten agreements between the parties.
              </p>
            </p>

            <h2 style={{ textAlign: 'center' }}>EXHIBIT A</h2>
            <h3 style={{ textAlign: 'center' }}>Payment Program Summary</h3>
            <p>The Patient and Guarantor, as applicable, agrees to and promises to pay to Provider the total sum of the PFR, and any subsequent adjustment, in US Dollars and as agreed to herein and subject to adjustment as described in the Governing Agreements.</p>

            <p><strong>PFR AMOUNT:</strong> As identified, displayed, and agreed to in the user interface</p>

            <p><strong>DOWN PAYMENT:</strong> As identified, displayed, and agreed to in the user interface</p>

            <p><strong>AMOUNT FINANCED:</strong> As identified, displayed, and agreed to in the user interface</p>

            <p><strong>INTEREST RATE.</strong> Interest shall accrue on the unpaid principal balance of this Agreement at an annual rate equal to zero percent (0.0%).</p>

            <p><strong>REPAYMENT TERMS.</strong> The PFR under this Agreement shall be due and payable in consecutive principal payments as identified, displayed, and agreed to in the user interface (the “Installment Date”).  The Patient and Guarantor, as applicable, shall continue to make the planned recurring payments, subject to PFR adjustments, until the PFR balance is $0.00. If the Autopay of recurring payments is canceled by the Patient or Guarantor, as applicable, the full remaining PFR balance is immediately due and payable to the Provider.</p>

            <p><strong>INITIAL DUE DATE</strong> is the date identified, displayed, and agreed to in the user interface, which is the first scheduled recurring payment under this payment program. All future recurring payments will be automatically processed on the same applicable monthly or weekly date.</p>

            <p><strong>LATE CHARGE.</strong> If any payments are not timely made, at the Provider’s discretion, Patient/Guarantor may also be subject to a late charge equal to five percent (1.5%) of each payment which is past due for ten (10) or more days.  This late charge shall not apply to payments due at maturity or by acceleration hereof, unless such late payment is in an amount not greater than the highest periodic payment due hereunder. Acceptance by Provider of any late payment without an accompanying late charge shall not be deemed a waiver of Provider’s right to collect such late charge or to collect a late charge for any subsequent late payment received.</p>

            <p><strong>NOTICES.</strong>  Any notices to Patient and Guarantor shall be sufficiently given, if by electronic communication such as email or text, in writing, and either hand delivered, sent by certified mail or by overnight delivery to the Patient and Guarantor’s address, as applicable,  shown on the signature page hereto or such other address as provided hereunder, and to Provider, if in writing and mailed or delivered to such other address as Provider may specify in writing from time to time, and to FinPay mailed and delivered to 700 S. Henderson Rd. Suite 202, King of Prussia, PA 19406.  Notices to Provider must include the mail code. In the event that Patient and Guarantor, as applicable, changes such address, email address, or phone number at any time prior to the date the PFR is paid in full, Patient and Guarantor, as applicable, agree to promptly give written notice of said change of address by registered or certified mail, return receipt requested, all charges prepaid.</p>


            <h2 style={{ textAlign: 'center' }}>Federal Truth-In-Lending Disclosure Statement</h2>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody style={{ borderStyle: 'solid', borderWidth: 'thin', borderColor: 'black' }}>
                <tr style={{ verticalAlign: 'top' }}>
                  <td style={{ borderRightStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>
                    <strong>
                      ANNUAL<br />
                      PERCENTAGE RATE
                    </strong>
                  </td>
                  <td style={{ borderRightStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>
                    <strong>
                      FINANCE<br />
                      CHARGE
                    </strong>
                  </td>
                  <td style={{ borderRightStyle: 'solid', borderWidth: 'thin', padding: '8px' }}><strong>AMOUNT FINANCED</strong></td>
                  <td style={{ padding: '8px' }}><strong>TOTAL OF PAYMENTS</strong></td>
                </tr>
                <tr style={{ verticalAlign: 'top' }}>
                  <td style={{ borderRightStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>
                    The cost of your credit as a yearly rate
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    0.00%
                  </td>
                  <td style={{ borderRightStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>
                    The dollar amount the credit will cost you assuming the annual percentage rate does not change.
                    <br />
                    <br />
                    <br />
                    $0.00
                  </td>
                  <td style={{ borderRightStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>The amount of credit provided to you based on your behalf as of the credit closing. The amount is what was identified, displayed, and agreed to in the user interface.</td>
                  <td style={{ padding: '8px' }}>The amount you will have paid after you have made all payments as scheduled assuming the annual percentage rate does not change. The amount is what was identified, displayed, and agreed to in the user interface.</td>
                </tr>
              </tbody>
            </table>
            <p className="p-3">
              Your payment schedule will be what was identified, displayed, and agreed to in the user interface.
              <br />
              <br />
              <strong>Late Charge:</strong> If a payment is late, you will be charged 0.00% of the payment. <strong>Prepayment:</strong> If you pay off early, you will not pay a penalty. <strong>Default:</strong> If in default, your account may be placed to an attorney or a 3rd party collection company, Provider may also report your account to  consumer credit bureau(s).
              <br />
              <br />
              See your Governing Agreements  (installment Agreement) for additional information about prepayments, default, any required repayment in full before scheduled date.
              <br />
              <br />
              You are not required to complete the Governing Agreements merely because you received this disclosure. 
              <br />
              <br />
              <i>The undersigned hereby acknowledge receiving and reading a completed copy of this disclosure along with copies of the documents provided. The delivery and signing of this disclosure does not constitute an obligation on part of the Provider to make, or the Patient/Guarantor(s) to accept the credit as identified. All Dates and numerical & currency disclosures, except late payment disclosures, are estimates</i>
              <br />
              <br />
              Read, acknowledged, and accepted by me on today’s date.
            </p>
            <h3 style={{ textAlign: 'center' }}>Advance Beneficiary Notice of Noncoverage (ABN)</h3>
            <p>
              NOTE:  We expect Insurance not to pay for the charges under Section “D.” below. If Insurance doesn’t pay for these services, you may have to pay. Health Insurance does not pay for everything, even some care that you or your healthcare provider have good reason to think are a medical necessity.
            </p>
            <table style={{ borderCollapse: 'collapse' }}>
              <thead style={{ verticalAlign: 'top', backgroundColor: 'rgb(199 199 199)' }}>
                <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>D.</td>
                <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>E. Reason Insurance May Not Pay:</td>
                <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>F. Estimated “Potential” Cost</td>
              </thead>
              <tbody>
                <tr style={{ verticalAlign: 'top' }}>
                  <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>1.  Self-pay, Balance Bill, Uncovered, and ancillary services</td>
                  <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>1. Amounts owed by you are out-of-pocket expenses as determined by the terms of your health care insurance plan (Deductibles, Co-pays, & Co-insurance)<br />
                  2. Services not covered under the terms of your health plan.
                  </td>
                  <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }} />
                </tr>
              </tbody>
            </table>
            <p>
              <strong>WHAT YOU NEED TO DO NOW:</strong><br />
              <p className="pl-3">o	Read this notice, so you can make an informed decision about your care.</p>
              <p className="pl-3">o	Ask us any questions that you may have after you finish reading.</p>
              <p className="pl-3">o	Choose an option below regarding the services in Section “D” above.</p>
              <strong>Note:</strong> If you choose Option 1 or 2, we may help you to use any other insurance that you might have.
            </p>
            <table style={{ borderCollapse: 'collapse' }}>
              <thead style={{ verticalAlign: 'top', backgroundColor: 'rgb(199 199 199)' }}>
                <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>
                  <strong>G. OPTIONS: You may select one of the below options</strong>
                </td>
              </thead>
              <tbody>
                <tr style={{ verticalAlign: 'top' }}>
                  <td style={{ borderStyle: 'solid', borderWidth: 'thin', padding: '8px' }}>
                    <strong>OPTION 1.</strong> I accept financial responsibility for “D.” listed above. You may ask to be paid now, but I also want my insurance billed for an official decision on payment, which is sent to me as part of my medical bill. I understand that if insurance doesn’t pay, I am responsible for payment. If Insurance does pay, you will refund any payments I made to you, less co-pays, co-insurance and/or deductibles.
                    <br />
                    <strong>OPTION 2.</strong> I want “D.” listed above, but do not bill my insurance. You may ask to be paid now, as I am responsible for payment.
                    <br />
                    <strong>OPTION 3.</strong> I don’t want “D.” listed above. I understand with this choice I will not receive services and I am not responsible for payment.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>H. Additional Information:</strong>
              <br />
              <strong>This notice gives our opinion, not an official Insurance decision.</strong> If you have other questions on this notice or insurance billing, call your health insurance company.
              <br />
              By acknowledging this agreement in the user interface, it means that you have received and understand this notice. You also receive a copy. 
              <br />
              <strong>We do not discriminate in programs and activities.</strong>
              <br />
              <p style={{ textAlign: 'center' }}><small>According to the Paperwork Reduction Act of 1995, no persons are required to respond to a collection of information unless it displays a valid control number.</small></p>
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ borderTop: "", marginTop: "0", padding: "2rem" }}>
        <Button onClick={handleModalCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

