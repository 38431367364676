import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../shared/state/root-reducer';
import {AppDispatch} from '../../../shared/state/store';
import {
    FacilityPayers,
    Vob,
    vobClassificationsItem,
    VobPostBody,
} from '../../models/vob';
import {saveVob} from '../../state/vob-thunk';
import {VOBModalContents} from '@finpay-development/shared-components'
import { ClientCrm, ClientStatusCardViewModel } from '../../../shared/model/client-status-card';
import {admissionsAdvisorUtils} from '../../utils/admission-advisor-utils';
import { crmLabels } from '../../../shared/enums';
import { patientService } from '../../../patient/services/patient-service';

interface VOBModalProps {
    open: boolean;
    isEdit: boolean;
    classifications: vobClassificationsItem[] | null;
    facilityPayersState?: FacilityPayers[];
    handleVOBModalCancel: () => void;
    handleVOBModalSubmit: (isEdit: boolean) => void;
}

export function VOBModal(props: VOBModalProps) {
    const {
        open,
        isEdit,
        classifications,
        facilityPayersState,
        handleVOBModalCancel,
        handleVOBModalSubmit,
    } = props;

    const aaVOB = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.vobContext.vob;
    });

    const fullVOBState = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.vobContext;
    });

    const vobPatientState = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.vobPatientContext;
    });

    const allClients = useSelector((state: RootState) => {
        return state.implementationContext?.implementationSpecialistClient.allClientsWithFacillities;
    });

    const estimatorState = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.estimatorContext?.estimator;
    });

    const vob = aaVOB;

    const dispatch = useDispatch<AppDispatch>();

    const copyVob = (formik: any): Vob => {
        const {values, isValid} = formik;
        const currentDateTime = new Date();
        return {
            isValid: isValid,
            selfPay: vob.selfPay,
            digitalVerificationMethod: vob.digitalVerificationMethod,
            client: {...vob.client, clientList: undefined},
            facility: {...vob.facility, facilityList: undefined},
            payer: {...vob.payer, payersList: undefined},
            plan: {...vob.plan, payersPlanList: undefined},
            groupNum: vob.groupNum,
            policyNum: vob.policyNum,
            liveVOB: values.liveVOB,
            activePolicy: values.activePolicy,
            isCarryover: values.isCarryover,
            policyBeginDate: values.policyBeginDate,
            policyEndDate: values.policyEndDate,
            inNetwDeductible: values.inNetwDeductible!,
            inNetwDeductibleRemaining: values.inNetwDeductibleRemaining!,
            inNetwFamilyDeductible: values.inNetwFamilyDeductible!,
            inNetwFamilyDeductibleRemaining:
                values.inNetwFamilyDeductibleRemaining!,
            inNetwOopIncluded: values.inNetwOopIncluded,
            inNetwOopMax: values.inNetwOopMax!,
            inNetwOopMaxRemaining: values.inNetwOopMaxRemaining!,
            inNetwFamilyOopMax: values.inNetwFamilyOopMax!,
            inNetwFamilyOopMaxRemaining: values.inNetwFamilyOopMaxRemaining!,
            inNetwVobClassifications: values.inNetwVobClassifications,
            ooNetwDeductible: values.ooNetwDeductible!,
            ooNetwDeductibleRemaining: values.ooNetwDeductibleRemaining!,
            ooNetwFamilyDeductible: values.ooNetwFamilyDeductible!,
            ooNetwFamilyDeductibleRemaining:
                values.ooNetwFamilyDeductibleRemaining!,
            ooNetwOopIncluded: values.ooNetwOopIncluded,
            ooNetwOopMax: values.ooNetwOopMax!,
            ooNetwOopMaxRemaining: values.ooNetwOopMaxRemaining!,
            ooNetwFamilyOopMax: values.ooNetwFamilyOopMax!,
            ooNetwFamilyOopMaxRemaining: values.ooNetwFamilyOopMaxRemaining!,
            ooNetwVobClassifications: values.ooNetwVobClassifications,
            vobId: vob.vobId,
            patientNotes: values.noteText
                ? [
                      {
                          noteText: values.noteText,
                          noteDt: currentDateTime.toISOString(),
                      },
                  ]
                : [],
            employer: {
                employerId: vob.employer?.employerId!,
                employerName: vob.employer?.employerName!
            }
        };
    };

    async function handleSave(formik: any) {
        const vobPostCopy = copyVob(formik);

        const vobPostBody: VobPostBody = {
            advisorPatientId: vobPatientState.patient.advisorPatientId,
            fpClientId: vob.client?.clientId,
            fpClientFacilityId: vob.facility?.facilityId,
            vobBody: {
                ...vobPostCopy,
            },
        };

        const newVob = await dispatch(saveVob(vobPostBody));

        handleSaveCallback(newVob?.payload.vobBody);
    }

    async function updateCRMVOB(newVobData: Vob) {
        const currentClientId: number | undefined = newVobData?.client?.clientId;
        const clientItem: ClientStatusCardViewModel | undefined = allClients?.find(
            (client: ClientStatusCardViewModel) =>
                client.clientId === currentClientId
        );
        const clientCrm: ClientCrm[] | undefined = clientItem?.clientCrm;
    
        if (clientCrm?.[0]?.crmType?.crmTypeSlug === crmLabels.salesforce_slug) {
            const instanceOfCare = await patientService.getPatientInstanceOfCare({
                patientId: 0,
                encounterId: estimatorState.finPay?.patientEncounterId!,
            });
    
            if (instanceOfCare.entity) {
                const formedClient = { ...newVobData.client };
                const formedFacility = { ...newVobData.facility };
                delete formedClient.clientList;
                delete formedFacility.facilityList;
    
                const getVOBPayload = admissionsAdvisorUtils.crmVOBUpdate(
                    newVobData,
                    instanceOfCare.entity.clientsPatientAccountId,
                    instanceOfCare.entity.clientsPatientIOCId,
                    formedClient,
                    formedFacility,
                    vobPatientState.patient
                );
    
                await patientService.integrationVOBUpdate({
                    vob: getVOBPayload,
                    crmType: "sf",
                });
            }
        }
    }

    async function handleSaveCallback(newVobData: Vob) {
        handleVOBModalSubmit(isEdit);
        if (
            newVobData?.client?.clientId &&
            estimatorState.finPay?.patientEncounterId
        ) {
            await updateCRMVOB(newVobData);
        }
    }

    return (
        <VOBModalContents
            open={open}
            isEdit={isEdit}
            classifications={classifications}
            vob={vob}
            facilityPayersState={facilityPayersState}
            handleSave={handleSave}
            handleVOBModalCancel={handleVOBModalCancel}
            handleVOBModalSubmit={handleVOBModalSubmit}
        />
    );
}

