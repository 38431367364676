import axios from 'axios';
import {
  Filters,
  selectedClientAndFacilities,
  selectedClientFacilitiesInfo,
} from '../../implementation-specialist/models/reportsFilters';
import {User} from '../../security/model/user';
import {UserRole} from '../../security/model/user-role';
import {configSettings} from '../../shared/configuration/config-settings';
import {Client} from '../../shared/model/client';
import {Column} from '../../shared/model/column';
import {Facility, FacilitySave} from '../../shared/model/facility';
import {axiosReadHelper} from '../../shared/service/axios-read-helper';
import {AxiosReadPayload} from '../../shared/service/axios-read-payload';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {axiosSaveHelper} from '../../shared/service/axios-save-helper';
import {AxiosSavePayload} from '../../shared/service/axios-save-payload';
import {EditUser} from '../models/edit-user';
import {FilterValues} from '../models/filter-values';
import {FilteredUserInfo} from '../models/filtered-user-info';
import {LogsInfo} from '../models/log-info';
import {LogsState} from '../models/logs-state';
import {ReportsInfo} from '../models/reports-info';
import {UserInfo} from '../models/user-info';
import {UserInfoClient} from '../models/user-info-client';
import {UserInfoFacility} from '../models/user-info-facility';
import {UserInfoRole} from '../models/user-info-role';
import {
  ClientFacility,
  ClientStatusCardViewModel,
} from '../../shared/model/client-status-card';
import _ from 'lodash';
import {TimingRisk} from '../../meta-data/models/metaData';
import {
  UserSearchFormValues,
} from '../components/admin-users/admin-user-search-form';
import {
  ApiBatchLimits,
} from '@finpay-development/shared-components/lib/esm/utils/enums';

export const adminUserHelper = {
  // gets data to populate users filter for Client, Facility, and Role dropdowns
  getUsersFilterData(payload: UserInfo[]) {
    const clients: UserInfoClient[] = [];
    const roles: UserInfoRole[] = [];

    payload.forEach((_user: UserInfo) => {
      _user.clients.forEach((_client: UserInfoClient) => {
        getClientsFromPayload(_client);
      });

      getRolesFromPayload(_user);
    });

    // extracts all unique clients from payload. For use in Users 'client' filter dropdown
    function getClientsFromPayload(client: UserInfoClient) {
      const hasClient = clients.some((_client) => {
        return client.clientId === _client.clientId;
      });
      if (hasClient) {
        return;
      }
      clients.push(client);
    }

    // extracts all unique roles from payload. For use in Users 'Roles' filter dropdown
    function getRolesFromPayload(user: UserInfo) {
      const hasRole = roles.some((_role) => {
        return _role?.roleId === user.userRole?.roleId;
      });
      if (hasRole) {
        return;
      }
      if (user.userRole) {
        roles.push(user.userRole);
      }

    }

    return {
      clients: clients,
      roles: roles,
    };
  },

  // mapping down to a minimal set of user data for display only in the filter results table
  filteredSearchResults(payload: UserInfo[]): FilteredUserInfo[] {
    let formatStringValue = (value:string | any) =>{
      if(value !== null){
        value = value?.trimStart()
        value = value?.charAt(0)?.toLocaleUpperCase() + value?.slice(1)
        return value
      }
    }

    payload.sort(function(a, b){
      if(formatStringValue(a.lastName) < formatStringValue(b.lastName)) return -1;
      if(formatStringValue(a.lastName) > formatStringValue(b.lastName)) return 1;
      return 0;
    })

    return payload.map((userInfo: UserInfo) => {
      const filteredUser = new FilteredUserInfo();
      filteredUser.userId = userInfo.userId;
      filteredUser.email = userInfo.email;
      filteredUser.fullName = `${userInfo.firstName} ${userInfo.lastName}`;
      filteredUser.status = userInfo.isActive ? "Active" : "Inactive";
      filteredUser.userRole = userInfo?.userRole?.roleName;
      filteredUser.clients = userInfo.clients;
      filteredUser.domainAccess = userInfo.domainAccess;
      filteredUser.hasAllTimingRisks = userInfo.hasAllTimingRisks;
      return filteredUser;
    });
  },
  mapFacilitiesForSearch(payload: any): UserInfoClient[] {
    return payload.map((clientData: any) => {
      const user = new UserInfoClient();
      user.clientId = clientData.clientId;
      user.clientName = clientData.clientName;
      user.allowedFacilities = [];
      return user;
    });
  },
  mapClientForSearch(payload: Client[]): UserInfoClient[] {
    return payload?.map((clientData: Client) => {
      const user = new UserInfoClient();
      user.clientId = clientData.clientId;
      user.clientName = clientData.clientName;
      //isFacilityLevel and allowedFacilities array is out of sync
      //for now, if the allowedFacilities array is empty, we assume the user has access to all facilities for the client
      user.isFacilityLevel = Array.isArray(clientData.allowedFacilities) && clientData.allowedFacilities.length === 0  ? true : clientData.isFacilityLevel
      user.allowedFacilities = [];
      if (Array.isArray(clientData.allowedFacilities) && clientData.allowedFacilities.length > 0) {
        user.allowedFacilities = clientData.allowedFacilities.map((facilityData: Facility) => {
          const facility = new UserInfoFacility();
          facility.facilityId = facilityData.clientFacilityId!;
          facility.facilityName = facilityData.facilityName;
          return facility;
        });
      }
      return user;
    });
  },
  mapUserTimingRiskForSearch(payload: TimingRisk[]): TimingRisk[] {
    if (!payload) {
      return [];
    }

    return payload.map((timingRisk: TimingRisk) => {
      const userTimingRisk = new TimingRisk();
      userTimingRisk.timingRiskId = timingRisk.timingRiskId;
      return userTimingRisk;
    });
  },


  mapUser(userData: User): UserInfo {
    const user = new UserInfo();
    user.userId = userData.userId;
    user.email = userData.userName;
    user.firstName = userData.firstName;
    user.lastName = userData.lastName;
    user.phoneNumber = userData.mobilePhone;
    user.fullName = `${userData.firstName} ${userData.lastName}`;
    user.isActive = userData.isActive;
    user.cognitoStatus = userData.cognitoStatus;
    user.domainAccess = userData?.userScope?.toUpperCase();
    user.isSSO = userData.isSSO;
    user.hasAllTimingRisks = userData.hasAllTimingRisks;

    if (userData.userRole && userData.userRole.userRoleId) {
      user.userRole = {
        roleId: userData.userRole.userRoleId,
        roleName: userData.userRole.roleName,
      }
    }

    user.clients = this.mapClientForSearch(userData.allowedClients)

    user.userTimingRisk = this.mapUserTimingRiskForSearch(userData.allowedTimingRisks
      )

    return user;
  },

  mapUsersForSearch(payload: User[]): UserInfo[] {
    return payload?.map((userData: User) => {
      return this?.mapUser(userData);
    });
  },

  mapFacilityForSave(facilityInfo: UserInfoFacility): Facility {
    const facility = new FacilitySave();
    facility.facilityId = facilityInfo.facilityId;
    facility.facilityName = facilityInfo.facilityName
    return facility;
  },

  mapClientFacilities(facilities: UserInfoFacility[]): Facility[] {
    return facilities.map((facilityData: UserInfoFacility) => {
      return this.mapFacilityForSave(facilityData);
    })
  },

  mapClientForSave(clientInfo: UserInfoClient): Client {
    const client = new Client();
    client.clientId = clientInfo.clientId;
    client.clientName = clientInfo.clientName;
    client.isFacilityLevel = clientInfo.isFacilityLevel

    if (clientInfo.allowedFacilities) {
      client.allowedFacilities = this.mapClientFacilities(clientInfo.allowedFacilities)
    }

    return client;
  },


  mapClientsForUpdateSaveUser(clients: UserInfoClient[]): Client[] {
    return clients.map((clientData: UserInfoClient) => {
      return this.mapClientForSave(clientData)
    })
  },



  mapTimingRiskForUpdateSaveUser(timingRisk: (TimingRisk | undefined)[]): TimingRisk[] {
      const filteredTimingRisk = timingRisk.filter(tr => tr !== undefined) as TimingRisk[];
      return filteredTimingRisk.map((timingRiskData: TimingRisk) => {
        const timingRiskItem = new TimingRisk();
        timingRiskItem.timingRiskId = timingRiskData?.timingRiskId || 0;
        timingRiskItem.timingRiskName = timingRiskData?.timingRiskName || "";
        timingRiskItem.shortName = timingRiskData?.shortName || "";
        return timingRiskItem;
      });
    },




  mapToColumns (dataReports: ReportsInfo): Column[]{
    let columns: Column[] = [];
    dataReports.headers.forEach(header=> {
      const id = header.columnName.replaceAll(" ","");
      const type = header.dataType
      const column = {
        id,
        label: header.columnName,
        type
      } as Column
      columns.push(column)
    })
    return columns
  },

  mapToData(columns: Column[], dataReports: ReportsInfo) {
    const reports:any =[]
    dataReports.data.forEach(report=>{
      // This generates and empty object each time with the keys from the columns array
      const obj:any = {}
      columns.forEach(column => {
        obj[column.id] = ""
      })
      // The data is asigned to the empty object created
      report.forEach((reportData, index)=>{
          obj[columns[index].id] = reportData
      })
      reports.push(obj)
    })
    return reports
  },

  isClientChecked(clientId:number, formik: any){
    const clientIndex = formik.values.clientsAndFacilities.clientFacilitiesInfo.findIndex((clientFacilities: selectedClientAndFacilities) => clientFacilities.clientId === clientId)
    return formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].checked
  },

  getClientIndex(clientId: number, formik: any){
    return formik.values.clientsAndFacilities.clientFacilitiesInfo.findIndex((clientFacilities: selectedClientAndFacilities) => clientFacilities.clientId === clientId)
  },

  selectedAll(formik: any, allowedClients: Client[], selectedClientsAndFaclities: selectedClientFacilitiesInfo) {
    allowedClients.forEach((client: Client) => {
      const clientIndex = adminUserHelper.getClientIndex(
        client.clientId,
        formik
      );
      formik.setFieldValue(client.clientId, true);
      if (allowedClients[clientIndex].allowedFacilities.length > 0) {
        allowedClients[clientIndex].allowedFacilities.forEach((facility) => {
          if (
            !formik.values.clientsAndFacilities.clientFacilitiesInfo[
              clientIndex
            ].facilities.includes(facility.facilityId)
          ) {
            formik.values.clientsAndFacilities.clientFacilitiesInfo[
              clientIndex
            ].facilities.push(facility.facilityId);
            selectedClientsAndFaclities.clientFacilitiesInfo[clientIndex].facilities.push(facility.facilityId)
            formik.setFieldValue(`${client.clientId}_${facility.facilityId}`, true);
          }
        });
      }
      formik.values.clientsAndFacilities.clientFacilitiesInfo[
        clientIndex
      ].checked = true;
    });
  },

  unSelectedAll(formik: any, allowedClients: Client[], selectedClientsAndFaclities: selectedClientFacilitiesInfo) {
    allowedClients.forEach((client: Client) => {
      const clientIndex = adminUserHelper.getClientIndex(
        client.clientId,
        formik
      );
      const facilities =
        formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex]
          .facilities;
      formik.values.clientsAndFacilities.clientFacilitiesInfo[
        clientIndex
      ].checked = false;
      formik.setFieldValue(client.clientId, false);
      if (facilities.length > 0) {
        facilities.map((facility: any) => {
          formik.setFieldValue(
            `${client.clientId}_${facility.facilityId}`,
            false
          );
          return true;
        });
      }
      formik.values.clientsAndFacilities.clientFacilitiesInfo[
        clientIndex
      ].facilities = [];
      selectedClientsAndFaclities.clientFacilitiesInfo[clientIndex].facilities=[]
    });
  },

  filteredAllowedClients(allowedClients: Client[], searchKeyWord: string) {
    return _.chain(allowedClients)
      .filter(
        (client: Client) =>
          _.chain(client.clientName)
            .toLower()
            .includes(_.toLower(searchKeyWord))
            .value() ||
          _.chain(client.allowedFacilities)
            .some((facility) =>
              _.chain(facility)
                .get("facilityName")
                .toLower()
                .includes(_.toLower(searchKeyWord))
                .value()
            )
            .value()
      )
      .value();
  },

  convertAllowedClients(
    allowedClients: Client[],
    allClients: ClientStatusCardViewModel[]
  ) {
    let tempAllowedClients = [...allowedClients];
    let tempAllClients = [...allClients];
    if (tempAllowedClients.length === 1 && tempAllowedClients[0].clientId === 0) {
      tempAllowedClients = [];
      tempAllClients.forEach((client: ClientStatusCardViewModel, i: number) => {
        tempAllowedClients.push({
          "clientId": client.clientId,
          "clientName": client.clientName,
          "allowedFacilities": client.clientFacilities!,
          isAll: false,
          userClientScopeId: 0
        });
      })
    } else {
      tempAllClients.forEach((client: ClientStatusCardViewModel) => {
        const allowedClient = tempAllowedClients.find(
          (item) => item.clientId === client.clientId
        );
        if (!allowedClient) return;

        const updatedAllowedFacilities = client.clientFacilities
          ?.map((facility: ClientFacility) => {
            const allowedFacility = allowedClient.allowedFacilities.find(
              (item) => item.facilityId === facility.facilityId
            );
            if (allowedFacility) {
              return {
                ...allowedFacility,
                facilityName: facility.facilityName,
              };
            } else {
              return {
                facilityId: facility.facilityId,
                facilityName: facility.facilityName,
              };
            }
          })
          .filter(Boolean);

        const updatedAllowedClient: any = {
          ...allowedClient,
          clientName: client.clientName,
          allowedFacilities:
            updatedAllowedFacilities || allowedClient.allowedFacilities,
        };

        const allowedClientIdx = tempAllowedClients.indexOf(allowedClient);
        tempAllowedClients[allowedClientIdx] = updatedAllowedClient;
      });
    }
    return tempAllowedClients;
  },

  getFacilitiesByClientId (clientId: number, allowedClients: Client[]) {
    const client = allowedClients.find(c => c.clientId === clientId);
    return client ? client.allowedFacilities : [];
  },

  setClientChecked(clientId: number, formik:any, allowedClients: Client[], selectedClientsAndFaclities: selectedClientFacilitiesInfo){
    if(clientId ===0){
      formik.values.clientsAndFacilities.clientFacilitiesInfo.forEach((client: selectedClientAndFacilities)=>{
        client.checked = false
        client.facilities = []
      })
    }
    const clientIndex = adminUserHelper.getClientIndex(clientId, formik)
    const allowedFacilities = adminUserHelper.getFacilitiesByClientId(clientId, allowedClients)
    if (allowedFacilities.length > 0) {
      allowedFacilities.forEach(facility=>{
        if(!formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].facilities.includes(facility.facilityId)){
          formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].facilities.push(facility.facilityId)
          selectedClientsAndFaclities.clientFacilitiesInfo[clientIndex].facilities.push(facility.facilityId)
        }
      })
    }
    formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].checked = true
  },

  removeClientChecked(clientId: number, formik: any, selectedClientsAndFaclities: selectedClientFacilitiesInfo)  {
    const clientIndex = adminUserHelper.getClientIndex(clientId,formik)
    formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].facilities =[]
    selectedClientsAndFaclities.clientFacilitiesInfo[clientIndex].facilities=[]
    formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].checked = false
  },


  isFacilityChecked(clientId: number, facilityId: number, formik: any, selectedClientsAndFaclities: selectedClientFacilitiesInfo){
    const clientIndex = adminUserHelper.getClientIndex(clientId,formik)
    return selectedClientsAndFaclities.clientFacilitiesInfo[clientIndex].facilities.includes(facilityId)
  },

  setFacilityChecked(clientId: number, facilityId: number, formik: any, selectedClientsAndFaclities: selectedClientFacilitiesInfo){
    const clientIndex = adminUserHelper.getClientIndex(clientId,formik)
    formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].facilities.push(facilityId)
    selectedClientsAndFaclities.clientFacilitiesInfo[clientIndex].facilities.push(facilityId)
    if(formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].facilities.length === formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].totalFacilities){
      formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].checked = true
    } else {
      formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].checked = false
    }
  },

  removeFacilityChecked(clientId: number, facilityId: number, formik: any, selectedClientsAndFaclities: selectedClientFacilitiesInfo){
    const clientIndex = adminUserHelper.getClientIndex(clientId,formik)
    const facilityIndex = formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].facilities.findIndex((facility: number) => facility === facilityId)
    formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].facilities.splice(facilityIndex, 1);
    selectedClientsAndFaclities.clientFacilitiesInfo[clientIndex].facilities.splice(facilityIndex, 1);
    formik.values.clientsAndFacilities.clientFacilitiesInfo[clientIndex].checked = false
  },

  mapWorkflowIds(workFlowStatusIds: number[], workFlowSubStatusIds: number[], IdsArray: string[]){
    IdsArray.forEach(IdsString => {
      let IdArray = IdsString.split(',')
      if(!workFlowStatusIds.includes(Number(IdArray[0]))){
        workFlowStatusIds.push(Number(IdArray[0]))
      }

      if(!workFlowSubStatusIds.includes(Number(IdArray[1]))){
        workFlowSubStatusIds.push(Number(IdArray[1]))
      }
    })

    return{workFlowStatusIds, workFlowSubStatusIds }


  },

  mapRiskIds(payorRiskIds: number[], timingRiskIds: number[], IdsArray: string[]){
    IdsArray.forEach(IdsString => {
      let IdArray = IdsString.split(',')
      if(!payorRiskIds.includes(Number(IdArray[0]))){
        payorRiskIds.push(Number(IdArray[0]))
      }

      if(!timingRiskIds.includes(Number(IdArray[1]))){
        timingRiskIds.push(Number(IdArray[1]))
      }
    })


    return{payorRiskIds, timingRiskIds}

  },

  mapAllRiskIds(initialRiskIds: number[], modifiedRiskIds: number[], convertedRiskIds: number[], finalRiskIds: number[], initialIdsArray: string[], modifiedIdsArray: string[], convertedIdsArray: string[], finalIdsArray: string[]){
    initialIdsArray.forEach(IdsString => {
      let IdArray = IdsString.split(",");
      if(!initialRiskIds.includes(Number(IdArray[0]))){
        initialRiskIds.push(Number(IdArray[0]));
      }
    })
    modifiedIdsArray.forEach(IdsString => {
      let IdArray = IdsString.split(",");
      if(!modifiedRiskIds.includes(Number(IdArray[0]))){
        modifiedRiskIds.push(Number(IdArray[0]));
      }
    })
    convertedIdsArray.forEach(IdsString => {
      let IdArray = IdsString.split(",");
      if(!convertedRiskIds.includes(Number(IdArray[0]))){
        convertedRiskIds.push(Number(IdArray[0]));
      }
    })
    finalIdsArray.forEach(IdsString => {
      let IdArray = IdsString.split(",");
      if(!finalRiskIds.includes(Number(IdArray[0]))){
        finalRiskIds.push(Number(IdArray[0]));
      }
    })

    return {initialRiskIds, modifiedRiskIds, convertedRiskIds, finalRiskIds}
  }


}

type GetAllUserFilters = {
  firstName?: string;
  lastName?: string;
  userName?: string;
  role?:  number;
  client?:  number ;
  facility?:  number;
}

export class AdminUserService {
  async getAllUsers(offset: number, limit: number, filters: GetAllUserFilters): Promise<AxiosResultStatus> {

    // converting all values of filters to strings.
    const filtersAsString: Record<string, string> = {};
    for (const key of Object.keys(filters) as (keyof GetAllUserFilters)[]) {
      const value = filters[key];
      if (value !== undefined) {
        filtersAsString[key] = String(value);
      }
    }

    const querystringParams = new URLSearchParams(filtersAsString).toString();

    const queryString = querystringParams ? `${querystringParams}` : '';

    const readPayload: AxiosReadPayload = {
      dataId: 0,
      url: `user/v2/user?${queryString ? `&${queryString}` : ''}`,
    }

    const responseData = await axiosReadHelper(readPayload)
    const allUsers = adminUserHelper.mapUsersForSearch(responseData.entity);

    responseData.entity = allUsers;
    return responseData;
  }


  async getLogs(): Promise<LogsState> {
    //  This is calling and mocked endpoint, this will change
    const response = await axios.get(`${configSettings.server_uri}/logs`);
    const logs = response.data;
    return logs;
  }

  async getReports(): Promise<ReportsInfo[]> {
    //  This is calling and mocked endpoint, this will change
    const response = await axios.get(`${configSettings.server_uri}/reports`);
    const reports = response.data;
    return reports;
  }


  async getReportsInfo(reportsFilters: Filters, offset: number, limit: number): Promise<AxiosResultStatus>  {
    const includeFacilityTotals: boolean = reportsFilters.includeFacilityTotals!;
    if (reportsFilters.reportType === 'SummaryTransactions') {
      delete reportsFilters.includeFacilityTotals;
    }

    const dataToSave ={
      filters: reportsFilters
    }

    let paramId;
    let reportsURL;

    switch(reportsFilters.reportType){
      case 'AllInstanceofCare':
        reportsURL= `patient-encounter/v2/IoCReport?offset=${offset}&limit=${limit}`;
        paramId = 0;
        break;
      case 'SummaryTransactions':
        reportsURL = `payment/v2/transactionReport?includeFacilityTotals=${includeFacilityTotals}&offset=${offset}&limit=${limit}`;
        paramId = 0;
        break;
      case "MergedTransactions":
        reportsURL = `payment/v2/mergedTransactionReport?offset=${offset}&limit=${limit}`;
        paramId = 0;
        break;
      case "AutomatedPayment":
        reportsURL = `payment/v2/automatedPaymentReport?offset=${offset}&limit=${limit}`;
        paramId = 0;
        break;
      case "SettlementSummary":
        reportsURL = `payment/v2/settlementReport?offset=${offset}&limit=${limit}`;
        paramId = 0;
        break;
      case 'PESActivityLog':
        reportsURL = `patient-encounter/v2/report?offset=${offset}&limit=${limit}`;
        paramId = 0
        break;
      case 'SettlementDetail':
        reportsURL= `payment/v2/report?offset=${offset}&limit=${limit}`;
        paramId = -2;
        break;
      default:
        reportsURL = `reports/?offset=${offset}&limit=${limit}`;
        paramId = 0;
    }

    const payload: AxiosSavePayload = {
      dataToSave: dataToSave,
      dataId: paramId,
      url: reportsURL
    }
    return await axiosSaveHelper(payload);
  }

  /**
   *
   * 'filters' argument is an object where the key is the field to filter (string), and the value is an arrow function
   * The function takes a paramter which is the value to evaluate. Returns boolean which determines if item must be included in filter result
   * See admin-users-view.tsx, there are 4 'dispatch(setFilter())' where where there is passed in the key and a value which is the function. the function
   * contains the filtering logic.
   *
   */

  filterUsers(filters: FilterValues, users: UserInfo[]) {
    const filterKeys = Object.keys(filters);
    const filteredUsers = users.filter((user: UserInfo) => {
      return filterKeys.every((key) => {
        switch (key) {
          case "clientId":
            // filters[key] is the filter key, where the value is the arrow function. user.clients is getting passed into the function and invoked
            return filters[key](user.clients);
          case "facilityId":
            return filters[key](user.clients);
          case "roleId":
            return filters[key](user["userRole"][key]);
          case "fullName":
            return filters[key](user[key]);
          default:
            return false;
        }
      }) as boolean;
    });
    // run results through function that transforms it for display in the filter results table
    return adminUserHelper.filteredSearchResults(filteredUsers);
  }

  filterLogs(filters: FilterValues, Logs: LogsInfo[]) {
    const filterKeys = Object.keys(filters);
    const filteredLog = Logs.filter((log: LogsInfo) => {
      return filterKeys.every((key) => {
        switch (key) {
          case "user":
            return filters[key](log[key]);
          case "patient":
            return filters[key](log[key]);
          case "client":
            return filters[key](log[key]);
          default:

            return false;
        }
      }) as boolean;
    });
    return filteredLog;
  }

  async getPaymentChannels() {
    const responseAPI = await axios.get(`${configSettings.unified_aws_app_gateway_uri}/payment/v2/payment/paymentchannels`);
    const paymentChannels = responseAPI.data;
    return paymentChannels;
  }

  async getUser(userId: number): Promise<EditUser> {
    const responseAPI = await axios.get(`${configSettings.unified_aws_app_gateway_uri}/user/v2/user/${userId}`);
    const userData: User = responseAPI.data;
    const mappedUser = adminUserHelper.mapUser(userData);

    const userItem: EditUser = {
      userInfo: mappedUser,
      fullUser: userData
    }

    return userItem;
  }

  async deactivateUser(userId: number): Promise<AxiosResultStatus> {

    const payload: AxiosSavePayload = {
      dataId: userId,
      dataToSave: {
        isActive: false,
      },
      url: `user/v2/user`
    }
    const responseData = await axiosSaveHelper(payload);

    return responseData;
  }

  async reactivateUser(userId: number): Promise<AxiosResultStatus> {

    const payload: AxiosSavePayload = {
      dataId: userId,
      dataToSave: {
        isActive: true,
      },
      url: `user/v2/user`
    }
    return axiosSaveHelper(payload);
  }

  async getUserCognitoStatus(userId: number): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: 0,
      url: `user/v2/user/${userId}`
    }
    return axiosReadHelper(readPayload);
  }

  async saveUser(fullUser: User, userRoles: UserRole[], data: UserInfo): Promise<AxiosResultStatus> {

    let userToSave = new User();
    if (data.userId > 0) {
      userToSave.userId = data.userId;
    }
    userToSave.userName = data.email;
    userToSave.firstName = data.firstName;
    userToSave.lastName = data.lastName;
    userToSave.mobilePhone = data.phoneNumber;
    userToSave.isActive = data.isActive;
    userToSave.isPatient = data.isPatient;
    userToSave.userScope = data.domainAccess
    // userToSave.scopeClientId = fullUser.scopeClientId;
    // userToSave.scopeEntityId = fullUser.scopeEntityId;
    userToSave.clientId = fullUser.clientId;
    userToSave.entityId = fullUser.entityId;
    userToSave.isSSO = data.isSSO;
    userToSave.hasAllTimingRisks = data.hasAllTimingRisks;


    const mappedData = adminUserHelper.mapClientsForUpdateSaveUser(data.clients);

    const mappedTimingRiskData = data.userTimingRisk
  ? adminUserHelper.mapTimingRiskForUpdateSaveUser(data?.userTimingRisk)
  : [];

  userToSave.landingPageRoute = fullUser.landingPageRoute;
  userToSave.hasAgreedToTc = fullUser.hasAgreedToTc;
  userToSave.allowedClients = mappedData;
  userToSave.userTimingRisk = mappedTimingRiskData.concat(fullUser.userTimingRisk || []);

    // Get Full User Role State to Save the User
    const systemRole = userRoles.find(function (role: UserRole) {
      return role.userRoleId === data.userRole.roleId;
    });

    if (systemRole) {
      userToSave.userRole = systemRole;
    }

    const payload: AxiosSavePayload = {
      dataToSave: userToSave,
      dataId: userToSave?.userId ? userToSave?.userId : 0,
      url: "user/v2/user",

    }
    const saveResult = await axiosSaveHelper(payload);

    // Map User Returned From API To Search Result User Info View Model
    if (!saveResult.hasErrors) {
      saveResult.entity = adminUserHelper.mapUser(saveResult.entity);
    }

    return saveResult;
  }

  async sendVerificationEmail(userId: string, patientEncounterId: number) {
    const payload: AxiosSavePayload = {
      dataToSave: { patientEncounterId },
      dataId: 0,
      url: `user/v2/user/${userId}/email`
    }

    return await axiosSaveHelper(payload)
  }

}


export const handleSearchGetUsers =  async (formValues: Partial <UserSearchFormValues>) => {
  let offset = 0;
  const limit = ApiBatchLimits.users;

  const response = await userService.getAllUsers(offset, limit, formValues);

  if (response.hasErrors) {
    const errorMessage = response.entity.showError.data;
    throw new Error(errorMessage);
  }

  const allUsers = response.entity

  if (allUsers.length === 0) {
    throw new Error("No users found for the given criteria.");
  }

  const filteredUsers = adminUserHelper.filteredSearchResults(allUsers)
  const { clients, roles } = adminUserHelper.getUsersFilterData(allUsers)


  const userData = {
    allUsers,
    clientsFilter: clients ? clients : [],
    rolesFilter: roles ? roles : [],
    filteredUsers
  }

  let formatStringValue = (value:string):string =>{
      value = value?.trimStart()
      return value?.charAt(0)?.toLocaleUpperCase() + value?.slice(1)

  }

  userData.clientsFilter.sort(function (
    clientA: UserInfoClient,
    clientB: UserInfoClient
  ) {
    if (formatStringValue(clientA.clientName) < formatStringValue(clientB.clientName)) return -1;
    if (formatStringValue(clientA.clientName) > formatStringValue(clientB.clientName)) return 1;
    return 0;
  });

  return userData

};

export const userService = new AdminUserService();

