import React, {useEffect, useState, useMemo} from 'react';
import {
    Button,
    GenericLevelOfCare,
    getFacilityLevelsOfCareSummary,
} from '@finpay-development/shared-components';
import CloseIcon from '@mui/icons-material/Close';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {formatNumberToUSD} from '../../../shared/calculators';
import {TableColumn} from '../../../shared/model/table-column';
import {RootState} from '../../../shared/state/root-reducer';
import {admissionsAdvisorUtils} from '../../utils/admission-advisor-utils';
import useIsClientConfiguredForNewEstimate from 'src/admissions-advisor/utils/useClientConfigHook';
import {FacilityLevelOfCare} from '@finpay/estimation-types';

interface EstFinancialSummaryModalProps {
    open: boolean;
    handleFinancialSummaryModalCancel: () => void;
}

export function EstFinancialSummaryModal(props: EstFinancialSummaryModalProps) {
    const {open, handleFinancialSummaryModalCancel} = props;

    const state = {
        vobState: useSelector(
            (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
        ),
        estimatorState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.estimator
        ),
        newEstimate: useSelector((state: RootState) => {
            return state.admissionsAdvisorContext.estimatorContext.newEstimate;
        }),
    };

    const {vobState, estimatorState, newEstimate} = state;

    const isClientConfiguredForNewEstimates =
        useIsClientConfiguredForNewEstimate();

    const locTableHeaders: TableColumn[] = [
        {id: 'colLocName', label: 'Levels of Care', align: 'left'},
        {
            id: 'colLocDays',
            label: `Length of Stay (${
                estimatorState.serviceLevel === 'outpatient'
                    ? 'sessions'
                    : 'days'
            })`,
            align: 'left',
        },
        {id: 'colLocChgs', label: 'Adjusted Charges', align: 'right'},
    ];

    const levelsOfCareSummary = getFacilityLevelsOfCareSummary(
        estimatorState.selectedLevelsOfCare,
        estimatorState.quoteMethod,
        vobState?.payer?.inNetwork!,
        vobState?.selfPay!
    );

    const financialSummary = useMemo(() => {
        if (isClientConfiguredForNewEstimates && newEstimate?.summary)
            return newEstimate.summary;

        return admissionsAdvisorUtils.calculateFinancialSummary(
            estimatorState,
            vobState
        );
    }, [estimatorState, vobState, newEstimate]);

    function handleModalCancel() {
        handleFinancialSummaryModalCancel();
    }

    function adjustedChgs() {
        let charges = 0.0;
        levelsOfCareSummary.forEach(loc => {
            if (loc.cfgLocType === 1) return;
            charges += loc?.rate * loc?.days;
        });
        return formatNumberToUSD(charges);
    }

    function estimatedLos() {
        let los = 0;
        levelsOfCareSummary.forEach(loc => {
            if (loc.cfgLocType === 1) return;
            los += loc?.days;
        });
        return los;
    }

    const genericLOCTableRows = _.sortBy(levelsOfCareSummary, 'name')
        .filter((loc: GenericLevelOfCare) => {
            if (loc.cfgLocType === 1) return false;
            return true;
        })
        .map((row: GenericLevelOfCare, index: number) => {
            return (
                <TableRow key={index} className="py-3">
                    <TableCell>
                        {row?.code}-{row?.name}
                    </TableCell>
                    <TableCell>{row?.days}</TableCell>
                    <TableCell align="right">
                        {formatNumberToUSD(row?.days * row?.rate)}
                    </TableCell>
                </TableRow>
            );
        });

    const newEstimateTableRows = _.sortBy(
        newEstimate?.facilityLevelOfCare,
        'facilityLevelOfCareName'
    )
        .filter((loc: FacilityLevelOfCare) => {
            if (loc.locType === 1) return false;
            return true;
        })
        .map((row: FacilityLevelOfCare, index: number) => {
            return (
                <TableRow key={index} className="py-3">
                    <TableCell>
                        {row?.facilityLevelOfCareCode}-
                        {row?.facilityLevelOfCareName}
                    </TableCell>
                    <TableCell>{row?.losDays}</TableCell>
                    <TableCell align="right">
                        {formatNumberToUSD(row?.losDays * row?.pdrRate)}
                    </TableCell>
                </TableRow>
            );
        });

    const tableRows =
        isClientConfiguredForNewEstimates &&
        newEstimate?.facilityLevelOfCare &&
        newEstimate.facilityLevelOfCare.length > 0
            ? newEstimateTableRows
            : genericLOCTableRows;
    const estimatedAdjustedCharges =
        isClientConfiguredForNewEstimates && newEstimate?.summary
            ? formatNumberToUSD(
                  newEstimate.summary.coveredCharges +
                      newEstimate.summary.unCoveredCharges
              )
            : adjustedChgs();
    const estimateLenghtOfStay =
        isClientConfiguredForNewEstimates && newEstimate?.summary
            ? newEstimate.summary.lengthOfStay
            : estimatedLos();
    const coveredServicesPFR = formatNumberToUSD(
        financialSummary.patientTotalCoPays +
            financialSummary.patientTotalCoInsurance +
            financialSummary.deductibleSpent
    );

    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <DialogTitle>Patient Financial Responsibility Summary</DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleModalCancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <Typography variant="subtitle2">Care Plan Details</Typography>

                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableHead>
                            <TableRow>
                                {locTableHeaders.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows}
                            <TableRow className="py-3 light-green-row">
                                <TableCell colSpan={2}>
                                    Estimated Adjusted Charges
                                </TableCell>
                                <TableCell align="right">
                                    {estimatedAdjustedCharges}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3 light-green-row">
                                <TableCell colSpan={2}>
                                    Estimated Length of Stay
                                </TableCell>
                                <TableCell align="right">
                                    {estimateLenghtOfStay}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="subtitle2" className="mt-2">
                    PFR Details
                </Typography>
                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableBody>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Pay</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.patientTotalCoPays
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Deductible</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.deductibleSpent
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Insurance</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.patientTotalCoInsurance
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell></TableCell>
                                <TableCell
                                    align="right"
                                    sx={{borderTop: '2px solid #999'}}
                                >
                                    <strong>{coveredServicesPFR}</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>
                                    Out of Pocket Max Remaining
                                </TableCell>
                                <TableCell align="right">
                                    {financialSummary.includeOOPmax ? (
                                        formatNumberToUSD(
                                            financialSummary.remainingOopMaxFromVob
                                        )
                                    ) : (
                                        <>N/A</>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>PFR for Covered Services</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(financialSummary.netPFR)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Uncovered Services</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.unCoveredCharges
                                    )}
                                </TableCell>
                            </TableRow>
                            {financialSummary.totalSurcharges > 0 && (
                                <>
                                    <TableRow className="py-3">
                                        <TableCell>PFR Subtotal</TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                financialSummary.subtotalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            Admission Assessment
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                financialSummary.totalSurcharges
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                            <TableRow className="py-3">
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        Estimated Patient Financial
                                        Responsibility
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2">
                                        {formatNumberToUSD(
                                            financialSummary.totalPFR
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleModalCancel()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
