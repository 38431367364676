import Checkbox from "@mui/material/Checkbox";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import React, { useState } from 'react';
import { FormControlLabel, Grid, TextField as MuiTextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { admissionsAdvisorUtils } from '../../../admissions-advisor/utils/admission-advisor-utils';
import { TakePaymentFields } from './take-payment-fields';
import { RootState } from '../../state/root-reducer';

interface TakePaymentViewProps {
  formik: any;
  handleStripeStatus: (status: boolean) => void;
}

export function TakePaymentView(props: TakePaymentViewProps) {
  const {
    formik,
    handleStripeStatus
  } = props;

  const [displayPFRTotalError, setDisplayPFRTotalError] = useState(false);

  const state = {
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    )
  };

  const { estimatorState } = state;

  const handleAmountChange = (e: any, fieldName: string) => {
    const { value } = e.target;
    const maxAmount: number = admissionsAdvisorUtils.getSelectedPFR(estimatorState);
    const sanitizedValue: number = Math.min(parseFloat(value), maxAmount);
    const pfrAmt = admissionsAdvisorUtils.getSelectedPFR(estimatorState);
    
    if (fieldName === 'payNowAmt') {
      formik.setFieldValue('payNowAmt', sanitizedValue);
    } else if (fieldName === 'payAtFacilityAmt') {
      formik.setFieldValue('payAtFacilityAmt', sanitizedValue);
    }

    if((pfrAmt - Number(formik.values.payAtFacilityAmt) - Number(formik.values.payNowAmt)) < 0){
      setDisplayPFRTotalError(true);
    } else {
      setDisplayPFRTotalError(false);
    }
  };

  return (
    <>
      <Typography variant="subtitle2" className="my-3" style={{marginTop: '22px'}}>
        Financial Clearance Details
      </Typography>
      <Grid container>
        <Grid item direction="column" md={4} sm={4} >
          <FormControlLabel control={
            <Checkbox
              style={{marginLeft: 3}}
              checked={formik.values.isPayNow}
              name="isPayNow"
              onChange={(e)=>{
                formik.handleChange(e)
                formik.setFieldValue("payNowAmt", "");
              }}/>}
                label="Collect Payment Now ?"
                labelPlacement="start"
                style={{marginLeft:0}} 
          />
        </Grid>
        <Grid item direction="column" md={8} sm={8} >
          <FormControlLabel
            control={
              <MuiTextField
                type="number"
                name="payNowAmt"
                value={formik.values.payNowAmt}
                onChange={(e) => {
                  handleAmountChange(e, 'payNowAmt');
                }}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: <AttachMoneyIcon style={{color: '#afbecd'}}/>
                }}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={!formik.values.isPayNow}
              />
            } label={
              <Typography sx={{width: 7/10, textAlign: 'center', paddingLeft: 2}}>
                Amount to be Collected
              </Typography>
            } labelPlacement="start"  style={{marginLeft:20}}
          />
        </Grid>
      </Grid>
      <Grid container style={{marginTop: '6px'}}>
        <Grid item direction="column" md={4} sm={4} >
          <FormControlLabel control={
            <Checkbox
              checked={formik.values.isPayAtFacility}
              name="isPayAtFacility"
              onChange={(e)=>{
                formik.handleChange(e);
                formik.setFieldValue("payAtFacilityAmt", "");
              }}/>}
              label="Collect Payment at Facility ?"
              labelPlacement="start"
              style={{marginLeft:0}} 
            />
        </Grid>
        <Grid item direction="column" md={8} sm={8} >
          <FormControlLabel control={
            <MuiTextField
                name="payAtFacilityAmt"
                type="number"
                value={formik.values.payAtFacilityAmt}
                onChange={(e) => {
                  handleAmountChange(e, 'payAtFacilityAmt')
                }}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: <AttachMoneyIcon style={{color: '#afbecd'}}/>
                }}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={!formik.values.isPayAtFacility}
            />} label={
            <Typography sx={{width: 7/10, textAlign: 'center', paddingLeft: 2}}>
              Amount to be Collected
            </Typography>
          } labelPlacement="start"  style={{marginLeft:20}}/>
        </Grid>
      </Grid>
      <Typography sx={{width: '100%', paddingTop: '8px', textAlign: 'right', fontSize: '13px', fontStyle: 'italic', color: displayPFRTotalError ? 'red' : 'black'}}>
        Total payment amount cannot exceed pfr amount
      </Typography>
      { formik.values.isPayNow && 
        <Grid container style={{marginTop: '6px'}} >
          <Typography variant="subtitle2" className="my-3" style={{marginTop: '22px'}}>
            Payment Details
          </Typography>
          <TakePaymentFields 
            paymentMethodsFailed={false}
            requiresExistingSource={false}
            requiresRecurringPayment={false}
            formik={formik}
            stripeStatus={handleStripeStatus}
          />
        </Grid>
      }
    </>
  );
}
