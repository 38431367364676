import "./../../scss/components/_tabs.scss";
import "./../../scss/pages/admin/_admin-configuration.scss";

import { Button, Status } from "@finpay-development/shared-components";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Grid, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StatusColors } from "../../shared/enums";
import { RootState } from "../../shared/state/root-reducer";
import Estimator from "./pfr-estimator/estimator";
import { RiskAssessmentMain } from "./risk-assessment/risk-assessment-main";
import VerificationOfBenefits from "./vob/verification-of-benefits";
import { admissionsAdvisorUtils } from "../utils/admission-advisor-utils";
import AccessControl, { checkPermissions } from "../../security/components/access-control";
import { RolePageNames } from "../../security/model/role-page-names";

function AdmissionsAdvisorView() {
  const [aaViewTabIndex, setAaViewTabIndex] = useState(0);
  const [showEstimateSummary, setShowEstimateSummary]  = useState(true);
  const [riskAsessmentTabDisabled, setRiskAsessmentTabDisabled] = useState(true);
  const vobPatientState = useSelector((state: RootState) => {
    return state.admissionsAdvisorContext.vobPatientContext;
  });
  const vobState = useSelector((state: RootState) => {
    return state.admissionsAdvisorContext.vobContext;
  });
  const estimatorState = useSelector((state: RootState) => {
    return state.admissionsAdvisorContext.estimatorContext;
  });
  const userProfile = useSelector((state: RootState) => {
    return state.userContext.userProfile;
  });
  const patientLedgerAccess = checkPermissions(userProfile.userRole.userRolePages, RolePageNames.PatientRecords, "Patient Ledger", false)

  useEffect(() => {
    checkRiskAssessmentEnabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState]);

  function handleAaViewChange(
    event: React.ChangeEvent<{}>,
    newTabIndex: number
  ) {
    setAaViewTabIndex(newTabIndex);
  }

  const handlePatientLedgerClick = (clientId: number, patientId: number) => {
    window.open(`/specialist/dashboard/client/${clientId}/patient/${patientId}/ledger`, '_blank');
  };

  function VobIcon() {
    return vobState?.vob?.isValid ? <CheckCircleOutlineIcon /> : <></>;
  }

  function checkRiskAssessmentEnabled(hasAccess: boolean) {
    if (
      hasAccess &&
      estimatorState?.estimator?.selectedLevelsOfCare?.length > 0
    ) {
      if (riskAsessmentTabDisabled) setRiskAsessmentTabDisabled(false);
    } else {
      if (!riskAsessmentTabDisabled) setRiskAsessmentTabDisabled(true);
    }
  }

  function PfrEstimatorIcon() {
    return estimatorState?.estimator?.selectedLevelsOfCare?.length > 0 ? (
      <CheckCircleOutlineIcon />
    ) : (
      <></>
    );
  }

  function clickEstimatorTab(tab: number) {
    setAaViewTabIndex(tab);
  }

  return (
    <>
      <main className="admin-configuration">
        <div className="content">
          {/* Patient Info Box */}
          {(vobState.vob?.isValid && showEstimateSummary) && (
            <>
              <Box
                border={1}
                borderColor="primary.main"
                borderRadius="5px"
                className="mb-5"
              >
                <Grid container direction="row">
                  <Grid
                    item
                    xs={3}
                    style={{
                      textAlign: "center",
                      padding: "0.25em",
                      color: "white",
                      backgroundColor: "#297EC1",
                    }}
                  >
                    Currently Viewing
                  </Grid>
                </Grid>
                <Box style={{ margin: "0.5em" }}>
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={4}>
                      <strong>
                        {vobPatientState?.patient?.firstName}{" "}
                        {vobPatientState?.patient?.lastName}
                      </strong>
                      /{vobPatientState?.patient?.advisorPatientId}
                      <br />
                      {vobState?.vob?.payer?.payorName}/
                      {vobState?.vob?.plan?.planName}
                      <br />
                      { patientLedgerAccess && vobPatientState?.patient?.fpPatientId &&
                        <IconButton
                          color="primary"
                          title="Open Details"
                          style={{ fontSize: "1.5rem" }}
                          onClick={() =>
                            handlePatientLedgerClick(vobState?.vob?.client?.clientId!, vobPatientState?.patient?.fpPatientId!)
                          }
                        >
                          <OpenInNewIcon fontSize="small" /> View Master Patient Ledger
                        </IconButton>
                      }
                    </Grid>
                    <Grid item xs={vobState?.vob?.selfPay ? 6 : 4}>
                      {vobState?.vob?.client?.clientName}
                      <br />
                      {vobState?.vob?.facility?.facilityName}
                    </Grid>
                    {!vobState?.vob?.selfPay && (
                      <Grid item xs={2} alignItems="center">
                        <Status
                          text={
                            admissionsAdvisorUtils.isPolicyActive(vobState?.vob)
                              ? "Active Policy"
                              : "Inactive Policy"
                          }
                          statusColor={
                            admissionsAdvisorUtils.isPolicyActive(vobState?.vob)
                              ? StatusColors.success
                              : StatusColors.error
                          }
                        />
                        <Status
                          text={
                            vobState?.vob?.payer?.inNetwork
                              ? "In Network"
                              : "Out of Network"
                          }
                          statusColor={
                            vobState?.vob?.payer?.inNetwork
                              ? StatusColors.success
                              : StatusColors.error
                          }
                        />
                      </Grid>
                    )}

                    <Grid item xs={2} className="mb-6">
                      <Button
                        type="secondary"
                        onClick={() => setShowEstimateSummary(false)}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
          {/* Tabs */}
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} className="tabs">
              <AccessControl
                rolePageName={RolePageNames.AdmissionsAdvisor}
                actionName="Risk Assessment"
                renderNoAccess={() => <>{checkRiskAssessmentEnabled(false)}</>}
              >
                <></>
              </AccessControl>

              <Tabs
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                value={aaViewTabIndex}
                onChange={handleAaViewChange}
              >
                <Tab disableRipple label="V.O.B" icon={VobIcon()} />
                <Tab
                  icon={PfrEstimatorIcon()}
                  disableRipple
                  disabled={!vobState?.vob?.isValid}
                  label="PFR Estimator"
                  className="tab-align"
                />
                <Tab
                  disableRipple
                  disabled={riskAsessmentTabDisabled}
                  label="Admissions Risk Assessment"
                  className="tab-align"
                />
                <Tab
                  disableRipple
                  disabled={true}
                  sx={{ display: "none" }}
                  label="Patient Program Calculator"
                />
                <Tab
                  disableRipple
                  disabled={true}
                  sx={{ display: "none" }}
                  label="Plan Year Crossover Calculator"
                />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <div>
                {aaViewTabIndex === 0 && (
                  <VerificationOfBenefits
                    handleEstimatorTabClick1={clickEstimatorTab}
                  />
                )}
                {aaViewTabIndex === 1 && (
                  <Estimator handleEstimatorTabClick1={clickEstimatorTab} />
                )}
                {aaViewTabIndex === 2 && <RiskAssessmentMain />}
                {aaViewTabIndex === 3 && <p>Patient Program Calculator</p>}
                {aaViewTabIndex === 4 && <p>Plan Year Crossover Calculator</p>}
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </>
  );
}

export default AdmissionsAdvisorView;
