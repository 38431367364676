import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { formatNumberToUSD } from "../../../shared/calculators";
import { Typography, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import useIsClientConfiguredForNewEstimate from "src/admissions-advisor/utils/useClientConfigHook";

export function SidebarFinancialSummary() {

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.estimator
    ),
	newEstimate: useSelector((state: RootState) => {
		return state.admissionsAdvisorContext.estimatorContext.newEstimate
	}),
  };

	const {
		vobState,
		estimatorState,
		newEstimate,
	} = state;

	const isClientConfiguredForNewEstimates = useIsClientConfiguredForNewEstimate()

  const financialSummary = useMemo(() => {
	if(isClientConfiguredForNewEstimates && newEstimate?.summary) return newEstimate.summary

	return admissionsAdvisorUtils.calculateFinancialSummary(estimatorState, vobState); 
  }, [estimatorState, vobState, newEstimate])

  const totalCharges = formatNumberToUSD(financialSummary.coveredCharges + financialSummary.unCoveredCharges)
  const coveredCharges = formatNumberToUSD(financialSummary.netPFR)
  const unCoveredCharges = formatNumberToUSD(financialSummary.unCoveredCharges) 
  const totalSurcharges = formatNumberToUSD(financialSummary.totalSurcharges) 

  const showSurcharges = financialSummary.totalSurcharges > 0 

  const summaryExists = estimatorState?.selectedLevelsOfCare?.length > 0 || (isClientConfiguredForNewEstimates && newEstimate?.facilityLevelOfCare && newEstimate?.facilityLevelOfCare?.length > 0)
	return (
		<>

			{summaryExists ? (
				<>
					<TableContainer>
						<Table size="small" aria-label="a dense table">
							<TableBody>
									<TableRow >
										<TableCell component="th" scope="row">
										Estimated Adjusted Charges
										</TableCell>
										<TableCell align="right"><strong>{totalCharges}</strong></TableCell>
									</TableRow>
									<TableRow >
										<TableCell component="th" scope="row">
										PFR for Covered Services
										</TableCell>
										<TableCell align="right"><strong>{coveredCharges}</strong></TableCell>
									</TableRow>
									<TableRow style={showSurcharges ? {} : {borderBottom: "none"}}>
										<TableCell component="th" scope="row" >
										PFR for Uncovered Services
										</TableCell>
										<TableCell align="right"><strong>{unCoveredCharges}</strong></TableCell>
									</TableRow>
									{showSurcharges && (
									<TableRow style={{borderBottom: "none"}}>
										<TableCell component="th" scope="row" >
										Admission Assessment
										</TableCell>
										<TableCell align="right" ><strong>{totalSurcharges}</strong></TableCell>
									</TableRow>
									)}
							</TableBody>
						</Table>
					</TableContainer>
				</>

      ) : (
				<Typography 
					align="center"
					style={{
						color: "#B4BECC",
						fontSize: "10pt"
					}}>
					<PermContactCalendarIcon /><br/>Add levels of care to estimate patient financial responsibility
				</Typography>
      )}

		</>
	)
	

}