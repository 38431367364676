import { RootState } from "../../shared/state/root-reducer";
import { UserRolePage } from "../model/user-role-page";
import { UserRolePageAction } from  "../model/user-role-page-action";
import { useSelector } from "react-redux";

interface IAccessControlProps {
  rolePageName: string;
  actionName: string;
  children: any;
  renderNoAccess: any;
  isAccountHolderPortal?: boolean;
}

export const checkPermissions = (userRolePages: UserRolePage[], rolePageName: string, actionName: string, isAccountHolderPortal: boolean) => {
  if (userRolePages.length === 0) {
    return true;
  }

  // Get the page from the user role pages
  const userRolePage = userRolePages.find((page: UserRolePage) => { return page?.sysRolePage?.rolePageName === rolePageName });

  // Check if the page is readonly, if it's read only, then no need to check the page actions.
  if (userRolePage?.isReadOnly) {
    return false;
  }
  return (
    userRolePage?.userRolePageActions?.findIndex(
      (action: UserRolePageAction) => {
        return (action?.sysRolePageAction?.actionName === actionName &&
            (action.isEnabled || isAccountHolderPortal));
      }
    ) !== -1
  );
};

const AccessControl = ({ rolePageName, actionName, children, renderNoAccess, isAccountHolderPortal = false }: IAccessControlProps) => {
  const userRolePages = useSelector(
    (state: RootState) => state.userContext.userProfile.userRole.userRolePages
  );
  const permitted = checkPermissions(userRolePages, rolePageName, actionName, isAccountHolderPortal);

  if (permitted) {
    return children;
  } else {
    return renderNoAccess();
  }
};

AccessControl.defaultProps = {
  renderNoAccess: () => null,
};

export default AccessControl;