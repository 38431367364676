import '../../../scss/pages/admin/_admin-clients.scss';

import {
  DatePicker,
  DialogActionButton,
  saveCallbackStatus,
  Status,
  TextField,
} from '@finpay-development/shared-components';
import { Divider, MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { createCommentSuccesMessages } from '../../../shared/enums';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { commonFields } from '../../../shared/validation/schemas';
import { getPatientStatusByStatusId, StatusType } from '../misc/patient-status';
import { createPatientNote, getInstanceOfCare } from '../../state/patient-thunk';
import { emptyPatientNote, PatientNotes } from '../models/patient-note';
import { workFlowStatus} from '../models/workflow-status';
import {Workflow} from '../../../shared/model/client';
import { paynowService as paymentService } from "src/guest/services/paynow-service";


interface ChangeStatusModalProps {
  open: boolean;
  handleModalCancel: () => void;
  handleModalSubmit: (obj: {
    workFlow: Workflow;
    encounterId: number;
    patientId: number
  }) => void;
}

export function ChangeStatusModal(props: ChangeStatusModalProps) {
  const { open, handleModalCancel, handleModalSubmit } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false)
  const [hideOnHoldDate, setHideOnHoldDate] = useState(true)
  const isValueSelected = false;
  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    selectedEncounter: useSelector((store: RootState) => {
        return store.patientContext?.selectedEncounter;
    }),
    isConverted: useSelector((store: RootState) => {
      return store.patientContext?.selectedEncounter?.isConverted
    }),
    currentPaymentProgramId: useSelector(
      (state: RootState) => state.patientContext.selectedEncounter.patientPaymentProgram[0]?.patientPaymentProgramId
  ),
  }
  const { selectedEncounter, isConverted, currentPaymentProgramId } = selectors;


  async function handleSave() {
    let isReminder = !hideOnHoldDate
    const currentDate = new Date();
    const noteDt = currentDate.toISOString()
    let completionDueDt = formRef?.current?.values?.completionDueDt;

    let workFlow = formRef?.current?.values.workflowStatusId as Workflow;

    let noteText = formRef?.current?.values.noteText;
    let encounterId = selectedEncounter?.patientEncounterId;
    let patientId = selectedEncounter?.patientId;
    let payloadToSave = {workFlow, encounterId, patientId};
    let changeStatusComment: PatientNotes = emptyPatientNote
    if (noteText){
      if (isReminder) {
        if (completionDueDt){
          workFlow.workflowStatusDt = completionDueDt.toISOString();
          const completionDueDtFormatted= new Date(completionDueDt)
          changeStatusComment.completionDueDt = completionDueDtFormatted.toISOString()
        } else {
          const newDate = new Date();
          newDate.setDate(newDate.getDate() + 30);
          changeStatusComment.completionDueDt = newDate.toISOString()
        }
      }
      changeStatusComment.noteDt = noteDt;
      changeStatusComment.isReminder = isReminder;
      changeStatusComment.noteText = noteText;
      changeStatusComment.patientEncounterId = encounterId;
      changeStatusComment.patientId = patientId;

      dispatch(createPatientNote({message: createCommentSuccesMessages.statusChangeComment, comment: changeStatusComment}))
    }
    await handleModalSubmit(payloadToSave);
    
    if (currentPaymentProgramId != null) {
      await paymentService.updatePaymentProgram(currentPaymentProgramId);
  }
    // change status endpoint returns an incomplete workflow object. need to re-fetch the IOC upon changing status.
    await dispatch(getInstanceOfCare({patientId: patientId, encounterId: encounterId}))
  }


  function handleCancelCallback() {
    handleModalCancel();
  }



  const initialValues = {
    workflowStatusId: '-1',
    noteText: '',
    noteDt:"",
    completionDueDt: ""
  };

  const validationSchema = Yup.object(
    {
      noteText: Yup.string().required(),
      workflowStatusId: Yup.object().test('validate ', (value:any) => {
      return value !== "-1";
    }),
    completionDueDt: Yup.string().when("workflowStatusId", {
      is: (val: any) => {
        return val?.workflowSubStatus?.workflowSubStatusId === 26 // Date is required if you choose At Risk: On Hold
      },
      then: commonFields.REQUIRED_STRING,
      otherwise: Yup.string()
    })
    }
  );

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <span className="subtitle">Change Status</span>
          </Grid>

        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid xs={4} item>
                  <Typography variant="body1">Current Workflow Status:</Typography>
                </Grid>
                <Grid xs={8} item>
                  <Status
                    typographyVariant="h3"
                    text={`${selectedEncounter?.workflow?.workflowStatus?.workflowStatusDesc}${selectedEncounter?.workflow?.workflowSubStatus?.workflowSubStatusDesc && ': '}
                        ${selectedEncounter?.workflow?.workflowSubStatus?.workflowSubStatusDesc && selectedEncounter?.workflow?.workflowSubStatus?.workflowSubStatusDesc}`}
                    statusColor={
                      selectedEncounter?.workflow?.workflowSubStatus?.workflowSubStatusDesc === "None" ?
                      getPatientStatusByStatusId(
                          selectedEncounter?.workflow?.workflowStatus?.workflowStatusId,
                          StatusType["primary-status"],
                          selectedEncounter?.workflow?.workflowId,
                      ).color :
                      getPatientStatusByStatusId(
                        selectedEncounter?.workflow?.workflowSubStatus?.workflowSubStatusId,
                        StatusType["sub-status"],
                        selectedEncounter?.workflow?.workflowId,
                      ).color}
                  />
                </Grid>
                <Grid xs={12} item className="mb-1">
                  <Divider />
                </Grid>
                <Grid xs={12} item className="mb-2">
                  <TextField
                    select={true}
                    error={
                      isValueSelected && formik.touched["workflowStatusId"] && formik.errors["workflowStatusId"]
                    }
                    label="Change Payment Workflow Status"
                    name="workflowStatusId"
                    required={true}
                    value={formik.values.workflowStatusId}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.setFieldValue("workflowStatusId",e.target.value)
                      if(e.target.value.workflowStatus.workflowStatusId === 10 &&
                         e.target.value.workflowSubStatus.workflowSubStatusId === 26
                        ){
                          setHideOnHoldDate(false)
                        } else {
                          setHideOnHoldDate(true)
                        }
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem disabled value="-1">Select Payment Workflow Status</MenuItem>
                    {/* eslint-disable-next-line array-callback-return */}
                    {workFlowStatus[isConverted ? 'Converted' : 'preconvert'].map((workflowStatus: any) => {
                      return (
                        <MenuItem key={workflowStatus.workflowSubStatus.workflowSubStatusId} value={workflowStatus}>
                          {`${workflowStatus.workflowStatus.workflowStatusName}${workflowStatus.workflowSubStatus?.workflowSubStatusName && ': '}
                            ${workflowStatus.workflowSubStatus?.workflowSubStatusName && workflowStatus.workflowSubStatus.workflowSubStatusName}`}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid xs={4} hidden={hideOnHoldDate} item className="mb-2">
                  <DatePicker
                  minDate= {new Date()}
                  className="m-0"
                  label="Remind On Date"
                  value={formik.values.completionDueDt}
                  onChange={(date: Date) => {
                    formik.setFieldValue('completionDueDt', date)
                  }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["noteText"] && formik.errors["noteText"]
                    }
                    label="Comments"
                    name="noteText"
                    multiline={true}
                    value={formik.values.noteText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={true}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={() => {}}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}