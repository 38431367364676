import React, {useEffect, useState} from 'react';
import {
  getAnonymousRequestToken, getLaunchPayload,
} from '../../state/guest-thunk';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch} from '../../../shared/state/store';
import {useParams} from 'react-router';
import {paynowService} from '../../services/paynow-service';
import {RootState} from '../../../shared/state/root-reducer';
import {
  PatientEncounter,
} from '../../../patient/components/models/patient-encounter';
import {Button, Snackbar} from '@finpay-development/shared-components';
import {
  MobileInstallmentDocModal,
} from '../../modal/mobile-installment-doc-modal';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import {formatNumberToUSD} from '../../../shared/calculators';
import {
  ApplicationStatusState,
  emptyApplicationStatus, GetError,
} from '../../../security/model/application-status';
import {
  PatientPaymentProgram,
} from '../../../patient/components/models/patient-payment-program';
import {
  PatientPaymentSchedule,
} from '../../../patient/components/models/patient-payment.schedule';
import {MobilePaymentSPAModal} from '../../modal/mobile-payment-spa-modal';
import {logUserOut} from '../../../security/state/user-thunk';

export const PaymentProgramEnrollment = () => {

  const dispatch = useDispatch<AppDispatch>();
  const authToken = useSelector(
      (state: RootState) => state.guestContext?.authToken);
  const params = useParams<{ token?: string }>();
  const [patientEncounter, setPatientEncounter] = useState<PatientEncounter | null>(
      null);
  const [isInstModalOpen, setIsInstModalOpen] = useState<boolean>(false);
  const [isSPAModalOpen, setIsSPAModalOpen] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [msg, setMsg] = useState<ApplicationStatusState>(
      emptyApplicationStatus);

  /**
   * Todo:
   * 1) Display appropriate message if user attempts to approve an already approved payment program
   * 2) Add check/error handling if authorizationDocumentStatus record doesn't exist
   * */

  useEffect(() => {

    const getTokenAndPatientEncounter = async () => {

      const tokenResponse = await dispatch(getAnonymousRequestToken());

      const launchPayloadResponse: any = await dispatch(getLaunchPayload(({
        'encryptedValue': params.token as string,
        'authToken': tokenResponse?.payload?.token,
      })));

      if (launchPayloadResponse.payload?.isProcessingError) {
        throw Error('Error decrypting patient encounter id');
      }

      const patientEncounterResponse = await paynowService.getPatientEncounter(
          +launchPayloadResponse?.payload?.patientEncounterId,
          tokenResponse?.payload?.token,
      );

      checkPaymentProgramData(
          patientEncounterResponse.patientPaymentProgram[0].patientPaymentSchedule);

      setPatientEncounter(patientEncounterResponse);

    };

    /**
     * A temporary measure to log out user session before loading the page.
     * If a patient portal session exists, it interferes with the loading of all guest pages.
     * */
    dispatch(logUserOut());

    getTokenAndPatientEncounter().catch((err) => {
      console.error(err);
      setMsg(GetError(
          'There is something wrong with your payment program. If the problem persists, please reach out to a FinPay administrator.'));
    });

  }, []);

  const isFullPay = patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentFreq ===
      'F';

  return (
      <Grid
          container
          justifyContent="center"
          sx={{width: '100vw'}}
      >
        {
          patientEncounter && !msg.hasErrorOccurred ? (
              <Grid
                  item
                  sx={{
                    padding: '1rem 2rem',
                    maxWidth: '500px',
                    minWidth: '300px',
                  }}
              >
                <Grid>
                  <Typography variant="h3" className="pt-2" align="center"
                              color="primary">
                    {`${patientEncounter?.client?.clientName} ${isFullPay ?
                        'Single Payment Agreement' :
                        'Payment Program'}`}
                  </Typography>
                </Grid>
                <Grid container sx={{margin: '1rem 0'}}>
                  <Grid item xs={8} className="mt-2">
                    <Typography variant="subtitle2">
                      Estimated PFR:
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className="mt-2">
                    <Typography variant="subtitle2" align="right">
                      {formatNumberToUSD(
                          patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.pfrAmt)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle2">
                      {`${isFullPay ? 'Payment' : 'Down Payment'}:`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" align="right">
                      {formatNumberToUSD(
                          patientEncounter?.patientPaymentProgram?.[0]?.downPmtAmt)}
                    </Typography>
                  </Grid>
                  {
                      !isFullPay && (
                          <>
                            <Grid item xs={8}>
                              <Typography variant="subtitle2">
                                {getRecurringPaymentText(
                                    patientEncounter?.patientPaymentProgram?.[0])}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="subtitle2" align="right">
                                {formatNumberToUSD(
                                    patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentDueAmt)}
                              </Typography>
                            </Grid>
                          </>
                      )
                  }
                </Grid>
                <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                  <Typography>{`${isFullPay ?
                      'Click below to approve this payment' :
                      'Click below to enroll in the above payment program'}:`}</Typography>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                  <Typography>
                    Please review the {' '}
                    <Typography
                        component="span"
                        variant="body1"
                        color="primary"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          if (isFullPay) {
                            setIsSPAModalOpen(true);
                          } else {
                            setIsInstModalOpen(true);
                          }
                        }}
                    >
                      {`${isFullPay ?
                          'payment agreement' :
                          'installment agreement'}`}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid sx={{marginBottom: '1rem'}}>
                  <FormControlLabel
                      control={<Checkbox onChange={(e) => {
                        setIsCheckboxChecked(e.target.checked);
                      }}/>}
                      label="I accept"/>
                </Grid>
                <Grid container justifyContent="center">
                  <Button disabled={!isCheckboxChecked}>Approve</Button>
                </Grid>
              </Grid>
          ) : (
              <Grid
                  item
                  container
                  justifyContent="center"
              >
                <Grid item sx={{marginTop: '3rem'}}>
                  <CircularProgress/>
                </Grid>
              </Grid>
          )
        }
        <MobileInstallmentDocModal
            isOpen={isInstModalOpen}
            handleModalCancel={() => {
              setIsInstModalOpen(false);
            }}/>
        <MobilePaymentSPAModal
            isOpen={isSPAModalOpen}
            handleModalCancel={() => {
              setIsSPAModalOpen(false);
            }}
        />
        <Snackbar
            open={!!msg?.showMessage}
            message={msg.message}
            type={msg?.messageType}
            onClose={() => {
              setMsg(emptyApplicationStatus);
            }}
        />
      </Grid>
  );
};

const checkPaymentProgramData = (patientPaymentSchedule: PatientPaymentSchedule) => {

  if (!patientPaymentSchedule) {
    console.error('No Payment Schedule exists: ', patientPaymentSchedule);
    throw Error();
  }

  if (patientPaymentSchedule.terms < 0) {
    console.error('Payment Program should not have a negative term value',
        patientPaymentSchedule?.terms);
    throw Error();
  }

};

const getRecurringPaymentText = (patientPaymentProgram: PatientPaymentProgram) => {

  const terms = patientPaymentProgram?.patientPaymentSchedule?.terms;
  let termAndPaymentFreq;

  switch (patientPaymentProgram.patientPaymentSchedule.paymentFreq.toLowerCase()) {
    case 'm' :
      termAndPaymentFreq = `Monthly `;
      break;
    case 'w' :
      termAndPaymentFreq = `Weekly `;
      break;
    default :
      termAndPaymentFreq = '';
  }

  return `${terms} ${termAndPaymentFreq} Payment(s) of:`;
};
