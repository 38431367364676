import React, {useCallback, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearStatus } from '../../../../admin-configuration/state/admin-configuration-slice';
import {
  showErrorStatus,
} from '../../../../security/state/user-slice';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import {
  apiStatus,
  EditEstLevelOfCare,
  Estimate,
  EstLevelOfCare,
} from '../../../models/estimator';
import { Utils } from "../../../../shared/utils";
import {
  configGetLOC
} from '../../../../admin-configuration/state/admin-configuration-thunk';
import _ from 'lodash';
import {
  callNewEstimatePost,
  getClientLevelsOfCare,
} from '../../../state/estimator-thunk';
import {
  clearClientLocStatus,
  clearConfigGetLOCStatus, setEstimator, setLevelsOfCare,
} from '../../../state/estimator-slice';
import {
  LOCModalContents,
} from '@finpay-development/shared-components';
import { FacilityLevelOfCare, Estimate as NewEstimate, QuoteMethod} from '@finpay/estimation-types';
import { NewLOCModal } from '@finpay-development/shared-components';
import useIsClientConfiguredForNewEstimate from 'src/admissions-advisor/utils/useClientConfigHook';

interface EstLevelOfCareModalProps {
  open: boolean;
  clientId: number | undefined;
  facilityId: number | undefined;
  facilityName: string | undefined;
  quoteMethod: string | undefined;
  handleEstimatorLevelOfCareModalClose: () => void;
}

const quoteMethodMap = (quoteMethod: string): QuoteMethod => {
  switch(quoteMethod) {
    case "sca" :
      return QuoteMethod.SCA
    case "avglos" :
      return QuoteMethod.AVGLOS
    case "manual" :
      return QuoteMethod.MANUAL
    case "rlos" :
      return QuoteMethod.RLOS
    default :
      return QuoteMethod.AVGLOS
  }
}

const compareFunction = (prevValue: any, nextValue: any) =>
    _.isEqual(prevValue, nextValue);

export function EstLevelOfCareModal({
                                          open,
                                          clientId,
                                          facilityId,
                                          facilityName,
                                          quoteMethod,
                                          handleEstimatorLevelOfCareModalClose,
                                        }: EstLevelOfCareModalProps) {

  const state = {
    estimatorState: useSelector(
        (state: RootState) =>
            state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    saveStatus: useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.modalSaveStatus
    ),
    errorMessage: useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.errorMessage
    ),
    vob: useSelector(
        (state: RootState) => state.admissionsAdvisorContext?.vobContext.vob
    ),
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
    patient: useSelector(
        (state: RootState) => state.patientContext.selectedPatient
    ),
    masterLevelsOfCareState: useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext?.levelsOfCare,
        compareFunction
    ),
    isLoadingMasterLoc: useSelector(
        (state: RootState) =>
            state.admissionsAdvisorContext.estimatorContext.isLoading
                .isLoadingMasterLoc
    ),
    getClientLOCStatus: useSelector(
        (state: RootState) =>
            state.admissionsAdvisorContext.estimatorContext.isLoading
                .getClientLOCStatus
    ),
    isLoadingClientLoc: useSelector(
        (state: RootState) =>
            state.admissionsAdvisorContext.estimatorContext.isLoading
                .isLoadingClientLoc
    ),
    configGetLOCStatus: useSelector(
        (state: RootState) =>
            state.admissionsAdvisorContext.estimatorContext.isLoading
                .configGetLOCStatus
    ),
    clientLevelsOfCareState: useSelector(
        (state: RootState) => {
          // TODO: use reselect memoization package to memoize this selector so that
          // this looping doesn't happen over and over again
          const masterLOCs = state.adminContext.adminConfigurationContext?.levelsOfCare;

          if(masterLOCs.length > 0) {
            return state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare.map((loc: any)=> {
             // double O(n) time complexity
             // consider sorting master list by id, then binary search
             // performance improvement: O(n) -> O(log n)
             const matchingLoc: any = masterLOCs.find((cfgLoc: any)=> {
               return cfgLoc.levelOfCareId === loc.cfgLevelOfCareId
             })

             return {
               ...loc,
               cfgLocType: matchingLoc?.locType || 0 
             }
           });
          } else {
            return state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare
          }
        },
        compareFunction
    ),
  };
  const { estimatorState, saveStatus, errorMessage, vob, vobPatientState, masterLevelsOfCareState, isLoadingMasterLoc, getClientLOCStatus, isLoadingClientLoc, configGetLOCStatus, clientLevelsOfCareState } = state;
  const paramId = -2;
  const dispatch = useDispatch<AppDispatch>();


  const isClientConfiguredForNewEstimates = useIsClientConfiguredForNewEstimate()

  const listOfLoc: FacilityLevelOfCare[] = useMemo(() => {
    return clientLevelsOfCareState.map((loc: EditEstLevelOfCare) => {
      return {
        facilityLevelOfCareId: loc.cfgLevelOfCareId,
        facilityLevelOfCareCode: loc.facilityLevelOfCareCode!,
        facilityLevelOfCareName: loc.facilityLevelOfCareName!,
        sortOrder: loc.sortOrder!,
        perDiemOperatingCost: loc.perDiemOperatingCost!,
        minRevenueAmount: loc.minRevenueAmount!,
        minRevenueFlatRate: loc.minRevenueFlatRate!,
        pdrRate: loc.facilityPayorLocRateLos?.pdrRate!,
        losDays: loc.facilityPayorLocRateLos?.losDays!, 
        entryType: 0,
        isCovered: loc.facilityPayorLocRateLos?.isCovered!,
        vobClassificationId: loc.vobClassificationId!,
        locType: loc.cfgLocType!,	 
      }
    }) 
  }, [clientLevelsOfCareState]) 

  function handleSave(selectedLocs: EditEstLevelOfCare[], selectedLocTypeFinal: string){
    return async function(){
      let estimatorStateCopy = Utils.deepClone(estimatorState);
      estimatorStateCopy.selectedLevelsOfCareFacilityType = selectedLocTypeFinal;
      estimatorStateCopy.selectedLevelsOfCare = selectedLocs.map((loc)=> {
        // double O(n) time complexity
        // consider sorting master list by id, then binary search
        // performance improvement: O(n) -> O(log n)
        const matchingLoc = masterLevelsOfCareState.find((cfgLoc)=> {
          return cfgLoc.levelOfCareId === loc.cfgLevelOfCareId
        })
        return {
          ...loc,
          cfgLocType: matchingLoc?.locType || 0 
        }
      });
      console.log('%cHandle Saved Estimate:', 'background-color: #272f77; color: white; font-weight: bold', estimatorStateCopy);
      dispatch(setEstimator(estimatorStateCopy));
      handleLocModalCallback(true);
    }
  }

  async function handleNewSave(selectedLocs: FacilityLevelOfCare[]){
    let estimatorStateCopy: Estimate = Utils.deepClone(estimatorState);
    console.log('%cHandle Saved Estimate:', 'background-color: #272f77; color: white; font-weight: bold', estimatorStateCopy);
    
    const postEstimateBody: NewEstimate = {
      vobId: vob.vobId!,
      clientId: estimatorStateCopy.client.clientId!,
      facilityId: estimatorStateCopy.facility.facilityId!,
      advisorPatientId: vobPatientState.patient.advisorPatientId!,
      description: estimatorStateCopy.description || "Blank Description",
      // quoteMethod: estimatorStateCopy.quoteMethod,
      anticipatedAdmitDate: new Date(estimatorStateCopy.admissionDate),
      facilityLevelOfCare: selectedLocs,
      priorCareLoC: [],
      isPlanYearCrossover: false,
      isActive: true
    }
    dispatch(callNewEstimatePost(postEstimateBody));
    handleLocModalCallback(true);
  }

  function handleLocModalCallback(saveSuccessful: boolean | undefined){
    if (saveSuccessful || typeof saveSuccessful === 'undefined') {
      handleEstimatorLevelOfCareModalClose();
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  const getLevelsOfCareByClient = useCallback(() => {
    if (masterLevelsOfCareState?.length > 0) {
      const config: any = {
        paramId: paramId,
        clientId: clientId,
        payerPlanId: vob?.plan?.payorPlanId ? vob?.plan?.payorPlanId : undefined,
        filterByPayerPlanId: !!vob?.plan?.payorPlanId,
        masterListLevelsOfCare: masterLevelsOfCareState,
      };
      dispatch(getClientLevelsOfCare(config));
    }
  }, [clientId, dispatch, masterLevelsOfCareState, paramId, vob?.plan?.payorPlanId]);

  useEffect(() => {

    dispatch(configGetLOC(paramId));
    if (estimatorState?.selectedLevelsOfCareFacilityType === 'multi') getLevelsOfCareByClient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState?.selectedLevelsOfCareFacilityType]);

  useEffect(() => {

    if (!isLoadingMasterLoc && configGetLOCStatus === apiStatus.success) {
      // config Levels of Care has loaded
      dispatch(clearConfigGetLOCStatus());
      getLevelsOfCareByClient();
    }
    if (!isLoadingClientLoc && getClientLOCStatus === apiStatus.success) {
      // client Levels of Care has loaded
      dispatch(clearClientLocStatus());
    }

  }, [isLoadingMasterLoc, isLoadingClientLoc, configGetLOCStatus, getClientLOCStatus, dispatch, getLevelsOfCareByClient, estimatorState?.selectedLevelsOfCareFacilityType, clientLevelsOfCareState, facilityId]);
  return (
      <>
        {(clientLevelsOfCareState.length) > 0 && (
          isClientConfiguredForNewEstimates ? (
            <NewLOCModal
              open={open}
              // selectedLocType={estimatorState.selectedLevelsOfCareFacilityType}
              quoteMethod={quoteMethod}
              saveStatus={saveStatus}
              handleSave={handleNewSave}
              handleLocModalCallback={handleLocModalCallback} 
              listOfLoc={listOfLoc}
            />
          ) : (
            <LOCModalContents
                open={open}
                loc={{
                  selectedLocType: estimatorState.selectedLevelsOfCareFacilityType,
                  preSelectedLocs: Array.isArray(estimatorState?.selectedLevelsOfCare) ? estimatorState?.selectedLevelsOfCare : [],
                  facilityLocList: Array.isArray(clientLevelsOfCareState) ? clientLevelsOfCareState : [],
                }}
                quoteMethod={quoteMethod}
                vob={{
                  inNetwork: vob.payer.inNetwork!,
                  selfPay: vob.selfPay!
                }}
                selectedFacility={{
                  facilityId: facilityId!,
                  facilityName: facilityName
                }}
                saveStatus={saveStatus}
                handleSave={handleSave}
                handleLocModalCallback={handleLocModalCallback}
            />)
          )}
      </>
  );
}
