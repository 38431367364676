import { Button, DialogActionButton, saveCallbackStatus, StartAndEndText } from '@finpay-development/shared-components';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Dialog, DialogActions, DialogContent, Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClient } from '../../../../../implementation-specialist/state/clients/implementation-clients-thunk';
import { formatNumberToUSD } from '../../../../../shared/calculators';
import HeaderWithDetails from '../../../../../shared/components/header-with-details';
import { createCommentSuccesMessages } from '../../../../../shared/enums';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { Utils } from '../../../../../shared/utils';
import {
  createPatientNote,
  createPFRHelloSignRequest,
  getTransactions,
  savePfrAdjustment,
  updatePFRDocument,
} from '../../../../state/patient-thunk';
import { getReasonById, TypeOfChange } from '../../../misc/adjustment-reasons';
import { BalanceAdjustment } from '../../../models/balance-adjustment';
import {
  FormattedDocument,
  PFRHelloSignRequest,
} from '../../../models/patient-document';
import { PatientNotes } from '../../../models/patient-note';
import { PFRAdjustment } from '../../../models/pfr-adjustment';
import moment from 'moment';


interface ModalProps {
  open: boolean,
  balanceAdjustmentModalValues: BalanceAdjustment,
  handleChangeBalanceAdjustment: () => void;
  handleConfirm: () => void;
}

const BalanceAdjustmentConfirmationModal = (props: ModalProps) => {
  const {
    open,
    balanceAdjustmentModalValues,
    handleChangeBalanceAdjustment,
    handleConfirm,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    patientEncounter: useSelector((state: RootState) => state.patientContext.selectedEncounter),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    clientBusinessRules: useSelector((state: RootState) => state.implementationContext.implementationSpecialistClient.client.clientBusRules),
  }

  const { patientEncounter, missingAddressError, clientBusinessRules } = stateFields;

  useEffect(() => {
    if (open) {
      dispatch(getClient(patientEncounter.clientId))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const pfrAmt = patientEncounter?.adjustedPfrAmt ? patientEncounter?.adjustedPfrAmt : balanceAdjustmentModalValues?.openingPFR;
  const sendPFRDocs = clientBusinessRules?.sendPFRDocs
  const isOverThreshold = !!(clientBusinessRules?.pfrVarianceTolerance && balanceAdjustmentModalValues.adjustmentAmount &&
    (balanceAdjustmentModalValues.adjustmentAmount > (pfrAmt * (clientBusinessRules.pfrVarianceTolerance / 100))));
  const isSendDocEnabled = (sendPFRDocs && isOverThreshold)
  const doDocsExist = patientEncounter?.authorizationDocumentStatus?.doPfrAdjustmentDocumentsExist;
  const haveDocsBeenSigned = doDocsExist
    ? (patientEncounter?.authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.documentStatus === "signed")
    : false;
  const wasPfrAdjustmentSent = doDocsExist
    ? (!!patientEncounter?.authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.wasPfrAdjustmentSent)
    : false
  const hasADocBeenSent = doDocsExist ? patientEncounter?.authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.documentStatus === 'sent' : false;
  const currentDocument =
    patientEncounter?.authorizationDocumentStatus?.pfrAdjustmentDocuments?.length > 0
      ? patientEncounter?.authorizationDocumentStatus?.pfrAdjustmentDocuments[0]
      : ({} as FormattedDocument);
  const isPatient = patientEncounter?.patientChampion?.filter(x => x.isGuarantor).length <= 0;
  const patientPaymentProgram = patientEncounter.patientPaymentProgram?.[patientEncounter.patientPaymentProgram.length - 1];
  const pendingBalance = patientPaymentProgram?.patientPaymentSchedule?.pfrPendingBalance || 0;
  const adjustmentAmount = Math.abs(+balanceAdjustmentModalValues.adjustmentAmount);
  const creditAmount = adjustmentAmount > pendingBalance ? adjustmentAmount - pendingBalance : 0;

  const calculateUpdatedPfr = () => {
    return pfrAmt + +balanceAdjustmentModalValues.adjustmentAmount
  }

  const reloadTransactions = () => {
    if (patientEncounter?.patientEncounterId !== 0 && !missingAddressError) {
      dispatch(getTransactions({
        patientId: patientEncounter?.patientId,
        encounterId: patientEncounter?.patientEncounterId,
        isAccountHolder: false,
      }));
    }
  }

  const shouldShowSendDocument = () => {
    if (isSendDocEnabled || hasADocBeenSent) {
      return wasPfrAdjustmentSent && haveDocsBeenSigned ? (!hasADocBeenSent) : (!hasADocBeenSent && !haveDocsBeenSigned)
    } else {
      return false
    }
  }

  const shouldShowConfirmButton = () => {
    if (isSendDocEnabled || hasADocBeenSent) { // we are above variance or a document has already been sent
      return (doDocsExist && haveDocsBeenSigned && !wasPfrAdjustmentSent && !hasADocBeenSent)
    } else {
      return true
    }
  }
  const shouldShowDisabledButton = !shouldShowConfirmButton() && !shouldShowSendDocument()

  const signersId = patientEncounter?.patientChampion?.filter(x => x.isGuarantor).length <= 0 ?
    patientEncounter?.patientId : patientEncounter?.patientChampion?.filter(x => x.isGuarantor)[0]?.patientChampionId || patientEncounter?.patientId;

  const createPFRReminder = () => {
    const currentDate = new Date();
    const dueDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
    const comment = {
      patientId: patientEncounter?.patientId,
      patientEncounterId: patientEncounter?.patientEncounterId,
      clientId: patientEncounter?.clientId,
      noteText: "PFR adjustment created",
      completionDueDt: dueDate.toISOString(),
      noteDt: currentDate.toISOString(),
      isReminder: true,
      patientNotesId: 0,
    } as PatientNotes;
    dispatch(createPatientNote({message: createCommentSuccesMessages.comment, comment: comment}))
  }

  const handleSubmit = async () => {
    const payload = {
      patientId: patientEncounter.patientId,
      patientEncounterId: patientEncounter.patientEncounterId,
      patientPFRId: patientEncounter.patientPfr?.patientPfrId,
      adjustmentDt: new Date().toISOString(),
      adjustmentAmt: balanceAdjustmentModalValues?.adjustmentAmount,
      adjustmentReasonId: balanceAdjustmentModalValues?.reasonForChangeId,
      adjustmentReason: getReasonById(balanceAdjustmentModalValues?.reasonForChangeId)
    } as PFRAdjustment;
    if (shouldShowConfirmButton()) {
      await dispatch(savePfrAdjustment(payload));
      if (doDocsExist && haveDocsBeenSigned && isSendDocEnabled ) {
        await dispatch(updatePFRDocument({ wasPfrAdjustmentSent: true, documentId: currentDocument?.authorizationDocumentStatusId }))
      }
      reloadTransactions();
      handleConfirm();
    }
    else if (shouldShowSendDocument()) {
      const helloSignPayload = {  
        clientId: patientEncounter.clientId,
        facilityId: patientEncounter.facilityId,
        encounterId: patientEncounter.patientEncounterId,
        signersId: signersId,
        isPatient: isPatient,
        pfrAmount: pfrAmt,
        adjustedPfr: balanceAdjustmentModalValues?.adjustmentAmount,
      } as PFRHelloSignRequest;
      createPFRReminder()
      await dispatch(savePfrAdjustment(payload));
      await dispatch(createPFRHelloSignRequest(helloSignPayload));
      handleConfirm();
    }
  }

  return (
    <Dialog
      className="modal delete-modal"
      scroll="body"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid xs={12} item className="mb-4 mt-2">
            <WarningRoundedIcon color="error" />
          </Grid>
          <Grid xs={12} item className="mb-2">
            <Typography variant="subtitle1">Patient Financial Responsibility Adjustment</Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="subtitle2">
              Please confirm the adjusted PFR has been correctly entered.  This process will update the patient’s financial responsbility.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <HeaderWithDetails header="Action" details={TypeOfChange[balanceAdjustmentModalValues?.changeType]} width="third" />
          <Grid item xs={12} className="pb-4" md={4}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Amount
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" className="positive-number">
                <span className={Utils.setPositiveNegativeNumberTextColor(true, +balanceAdjustmentModalValues.adjustmentAmount)}>
                  {formatNumberToUSD(+balanceAdjustmentModalValues.adjustmentAmount)}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <HeaderWithDetails header="Reason" details={getReasonById(balanceAdjustmentModalValues?.reasonForChangeId)} width="third" />
          <Grid item xs={12}>
            {calculateUpdatedPfr() < 0 && <Typography variant="body2">
              Note this action will result in a refund of {formatNumberToUSD(Math.abs(calculateUpdatedPfr()))}
            </Typography>}
          </Grid>
          <Grid item xs={12} className="mb-4">
            <Divider />
          </Grid>
        </Grid>
        {/* <StartAndEndText startText="Opening PFR" endText={balanceAdjustmentModalValues.openingPFR} />
        <StartAndEndText startText="Paid to date" endText={pfrAmt - balanceAdjustmentModalValues.pfrBalance} />
        <StartAndEndText startText="Current PFR" endText={balanceAdjustmentModalValues.pfrBalance} />
        <StartAndEndText startText="Adjustment" endText={+balanceAdjustmentModalValues.adjustmentAmount} hasPositiveNegativeEndTextColor />
        <StartAndEndText startText="Updated PFR" endText={calculateUpdatedPfr() > 0 ? calculateUpdatedPfr() : 0} /> */}

        <StartAndEndText startText="Adjusted PFR" endText={pfrAmt} />
        <StartAndEndText startText="Current adjustment" endText={+balanceAdjustmentModalValues.adjustmentAmount} hasPositiveNegativeEndTextColor />
        <StartAndEndText startText="Updated PFR" endText={calculateUpdatedPfr() > 0 ? calculateUpdatedPfr() : 0} />

        {creditAmount > 0 &&
          <StartAndEndText startText="Credit" endText={Math.abs(creditAmount)} hasPositiveNegativeEndTextColor />
        }
         {doDocsExist && hasADocBeenSent && !haveDocsBeenSigned &&(
          <Typography variant='body2' color='error'>
            PFR Adjustment Form must be signed prior to this adjustment being completed.
            PFR Adjustment Form sent: {moment(patientEncounter?.authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.lastUpdateDt).format('MM/DD/YYYY') }
          </Typography>
          )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button onClick={handleChangeBalanceAdjustment} type="danger">
              Change
            </Button>
          </Grid>
           {(shouldShowConfirmButton()) && (
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" className="mw-100">
                <DialogActionButton
                  isEnabled
                  savebuttonText='Confirm'
                  saveStatus={saveCallbackStatus.none}
                  executeSave={handleSubmit}
                  showCancelButton={false}
                />
              </Box>
            </Grid>
          )}
          {(shouldShowSendDocument()) && (
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" className="mw-100">
                <DialogActionButton
                  isEnabled
                  savebuttonText='Send Document'
                  spinnerLeftPosition={8}
                  saveStatus={saveCallbackStatus.none}
                  executeSave={handleSubmit}
                  showCancelButton={false}
                />
              </Box>
            </Grid>
          )}
          {(shouldShowDisabledButton) && (
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" className="mw-100">
                <DialogActionButton
                  isEnabled={false}
                  savebuttonText='Confirm'
                  spinnerLeftPosition={6}
                  saveStatus={saveCallbackStatus.none}
                  executeSave={() => {}}
                  showCancelButton={false}
                />
              </Box>
            </Grid>
          )}

        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default BalanceAdjustmentConfirmationModal;
