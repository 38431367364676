import { Status } from '@finpay-development/shared-components';
import { Divider, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import HeaderWithDetails from '../../../../shared/components/header-with-details';
import { RootState } from '../../../../shared/state/root-reducer';
import { Utils } from '../../../../shared/utils';

const DocumentsStatusAccordion = () => {
  const selectedEncounter = useSelector((state: RootState) => state.patientContext.selectedEncounter);
  const authorizationDocumentStatus = selectedEncounter?.authorizationDocumentStatus;
  const installmentDocuments = selectedEncounter?.patientDocument;

  const doPfrAdjustmentDocumentsExist = !!authorizationDocumentStatus?.doPfrAdjustmentDocumentsExist;
  const doPhiConsentDocumentsExist = !!authorizationDocumentStatus?.doPhiConsentDocumentsExist;
  const doFullPayDocumentsExist = !!authorizationDocumentStatus?.doFullPayDocumentsExist;
  const doInstallmentDocumentsExist = !!(installmentDocuments && installmentDocuments?.length > 0);
  // if none of the above document types exist then we don't have an overall document type status.
  const doesAuthorizationDocumentOverallStatusExist = doPfrAdjustmentDocumentsExist || doPhiConsentDocumentsExist || doFullPayDocumentsExist || doInstallmentDocumentsExist

  return (
    <div className="w-100">
      <Grid container justifyContent="space-between">
        <HeaderWithDetails
          header="Authorization Documents Status"
          width="full"
          details={
            <Status
              typographyVariant="h3"
              statusColor={Utils.getDocumentStatusColor(doesAuthorizationDocumentOverallStatusExist, doesAuthorizationDocumentOverallStatusExist ? authorizationDocumentStatus?.overallDocumentStatus : '')}
              text={doesAuthorizationDocumentOverallStatusExist ? authorizationDocumentStatus?.overallDocumentStatus : 'No documents exist'}
            />
          }
        />
        <Grid item xs={12} className="pb-2">
          <Divider />
        </Grid>
        <HeaderWithDetails
          header="PHI Consent Status"
          width="half"
          details={
            <Status
              typographyVariant="h3"
              statusColor={Utils.getDocumentStatusColor(doPhiConsentDocumentsExist, doPhiConsentDocumentsExist ? authorizationDocumentStatus?.phiConsentDocuments[0]?.displayedDocumentStatus : '')}
              text={doPhiConsentDocumentsExist ? authorizationDocumentStatus?.phiConsentDocuments[0]?.displayedDocumentStatus || '' : 'No documents exist'}
            />
          }
        />
        <HeaderWithDetails
          header="Full Payment Agreement Status"
          width="half"
          details={
            <Status
              typographyVariant="h3"
              statusColor={Utils.getDocumentStatusColor(doFullPayDocumentsExist, doFullPayDocumentsExist ? authorizationDocumentStatus?.spaDocuments[0]?.displayedDocumentStatus: '')}
              text={doFullPayDocumentsExist ? authorizationDocumentStatus?.spaDocuments[0]?.displayedDocumentStatus || '' : 'No documents exist'}
            />
          }
        />
        <HeaderWithDetails
          header="Installment Agreement Status"
          width="half"
          details={
            <Status
              typographyVariant="h3"
              statusColor={Utils.getDocumentStatusColor( doInstallmentDocumentsExist, doInstallmentDocumentsExist ? installmentDocuments[0]?.signatureTracking?.signatureStatus : '')}
              text={doInstallmentDocumentsExist ? installmentDocuments[0]?.documentStatus || '' : 'No documents exist'}
            />
          }
        />
        <HeaderWithDetails
          header="PFR Adjustment Agreement Status"
          width="half"
          details={
            <Status
              typographyVariant="h3"
              statusColor={Utils.getDocumentStatusColor( doPfrAdjustmentDocumentsExist, doPfrAdjustmentDocumentsExist ? authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.displayedDocumentStatus : '')}
              text={doPfrAdjustmentDocumentsExist ? authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.displayedDocumentStatus || '' : 'No documents exist'}
            />
          }
        />
      </Grid>
    </div>
  )

}

export default DocumentsStatusAccordion;
