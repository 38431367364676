import { Grid, Typography, Box } from "@mui/material";

import { formatNumberToUSD } from '../calculators';
import { PatientEncounter } from "../../patient/components/models/patient-encounter";

interface PfrDownPaymentAmtProps {
    selectedEncounter: PatientEncounter;
    labelPadding?: number;
    amountMargin: number;
    amountPadding: number;
}

export function PfrDownPaymentAmt(props: PfrDownPaymentAmtProps) {
    const {selectedEncounter, labelPadding, amountMargin, amountPadding} = props;

    const downPaymentsTotal = selectedEncounter?.patientTransactions?.reduce((total, transaction) => {
    if (selectedEncounter.workflow.workflowId === 2) {
        if (transaction.payment?.paymentType?.paymentTypeId === 1) {
            return total + transaction.payment.paymentAmt;
        }
        if (transaction?.paymentReversal?.reversalAmount) {
            return total + transaction.paymentReversal.reversalAmount;
        }
    }
    return total;
    }, 0);

    return (
        <>
            <Grid item xs={8} style={{ paddingTop: labelPadding ? `${labelPadding}px` : undefined }}>
                <Typography variant="subtitle2">
                    Down Payment Collected
                </Typography>
            </Grid>
            <Box justifyContent="flex-end" className="pr-1 mt-2" style={{ marginTop: `${amountMargin}px`, paddingTop: `${amountPadding}px` }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle2">
                        {formatNumberToUSD(downPaymentsTotal)}
                    </Typography>
                </Grid>
            </Box>
        </>
    )
}