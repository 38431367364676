import './../../../../scss/components/_tabs.scss';
import './../../../../scss/pages/implementation/_clients.scss';

import { Button, IconLabel, LoadingOverlay, Status } from '@finpay-development/shared-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Grid, MenuItem, Paper, Select } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AccessControl, {
  checkPermissions,
} from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import NotifyModal from '../../../../shared/components/notify-modal';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { statusNames } from '../../../misc/client-and-facility-status';
import { resetIsActivating } from '../../../state/clients/implementation-clients-slice';
import {
  activateClient,
  getClientComments,
} from '../../../state/clients/implementation-clients-thunk';
import { commentsPage, StatusColors } from '../../../../shared/enums';
import Comments from './comments';
import EntityDetails from './entity-details/entity-details-view';
import EntitySettings from './entity-settings/entity-settings-view';
import Facilities from './facilities/facilities-view';
import RulesEngine from './rules-engine/rules-engine-view';
import ClientAdmissionsAdvisor from './client-admissions-advisor/client-admissions-advisor-view';
import PESScriptView from './pes-script/pes-script-view';
import RiskSettings from './risk-settings/risk-settings-view';
import OperatingCosts from './operating-costs/operating-costs-view';
import MinRevRequirements from './min-revenue-requirements/min-revenue-requirements-view';
import ReferralSourceRevenue from './ref-source-revenue/ref-source-revenue-view';
import PayerPatientPaymentPerformance from './payer-patient-payment/payer-patient-payment.view';
import PaymentChannelFees from './payment-channel-fees/payment-channel-fees';
import AuthDocsView from './auth-docs/auth-docs-view';
import CRMView from './crm/crm-view';
import PreselectLevelsOfCareView from './preselect-loc/preselect-loc-view';
import {configSettings} from '../../../../shared/configuration/config-settings';
import {
  FacilityPayorFormObj, PayorPlanActions,
  PreselectPayorsPlan,
} from './preselect-payors-plan/preselect-payors-plan';
import {showErrorStatus} from '../../../../security/state/user-slice';

function ImplementationClientsDetailsView() {
  const [fpViewTabIndex, setFpViewTabIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [clientsView, setClientsView] = useState('FinPass');
  const [aaViewTabIndex, setAaViewTabIndex] = useState(0);

  const navigate = useNavigate();

  function handleFpViewChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setFpViewTabIndex(newTabIndex);
  }

  function handleAaViewChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setAaViewTabIndex(newTabIndex);
  }

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    fullClient: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
    ),
    saveStatus: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.apiStatus
    ),
    errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.errorMessage
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.isLoading
    ),
    isActivating: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.isActivating
    ),
    isTabComplete: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.isTabComplete
    ),
    recentViewedClients: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.recentViewedClients
    ),
    facilities: useSelector(
        (state: RootState) => state.implementationContext.implementationFacility.facilities
    ),
    userProfile: useSelector(
        (state: RootState) =>
            state.userContext.userProfile
    ),
    allClientsMap: useSelector((state: RootState) => state.implementationContext.implementationSpecialistClient.allClientsMap),
  };

  useEffect(() => {
    if (selectors.fullClient.clientId > 0) {
      dispatch(getClientComments(selectors.fullClient.clientId))
    }
  }, [selectors.recentViewedClients, dispatch, selectors.fullClient.clientId] )

  const workflowSubStatus = selectors.fullClient.workflow?.workflowSubStatus?.workflowSubStatusDesc || "";
  const areFacilitiesAllActive = selectors.facilities?.every((facility: any) => {
    return facility.every((fac: any) => fac.workflow?.workflowSubStatus?.workflowSubStatusDesc === statusNames.ACCOUNT_ACTIVE)
      }
  ) ?? true

  function showNotifyDialog() {
    setOpenModal(true);
  }

  function handleNotifyCancel() {
    setOpenModal(false);
  }

  async function sendEmailReminderNotification() {
    setOpenModal(false);
  }

  async function handleActivateClient() {
    const clientId = selectors.fullClient.clientId;
    if (clientId) {
      await dispatch(activateClient(clientId))
      dispatch(resetIsActivating());
      navigate("/clients");
    }
  }

  const isTabComplete = () => {
    return !!(selectors.isTabComplete.entitySettings &&
        selectors.isTabComplete.facilities &&
        selectors.isTabComplete.rulesEngine &&
        selectors.isTabComplete.admissionsAdvisor);
  }

  const [preselectPayorPlanForm, setPreselectPayorPlanForm] = useState<Record<number, FacilityPayorFormObj>[] | {}>({})

  const handlePreselectPayorsPlanFormOnChange = useCallback((formValues: Record<number, FacilityPayorFormObj>[]) => {
    setPreselectPayorPlanForm(formValues)
  }, [])

  const handleExitOnClick = () => {

    switch(aaViewTabIndex){

      case 6 :

        let showWarning = false;
        let unsavedWarningMessage: string = `There are unsaved changes. Please click save before exiting.`;

        const actionValues = Object.values(PayorPlanActions);

        Object.entries(preselectPayorPlanForm).forEach(([_, preselectPayorPlans])=>{
          if(Array.isArray(preselectPayorPlans)){

            preselectPayorPlans.forEach((payorAndPlans: FacilityPayorFormObj) => {

              const filteredFacilityPlans = Array.isArray(
                  payorAndPlans.facilityPayorPlans) ?
                  payorAndPlans.facilityPayorPlans.filter(
                      plan => plan.action && actionValues.includes(plan.action)) :
                  [];

              if ((payorAndPlans?.action &&
                      actionValues.includes(payorAndPlans.action)) ||
                  filteredFacilityPlans.length > 0) {

                showWarning = true;

              }
            })
          }
        })

        if(showWarning) {
          dispatch(showErrorStatus(unsavedWarningMessage));
        }else{
          navigate("/clients")
        }
        break;
      default:
        navigate("/clients")
    }

  }

  const clientsFooter = (
    <div>
      <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <Grid container justifyContent="center" alignItems="center" className="py-4">
          <Grid item>
            <Button
              onClick={handleExitOnClick}
              type="secondary"
              test-id="implementation-clients-exit-btn"
            >
              Exit
            </Button>
            {workflowSubStatus === statusNames.SETUP_INCOMPLETE &&
              <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
              renderNoAccess={() => <Button disabled>Notify Client</Button>}>
              <Button disabled={!isTabComplete()} marginLeft={6} onClick={() => showNotifyDialog()} >
              Notify Client
              </Button>
              </AccessControl>
            }
            {(workflowSubStatus !== statusNames.ACCOUNT_ACTIVE || !areFacilitiesAllActive) &&
                <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
                               renderNoAccess={() => <Button disabled>Activate Client</Button>}>
                  <Button loading={selectors.isActivating} spinnerLeftPosition={9.5} marginLeft={6} disabled={(workflowSubStatus === statusNames.ACCOUNT_ACTIVE && areFacilitiesAllActive) || selectors.isActivating} onClick={() => handleActivateClient()} >
                    Activate Client
                  </Button>
                </AccessControl>
            }
          </Grid>
        </Grid>
      </Paper>
      {openModal && <NotifyModal
        open={openModal}
        title="Notify Client"
        subTitle="This will send a notification to the client"
        okButtonText="Send now"
        sendEmailReminderNotification={sendEmailReminderNotification}
        handleNotifyCancel={handleNotifyCancel}
      />}
    </div>
  )

  return <div>
    <main className="implementation-clients">
    {!selectors.isLoading ? (
      <div className="content extra-padding">
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid xs={9}>
              <IconLabel
                icon={<HomeIcon />}
                label={selectors.fullClient.clientName}
                isBold={true}
              />
            </Grid>
            <Grid>
              <Status text="Active" statusColor={StatusColors.success} isPill />
            </Grid>
            <Grid>
              <AccessControl rolePageName={RolePageNames.Clients} actionName="Client Portal Admissions Advisor Settings"
                renderNoAccess={() => <></>}>
                <Box display="flex" justifyContent="flex-end">
                  <Select
                    className="MuiSelect-outlined"
                    style={{
                      border: "2px solid #b4becc",
                    }}
                    variant="outlined"
                    labelId="client-view-select-label"
                    id="client-view-select"
                    value={clientsView}
                    onChange={event => setClientsView(event.target.value as string)}
                  >
                    <MenuItem value={'FinPass'}>FinPass</MenuItem>
                    <MenuItem value={'FinAdvisor'}>FinAdvisor</MenuItem>
                  </Select>
                </Box>
              </AccessControl>
            </Grid>
          </Grid>


        {/* Default View */}
        {clientsView === "FinPass" &&

        <Grid container spacing={2} className="mt-1">
          <Grid item xs={12} className="tabs">
            <Tabs
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              value={fpViewTabIndex}
              onChange={handleFpViewChange}
            >
              <Tab disableRipple={true} icon={<CheckCircleOutlineIcon />} label="Entity Details" />
              <Tab disableRipple={true} label="Entity Settings" icon={selectors.isTabComplete.entitySettings ? <CheckCircleOutlineIcon /> : <></>} />
              <Tab disableRipple={true} label="Facilities" icon={selectors.isTabComplete.facilities ? <CheckCircleOutlineIcon /> : <></>}  />
              <Tab disableRipple={true} label="Fees" icon={<CheckCircleOutlineIcon />}  />
              <Tab disableRipple={true} label="Rules Engine" icon={selectors.isTabComplete.rulesEngine ? <CheckCircleOutlineIcon /> : <></>} />
              <Tab disableRipple={true} label="PES Script" icon={selectors.isTabComplete.pesScript ? <CheckCircleOutlineIcon /> : <></>} />
              <Tab disableRipple={true} label="Auth Docs" icon={selectors.isTabComplete.authDocs ? <CheckCircleOutlineIcon /> : <></>} />
              {checkPermissions(
              selectors.userProfile.userRole.userRolePages,
              RolePageNames.Clients, "CRM Configuration", false) && (
                  <Tab disableRipple={true} label="CRM" icon={selectors.isTabComplete.crm ? <CheckCircleOutlineIcon /> : <></>}/>)}
            </Tabs>
          </Grid>

          <Grid item xs={8}>
            <div>
              {fpViewTabIndex === 0 && <EntityDetails />}
              {fpViewTabIndex === 1 && <EntitySettings />}
              {fpViewTabIndex === 2 && <Facilities />}
              {fpViewTabIndex === 3 && <PaymentChannelFees />}
              {fpViewTabIndex === 4 && <RulesEngine />}
              {fpViewTabIndex === 5 && <PESScriptView />}
              {fpViewTabIndex === 6 && <AuthDocsView />}
              {fpViewTabIndex === 7 && <CRMView />}
            </div>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <div className="px-4 py-4">
                <Comments page={commentsPage.clients} />
              </div>
            </Paper>
          </Grid>
        </Grid>
        }

        {clientsView === "FinAdvisor" &&
            <Grid container spacing={2} className="mt-1">
              <Grid item xs={12} className="tabs">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  value={aaViewTabIndex}
                  onChange={handleAaViewChange}
                >
                  <Tab
                    disableRipple={true}
                    label="Facility/Rates/LOS"
                  />
                  <Tab
                    disableRipple={true}
                    label="Risk Settings"
                  />
                  <Tab
                    disableRipple={true}
                    label="Operating Costs"
                  />
                  <Tab
                    disableRipple={true}
                    label="Min. Revenue Req's"
                  />
                  <Tab
                    disableRipple={true}
                    label="Referral Source Revenue Data"
                  />
                  <Tab
                    disableRipple={true}
                    // label="Payer & Patient Payment Performance"
                    label="Payment Performance"
                  />
                  <Tab
                      disableRipple={true}
                      label="Preselect Payors/Plans"
                      style={selectors.userProfile?.userRole?.userRoleId !== 1  ? {display: "none"} : {}}
                  />
                  <Tab
                    disableRipple={true}
                    label="Preselect LOC"
                    style={configSettings.app_environment === 'prod' ? {display: "none"} : {}}
                  />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <div>
                  {aaViewTabIndex === 0 && <ClientAdmissionsAdvisor />}
                  {aaViewTabIndex === 1 && <RiskSettings />}
                  {aaViewTabIndex === 2 && <OperatingCosts />}
                  {aaViewTabIndex === 3 && <MinRevRequirements />}
                  {aaViewTabIndex === 4 && <ReferralSourceRevenue />}
                  {aaViewTabIndex === 5 && <PayerPatientPaymentPerformance />}
                  {aaViewTabIndex === 6 && <PreselectPayorsPlan handleFormOnChange={handlePreselectPayorsPlanFormOnChange}/>}
                  {aaViewTabIndex === 7 && <PreselectLevelsOfCareView />}
                </div>
              </Grid>
            </Grid>
        }

        </Grid>
      </div>) : <LoadingOverlay />}
    </main>
    {clientsFooter}
  </div>;
}

export default ImplementationClientsDetailsView;
