import { emptySignatureTracking, SignatureTracking } from "./signature-tracking";

export interface PatientDocument {
  documentLocation: string;
  documentLocationType: string;
  documentName: string;
  documentStatus: string;
  documentType: string;
  patientDocumentId: number;
  patientEncounterId: number;
  patientId: number;
  signatureTracking: SignatureTracking;
}

export const emptyPatientDocument: PatientDocument = {
  documentLocation: '',
  documentLocationType: '',
  documentName: '',
  documentStatus: '',
  documentType: '',
  patientDocumentId: 0,
  patientEncounterId: 0,
  patientId: 0,
  signatureTracking: emptySignatureTracking
}

export interface SPAHelloSignRequest {
  pfrAmount: number;
  paymentTotal: number;
  clientId: number;
  facilityId: number;
  encounterId: number;
  signersId: number;
  isPatient: boolean;
  isFullPay: boolean;

}

export interface PFRHelloSignRequest {
  clientId: number,
  facilityId: number,
  encounterId: number,
  signersId: number,
  isPatient: boolean,
  pfrAmount: number,
  adjustedPfr: number|'',
}

interface authorizationDocuments{
  typeOfDoc?: string
}

// the shape that comes back from the patient encounter api
export interface UnFormattedDocument {
  authorizationDocumentStatusId: number,
  documentStatus?: string,
  documentTypeId?: number,
  externalSignatureRequestId: string,
  lastUpdateDt?: string | null | undefined,
  patientChampionId?: number | null,
  patientId?: number,
  authorizationDocuments?: authorizationDocuments,
  wasPfrAdjustmentSent?: boolean,
}



// gets used after sorting and formatting documents into this shape
export interface FormattedDocument {
  displayedDocumentStatus?: string,
  authorizationDocumentStatusId: number,
  documentStatus?: string,
  documentTypeId?: number,
  externalSignatureRequestId: string,
  lastUpdateDt?: string | null | undefined,
  patientChampionId?: number | null,
  patientId?: number,
  wasPfrAdjustmentSent?: boolean,
}

export interface SortedDocuments {
  overallDocumentStatus: string,
  phiConsentDocuments: FormattedDocument[],
  spaDocuments: FormattedDocument[],
  pfrAdjustmentDocuments: FormattedDocument[],
  doPfrAdjustmentDocumentsExist?: boolean,
  doPhiConsentDocumentsExist?: boolean,
  doFullPayDocumentsExist?: boolean,
}

export const emptySortedDocuments = {
  overallDocumentStatus: '',
  phiConsentDocuments: [],
  spaDocuments: [],
  pfrAdjustmentDocuments: [],
  doPfrAdjustmentDocumentsExist: false,
  doFullPayDocumentsExist: false,
  doPhiConsentDocumentsExist: false
}
