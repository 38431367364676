import { EstimatorBody, PFRSummary, emptyPFRSummary } from "./estimator";
import { FinPayRiskClass } from "../../admin-configuration/models/risk-class-setting";

export const ThresholdEnum = {
  PFRCONCRISK: "PFRCONCRISK",
  PROFITABILITYRISK: "PROFITABILITYRISK",
  INPATREFSRCPROFITRISK: "INPATREFSRCPROFITRISK",
  OUTPATREFSRCPROFITRISK: "OUTPATREFSRCPROFITRISK",
  AMARISK: "AMARISK"
};

export enum RiskStatus {
	low,
	medium,
	high,
	none,
}

export enum AdmitStatus {
  fast = "Fast Track Admit",
  admit = "Admit",
  pending = "Pending Financial Clearance",
  noAdmit = "No Admit"
}

export interface RiskAssessmentForm {
  isValid: boolean;
  facility: {
    facilityId?: number;
    facilityName?: string;
  };
  facilityIds: number[];
  facilityCensus?: number;
  payorRisk: {
    payorRiskId?: number;
    payorRiskName?: string;
  };
  timingRisk: {
    timingRiskId?: number;
    timingRiskName?: string;
  };
  riskClass: {
    riskClassId?: number;
    riskClassName?: string;
  };
  referralSource: {
    facilityReferralSourceId?: number;
    facilityReferralSourceName?: string;
  };
  compareBest: boolean;
  showCleared: boolean;
}

export interface FacilityRiskThreshold {
	paramId?: number | undefined;
	facilityRiskThresholdId?: number | null;
	clientFacilityId?: number | null;
	cfgRiskThresholdId?: number | null;
  cfgRiskThresholdCode?: string | null | undefined;
	lowRisk?: number | null;
	mediumRisk?: number | null;
	highRisk?: number | null;
}

export interface MultiFacilityRiskThreshold {
	clientFacilityId: number;
	facilityRiskThresholds: FacilityRiskThreshold[];
}

export interface ClientRiskClassSettings {
  clientRiskClassSettingId?: number;
  clientId: number;
  riskClassId: number;
  riskClassCleared: number;
  riskClassHre: number;
};

export interface FacilityRiskClassSettings {
  facilityRiskClassSettingId?: number;
  clientFacilityId: number;
  riskClassId: number;
  riskClassCleared: number;
  riskClassHre: number;
};

export interface ClientDeficiencySettings {
  isEiv: boolean;
  addedToDownpayment: boolean;
  downpaymentPct: number;
}

export interface MultiFacilityRiskClassSetting {
	clientFacilityId: number;
	facilityRiskClassSettings: FacilityRiskClassSettings[];
}

export interface MultiFacilityRiskClass {
	clientFacilityId: number;
	facilityRiskClass: FinPayRiskClass[];
}

export interface PayerClaimRate {
	paramId?: number | undefined;
  clientPayorClaimrateId?: number | undefined | null,
  clientId: number,
  cfgPayorId?: number | undefined | null,
  cfgPayorPlanId?: number | undefined | null,
  claimRatePct?: number,
}

export interface RiskAssessmentEstimate {
	isValid: boolean;
	estimateBody: EstimatorBody;
}

export interface ValueRisk {
  value: number;
  risk: RiskStatus;
}

export interface PfrEiv {
  pfr: number;
  eiv: number;
  estimate?: EstimatorBody | null | undefined;
}

export interface EditedFacilityCensus {
  facilityId: number;
  census: number;
}

export interface FacilityRiskAssessment {
	isValid: boolean;
  isHreOnly: boolean;
  facilityId: number;
  facilityName: string;
  facilityLabel: JSX.Element | string;
  facilityCensus: number;
  isSelected: boolean;
	estimateBody: EstimatorBody;
  pfrSummary: PFRSummary;
  riskThresholds?: FacilityRiskThreshold[];
  daysToReimbursement: ValueRisk;
  pfrRiskSettings: ValueRisk;
  estAdjChgs: number;
  estInsVal: number;
  eivDeficiency: number;
  pfr: number;
  netPatientRevenueEst: number;
  netPatientRevenueDeficiency: number;
  patientEpisodeMargin: number;
  profitabilityRisk: ValueRisk;
  referralSourceRisk: ValueRisk;
  totalVariableCost: number;
  variableCostCoveragePercent: number;
  minimumDownPayment: number | null;
  riskAssessment: AdmitStatus | null; 
}

export const emptyFacilityRiskAssessment: FacilityRiskAssessment = {
  isValid: false,
  isHreOnly: false,
  facilityId: 0,
  facilityName: "",
  facilityLabel: "",
  facilityCensus: 0,
  isSelected: false,
  estimateBody: {
    client: {
			clientId: undefined,
			clientName: "",
		},
		facility: {
			facilityId: undefined,
			facilityName: "",
		},
		finPay: {
			patientId: undefined,
			patientEncounterId: undefined,
			sentDateTime: undefined,
		}
  },
  pfrSummary: emptyPFRSummary,
  riskThresholds: [],
  daysToReimbursement: {
    value: 0,
    risk: RiskStatus.none
  },
  pfrRiskSettings: {
    value: 0,
    risk: RiskStatus.none
  },
  estAdjChgs: 0,
  estInsVal: 0,
  eivDeficiency: 0,
  pfr: 0,
  netPatientRevenueEst: 0,
  netPatientRevenueDeficiency: 0,
  patientEpisodeMargin: 0,
  profitabilityRisk:  {
    value: 0,
    risk: RiskStatus.none
  },
  referralSourceRisk:  {
    value: 0,
    risk: RiskStatus.none
  },
  totalVariableCost: 0, 
  variableCostCoveragePercent: 0,
  minimumDownPayment: null,
  riskAssessment: null
};

export interface ReferralSourceRevenue {
  facilityLocReferralSourceId: number;
  clientFacilityId: number;
  facilityLevelOfCareId: number,
  clientReferralSourceId?: number | null | undefined;
  facilityReferralSourceId: number,
  locReferralRevenue?: number
}

export interface PatientEpisodeMarginSettings {
  clientPatientEpisodeMarginId?: number | null | undefined;
  clientId?: number | null | undefined;
  isEiv?: boolean | null | undefined,
  isFacilityCost?: boolean | null | undefined,
  variableCostPct?: number | null | undefined;
}

export const emptyPatientEpisodeMarginSettings: PatientEpisodeMarginSettings = {
  clientPatientEpisodeMarginId: null,
  clientId: null,
  isEiv: false,
  isFacilityCost: false,
  variableCostPct: null
}