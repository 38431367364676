import AccountHolderView from "./account-holder/components/account-holder-view";
import AccountHolderSettingsView from "./account-holder/components/settings/settings-view";
import AccountHolderActive from "./account-holder/components/account-holder-active/account-holder-active-view";
import AccountHolderCompleted from "./account-holder/components/account-holder-completed/account-holder-completed-view";
import AccountHolderCanceled from "./account-holder/components/account-holder-canceled/account-holder-canceled-view";
import ClientView from "./client/components/client-view";
import SpecialistDashboard from "./patient/components/patient-overview/specialist-dashboard";
import PatientDetailsView from "./patient/components/patient-drilldown/patient-details/patient-details-view";
import Launch from "./admissions-advisor/components/inbound/Launch";
import MasterPatientView from "./patient/components/master-ledger/master-ledger-view"

import AdminView from "./admin/components/admin-view";
import AdminUsersView from "./admin/components/admin-users/admin-users-view";
import AdminRolesView from "./admin/components/admin-roles/admin-roles-view";
import AdminLogsView from "./admin/components/admin-logs-view";
import HomeView from "./security/components/home-view";
import AccessDeniedView from "./security/components/access-denied";
import ImplementationClientsGridView from "./implementation-specialist/components/implementation-clients/implementation-clients-grid-view";
import ImplementationClientsDetailsView from "./implementation-specialist/components/implementation-clients/details/implementation-clients-view";
import AdminConfigurationView from "./admin-configuration/components/admin-configuration-view";
import AdmissionsAdvisorView from "./admissions-advisor/components/admissions-advisor-view";
import ClientDashboard from "./client/components/client-dashboard";
import ClientFacilities from "./client/components/client-facilities";
import PrivacyPolicyView from "./admin/components/privacy-policy/privacy-policy-view";
import AdminReportsView from "./admin/components/admin-reports-parent";
import ClientReportsView from "./client/components/client-reports-view";
import { ForwardDocumentsView } from "./patient/components/patient-drilldown/documents/forward-documents-view";
import ClientDocumentSign from "./patient/components/patient-drilldown/documents/client-document-sign";
import AccountHolderEmailVerification
  from './account-holder/components/account-holder-email-verification/account-holder-email-verification';

export interface RouteConfigProps {
  path: string;
  protectedPageName?: string;
  component: any;
}

const RouteConfiguration: Array<RouteConfigProps> = [
  { path: "/", component: HomeView },

  // Admin
  { path: "/admin", component: AdminView },
  {
    path: "/admin/users",
    protectedPageName: "Users",
    component: AdminUsersView,
  },
  {
    path: "/admin/roles",
    protectedPageName: "Roles",
    component: AdminRolesView,
  },
  {
    path: "/admin/configuration",
    protectedPageName: "Configuration",
    component: AdminConfigurationView,
  },
  { path: "/admin/logs", protectedPageName: "Logs", component: AdminLogsView },
  {
    path: "/operations/reports",
    protectedPageName: "Reports",
    component: AdminReportsView,
  },
  {
    path: "/admissions-advisor",
    protectedPageName: "Admissions Advisor",
    component: AdmissionsAdvisorView,
  },
  {
    path: "/admissions-advisor/inbound",
    component: Launch,
  },
  // Implementation
  {
    path: "/clients",
    protectedPageName: "Clients",
    component: ImplementationClientsGridView,
  },
  {
    path: "/clients/:id",
    protectedPageName: "Clients",
    component: ImplementationClientsDetailsView,
  },

  // Patient
  {
    path: "/specialist/dashboard",
    protectedPageName: "Patient Records",
    component: SpecialistDashboard,
  },
  {
    path: "/specialist/dashboard/:id",
    protectedPageName: "Patient Records",
    component: PatientDetailsView,
  },
  {
    path: "/specialist/dashboard/client/:cid/patient/:id/ledger",
    protectedPageName: "Patient Records",
    component: MasterPatientView,
  },

  // Account Holder
  {
    path: "/patient",
    protectedPageName: "Patient Dashboard",
    component: AccountHolderView,
  },
  {
    path: "/patient/settings",
    protectedPageName: "Patient Dashboard",
    component: AccountHolderSettingsView,
  },
  {
    path: "/patient/dashboard",
    protectedPageName: "Patient Dashboard",
    component: AccountHolderView,
  },
  {
    path: "/patient/dashboard/active",
    protectedPageName: "Patient Dashboard",
    component: AccountHolderActive,
  },
  {
    path: "/patient/dashboard/completed",
    protectedPageName: "Patient Dashboard",
    component: AccountHolderCompleted,
  },
  {
    path: "/patient/dashboard/canceled",
    protectedPageName: "Patient Dashboard",
    component: AccountHolderCanceled,
  },
  {
    path: "/patient/encounter/:encounterId/email-verification",
    protectedPageName: "Patient Dashboard",
    component: AccountHolderEmailVerification
  },

  // Client Owner
  {
    path: "/client",
    protectedPageName: "Client Portal",
    component: ClientView,
  },
  {
    path: "/client/dashboard",
    protectedPageName: "Client Portal",
    component: ClientDashboard,
  },
  {
    path: "/client/facilities",
    protectedPageName: "Client Portal",
    component: ClientFacilities,
  },
  {
    path: "/client/reports",
    protectedPageName: "Client Portal",
    component: ClientReportsView,
  },

  //access-denied
  { path: "/accessdenied", component: AccessDeniedView },

  // Misc
  { path: "/privacypolicy", component: PrivacyPolicyView },
  {
    path: "/sign-document/:helloSignRequestId",
    protectedPageName: "Forward Document",
    component: ForwardDocumentsView,
  },
  {
    path: "/sign-doc/patient/:patientId/encounter/:encounterId",
    component: ClientDocumentSign,
  },
];

export default RouteConfiguration;
