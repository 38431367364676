import "../../../../scss/components/_shared.scss";
import "../../../../scss/components/_tabs.scss";
import "../../../../scss/pages/implementation/_clients.scss";
import "../../../../scss/pages/patients/_patient-details.scss";

import {
  Button,
  IconLabel,
  LoadingOverlay,
} from "@finpay-development/shared-components";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import {Divider, Grid, Paper, Typography} from '@mui/material';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";

import AccessControl from "../../../../security/components/access-control";
import { RolePageNames } from "../../../../security/model/role-page-names";
import { configSettings } from "../../../../shared/configuration/config-settings";
import { RootState } from "../../../../shared/state/root-reducer";
import { AppDispatch } from "../../../../shared/state/store";
import {
  disabledConvertMessages,
} from "../../../../shared/enums";
import {
  phoneNumberValidator,
  emailAddressValidator,
} from "../../../../shared/misc/regex";
import {resetIsConverting, setRedirectToId} from '../../../state/patient-slice';
import {
  convertPatientEncounter,
  getPatientDemographics,
  savePatientStatus,
} from "../../../state/patient-thunk";
import { emptyPatientChampion } from "../../models/patient-champion";
import { emptyPatientEncounter } from "../../models/patient-encounter";
import { emptyPatientInsurance } from "../../models/patient-insurance";
import { emptyPatientViewModel } from "../../models/patient-view-model";
import { PAYMENT_TYPES } from "../../models/payment-type";
import { emptyStripePaymentMethod } from "../../models/stripe-payment-method";
import { emptyTransaction, Transaction } from "../../models/transaction";
import { ChangeStatusModal } from "../../patient-overview/change-status-modal";
import { PatientModal } from "../../patient-overview/patient-modal";
import InstanceOfCare from "../instance-of-care/instance-of-care";
import Demographics from "./demographics";
import ScriptButton from "./script-button";
import { ConvertErrorHandlerModal } from "./convert-error-modal";
import { patientService } from "../../../services/patient-service";
import {
  showErrorStatus,
  showStatus,
} from "../../../../security/state/user-slice";
import { SendToMobileQueueErrorModal } from "./send-to-mobile-queue-error-modal";
import { ClientCrm, ClientStatusCardViewModel } from "../../../../shared/model/client-status-card";
import { TakePaymentModal } from "../../../../shared/components/take-payment-modal";
import { clearPaymentURLParams } from "../../../../admissions-advisor/state/vob-slice";
import {Workflow} from '../../../../shared/model/client';
import { paynowService as paymentService } from "src/guest/services/paynow-service";

interface LocationState extends Location {
  state: {
    tabIndex: number;
  };
}

function PatientDetailsView() {
  const [tabIndex, setTabIndex] = useState(0);
  const [isCovertDisabled, setIsCovertDisabled] = useState(false);
  const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
  const [isChangeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [warmTransferCompletedValue, setWarmTransferCompletedValue] =
    useState("");
  const [isMissingReqModalOpen, setIsMissingReqModalOpen] = useState(false);
  const [convertRequirementsList, setConvertRequirementsList] = useState<any>(
    []
  );
  const [
    isSendToMobileQueueErrorModalOpen,
    setIsSendToMobileQueueErrorModalOpen,
  ] = useState(false);
  const [
    sendToMobileQueueRequirementsList,
    setSendToMobileQueueRequirementsList,
  ] = useState([]);
  const [paymentData, setPaymentData] = useState<any>(undefined);

  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation() as LocationState;
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  useEffect(() => {
    //Need to set the tab to Instance of Care if navigating over from Admissions Advisor
    if (state?.tabIndex) {
      setTabIndex(state.tabIndex);
    }

    return () => {
      window.history.replaceState({}, " ");
      setTabIndex(0);
    };
  }, [state?.tabIndex]);

  const triggerPatientModal = (isOpen: boolean) => {
    setIsPatientModalOpen(isOpen);
  };
  function handleChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setTabIndex(newTabIndex);
  }
  const handleChangeStatusModalCancel = () => {
    setChangeStatusModalOpen(false);
  };

  const handleChangeStatusModalSubmit = async (value: {
    workFlow: Workflow;
    encounterId: number;
    patientId: number;
  }) => {
    await dispatch(savePatientStatus(value));
    await setChangeStatusModalOpen(false);
    navigate(`/specialist/dashboard/${selectedEncounter.patientId}`);
  };

  const isDemographicsComplete = false;
  const isIOCComplete = false;

  const selectors = {
    recentlyViewedPreConvertedPatients: useSelector(
      (state: RootState) =>
        state.patientContext.recentlyViewedPreConvertedPatients
    ),
    accountHolderContext: useSelector(
      (state: RootState) => state.patientContext.accountHolderContext
    ),
    recentlyViewedConvertedPatients: useSelector(
      (state: RootState) => state.patientContext.recentlyViewedConvertedPatients
    ),
    selectedEncounter: useSelector(
      (state: RootState) => state.patientContext.selectedEncounter
    ),
    patient: useSelector(
      (state: RootState) => state.patientContext.selectedPatient
    ),
    isLoading: useSelector(
      (state: RootState) => state.patientContext.isLoading.patientDemographics
    ),
    isLoadingIOC: useSelector(
      (state: RootState) => state.patientContext.isLoading.patientIOC
    ),
    isConverting: useSelector(
      (state: RootState) => state.patientContext.isConverting
    ),
    downPayments: useSelector((state: RootState) => {
      return (
        state.patientContext.selectedEncounter?.patientTransactions?.length >
          0 &&
        state.patientContext.selectedEncounter.patientTransactions.filter(
          (transaction: Transaction) =>
            transaction?.payment?.paymentType?.paymentTypeId ===
              PAYMENT_TYPES.DOWN_PAYMENT.paymentTypeId ||
            transaction?.payment?.paymentType?.paymentTypeId ===
              PAYMENT_TYPES.AT_FACILITY.paymentTypeId
        )
      );
    }),
    shouldReFetchTransactions: useSelector((state: RootState) => {
      return (
        state.patientContext?.isError?.patientTransactions ||
        state.patientContext?.selectedEncounter?.patientTransactions ===
          undefined ||
        // @ts-ignore
        (state.patientContext?.selectedEncounter?.patientTransactions === [] &&
          // @ts-ignore
          state.patientContext?.selectedEncounter?.patientTransactions ===
            [emptyTransaction])
      );
    }),
    shouldReFetchPaymentMethods: useSelector((state: RootState) => {
      return (
        state.patientContext?.isError?.paymentMethods ||
        state.patientContext?.selectedEncounter?.patientPaymentMethods ===
          undefined ||
        // @ts-ignore
        (state.patientContext?.selectedEncounter?.patientPaymentMethods ===
          [] &&
          // @ts-ignore
          state.patientContext?.selectedEncounter?.patientPaymentMethods ===
            [emptyStripePaymentMethod])
      );
    }),
    shouldReFetchDemographics: useSelector((state: RootState) => {
      return (
        !state.patientContext?.selectedPatient ||
        state.patientContext?.selectedPatient === emptyPatientViewModel
      );
    }),
    recurringPayment: useSelector((state: RootState) => {
      if (
        state.patientContext.selectedEncounter?.patientPaymentMethods?.length >
        0
      ) {
        const recurringPayment =
          state.patientContext.selectedEncounter.patientPaymentMethods.filter(
            (method) => {
              return (
                method.metadata?.metaData_paymentMethodType?.includes(
                  "RECURRING"
                ) || method?.metadata?.isRecurring
              );
            }
          );
        return recurringPayment ? recurringPayment[0] : false;
      } else {
        return false;
      }
    }),

    patientEncounters: useSelector(
      (state: RootState) =>
        state.patientContext.selectedPatient?.patientEncounters
    ),
    isDownPaymentFullyPaid: useSelector(
      (state: RootState) => state.patientContext?.isDownPaymentFullyPaid
    ),
    isLoadingTransactions: useSelector(
      (state: RootState) => state.patientContext?.isLoading?.patientTransactions
    ),
    isLoadingPaymentMethods: useSelector(
      (state: RootState) => state.patientContext?.isLoading?.paymentMethods
    ),
    allClientsMap: useSelector((state: RootState)=>state.implementationContext?.implementationSpecialistClient.allClientsWithFacilitiesMap),
    vob: useSelector(
        (state: RootState) =>
            state.admissionsAdvisorContext?.vobContext.vob
    ),
    vobPatientState: useSelector(
      (state: RootState) =>
          state.admissionsAdvisorContext?.vobPatientContext.patient
    ),
    estimatorState: useSelector(
      (state: RootState) =>
          state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };
  const {
    patient,
    selectedEncounter,
    isLoading,
    isLoadingIOC,
    downPayments,
    recurringPayment,
    patientEncounters,
    isDownPaymentFullyPaid,
    isLoadingTransactions,
    isLoadingPaymentMethods,
    shouldReFetchDemographics,
    shouldReFetchTransactions,
    shouldReFetchPaymentMethods,
    isConverting,
    allClientsMap,
    vob,
    vobPatientState,
    estimatorState
  } = selectors;

  const patientId = parseInt(params.id || "") || 0;

  useEffect(() => {
    if (shouldReFetchDemographics) {
      dispatch(getPatientDemographics(patientId));
    }
  }, [dispatch, patientId, shouldReFetchDemographics]);

  useEffect(() => {
    if (vob?.urlParams?.op === 'pay') {
      setPaymentData({
        patientId: vobPatientState?.fpPatientId,
        patientEncounterId: estimatorState?.finPay?.patientEncounterId,
        paymentType: PAYMENT_TYPES.DOWN_PAYMENT,
        paymentChannelId: 3
      });
      setOpenPaymentModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  function handlePaymentModalCancel() {
    setOpenPaymentModal(false);
    dispatch(clearPaymentURLParams());
  }

  function handleModalSubmit() {
    setIsPatientModalOpen(false);
  }

  function handleModalCancel() {
    setIsPatientModalOpen(false);
  }

  function handleConvertModalCancel() {
    setIsMissingReqModalOpen(false);
  }

  function handleSendToMobileQueueModalCancel() {
    setIsSendToMobileQueueErrorModalOpen(false);
  }

  const doesSelectedEncounterExist =
    selectedEncounter !== emptyPatientEncounter;

  const doesInsuranceExist =
      doesSelectedEncounterExist &&
      selectedEncounter?.patientInsurance?.length > 0 &&
      selectedEncounter?.patientInsurance[0] !== emptyPatientInsurance;

  const doesChampionExist =
      (doesSelectedEncounterExist &&
          selectedEncounter?.patientChampion?.length > 0 &&
          selectedEncounter?.patientChampion[0] !== emptyPatientChampion) ||
      selectedEncounter?.hasChampions === false; // if we have champions, or hasChampions is explicitly false

  const doesPaymentProgramExist =
      doesSelectedEncounterExist &&
      selectedEncounter?.patientPaymentProgram?.length > 0 &&
      selectedEncounter?.patientPaymentProgram[
          selectedEncounter.patientPaymentProgram.length - 1
      ]?.downPmtAmt >= 0;

  const doesPaymentProgramPFRMatchEncounterPFR =
      doesPaymentProgramExist &&
      selectedEncounter.patientPaymentProgram[selectedEncounter.patientPaymentProgram.length - 1]
      .patientPaymentSchedule.pfrAmt === selectedEncounter.pfrAmt

  const isFullPayPlan =
      doesPaymentProgramExist &&
      selectedEncounter?.patientPaymentProgram[
          selectedEncounter.patientPaymentProgram.length - 1
      ]?.patientPaymentSchedule?.paymentFreq === 'F';

  const doesDownPaymentExist =
      doesSelectedEncounterExist &&
      doesPaymentProgramExist &&
      downPayments &&
      downPayments?.length > 0;

  const doesRecurringPaymentExist =
      !!(
          doesSelectedEncounterExist &&
          doesPaymentProgramExist &&
          recurringPayment
      ) ||
      (doesSelectedEncounterExist && doesPaymentProgramExist && isFullPayPlan);

  const doesRiskClassExist = selectedEncounter?.riskClass?.riskClassId;

  const doesMatchIOCConditions =
      doesSelectedEncounterExist &&
      selectedEncounter?.typeOfCare?.typeOfCareId > 0 &&
      selectedEncounter?.typeOfCare?.typeOfCare !== null &&
      selectedEncounter?.patientId > 0 &&
      selectedEncounter?.facilityId !== -1 &&
      selectedEncounter?.facilityId !== null &&
      selectedEncounter?.clientId !== -1 &&
      selectedEncounter?.clientId !== null &&
      selectedEncounter?.payorRisk?.payorRiskId > 0 &&
      selectedEncounter?.timingRisk?.timingRiskId > 0 &&
      selectedEncounter?.pfrAmt > 0 &&
      selectedEncounter?.workflow?.workflowId > 0;

  const doesMatchDemoConditions =
      patient?.contact?.firstName !== '' &&
      patient?.contact?.firstName !== null &&
      patient?.contact?.lastName !== '' &&
      patient?.contact?.lastName !== null &&
      patient?.contact?.email !== '' &&
      patient?.contact?.email !== null &&
      emailAddressValidator.test(patient?.contact?.email) &&
      patient?.contact?.primPhoneNum !== '' &&
      patient?.contact?.primPhoneNum !== null &&
      phoneNumberValidator.test(patient?.contact?.primPhoneNum);

  const handleDisableConvert = (value: string) => {
      setWarmTransferCompletedValue(value);
  };

  useEffect(() => {
      const disabledRequirementList = [
          {
              requirementName: disabledConvertMessages.iocLoadingLabel,
              requirementNameInfo: disabledConvertMessages.iocLoadingTooltip,
              status: !isLoading || !isLoadingIOC,
          },
          {
              requirementName: disabledConvertMessages.championLabel,
              status: doesChampionExist,
          },
          {
              requirementName: disabledConvertMessages.paymentProgramLabel,
              status: doesPaymentProgramExist,
          },
          {
              requirementName: disabledConvertMessages.downPaymentLabel,
              status: doesDownPaymentExist && isDownPaymentFullyPaid,
          },
          {
              requirementName: disabledConvertMessages.recurringPaymentLabel,
              status: doesRecurringPaymentExist,
          },
          {
              requirementName: disabledConvertMessages.iocCompletedLabel,
              status: doesMatchIOCConditions,
          },
          {
              requirementName: disabledConvertMessages.demographicsLabel,
              status: doesMatchDemoConditions,
          },
          {
              requirementName: disabledConvertMessages.riskClassLabel,
              status: doesRiskClassExist,
          },
          {
              requirementName: disabledConvertMessages.warmTransferLabel,
              requirementNameInfo: disabledConvertMessages.warmTransferTooltip,
              status: warmTransferCompletedValue !== '-1',
          },
          {
            requirementName: disabledConvertMessages.pfrMissmatchLable, 
            status:  doesPaymentProgramPFRMatchEncounterPFR
          }
      ];
      setConvertRequirementsList(disabledRequirementList);

      const disableConvertConditions = () => {
          return (
              !(
                  (!isLoading || !isLoadingIOC) &&
                  doesChampionExist &&
                  isDownPaymentFullyPaid &&
                  doesPaymentProgramExist &&
                  doesDownPaymentExist &&
                  doesRecurringPaymentExist &&
                  doesMatchIOCConditions &&
                  doesMatchDemoConditions &&
                  tabIndex === 1
              ) ||
              isConverting ||
              !doesRiskClassExist ||
              warmTransferCompletedValue === '-1'
          );
      };
      setIsCovertDisabled(disableConvertConditions());

      // eslint-disable-next-line
  }, [
      isLoading,
      isLoadingIOC,
      tabIndex,
      isConverting,
      doesRiskClassExist,
      doesChampionExist,
      isDownPaymentFullyPaid,
      doesPaymentProgramExist,
      doesDownPaymentExist,
      doesRecurringPaymentExist,
      doesMatchIOCConditions,
      doesMatchDemoConditions,
      warmTransferCompletedValue,
  ]);

  const patientInfoHeader = (
      <>
          <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
          >
              <Grid item className="pr-4">
                  <IconLabel
                      icon={<PersonIcon />}
                      label={`${patient?.contact?.firstName} ${patient?.contact?.lastName}`}
                      isBold={true}
                  />
              </Grid>
              <Grid item className="pr-4">
                  <IconLabel
                      icon={<MailIcon />}
                      label={`${patient?.contact?.email}`}
                      isBold={true}
                  />
              </Grid>
              <Grid item className="pr-4">
                  <IconLabel
                      icon={<PhoneIcon />}
                      label={`${patient?.contact?.primPhoneNum}`}
                      isBold={true}
                  />
              </Grid>
          </Grid>
          <Divider />
      </>
  );

  const tabs = (
      <Grid item xs={12} className="tabs">
          <Tabs
              TabIndicatorProps={{
                  style: {
                      display: 'none',
                  },
              }}
              value={tabIndex}
              onChange={handleChange}
          >
              <Tab
                  className="py-3"
                  disableRipple
                  icon={
                      isDemographicsComplete ? <CheckCircleOutlineIcon /> : ''
                  }
                  label="Demographics"
              />
              <Tab
                  disableRipple
                  icon={isIOCComplete ? <CheckCircleOutlineIcon /> : ''}
                  label="Instance Of Care"
                  test-id="instance-of-care-tab"
              />
          </Tabs>
      </Grid>
  );

  async function handleConvert() {
      if (isCovertDisabled) {
          return setIsMissingReqModalOpen(true);
      }
      const clientItem: ClientStatusCardViewModel = allClientsMap!.get(selectedEncounter?.clientId)!;
      const clientCrm: ClientCrm[] = clientItem?.clientCrm!;
      const response: any = await dispatch(
          convertPatientEncounter({
              patientId: selectedEncounter.patientId,
              encounterId: selectedEncounter.patientEncounterId,
              crmUrl: clientCrm.length > 0 ? clientCrm[0].crmType.crmTypeSlug : undefined
          })
      );
      dispatch(resetIsConverting());
      if (!(response?.meta?.requestStatus === 'rejected')) {
          // put payment program to recalculate the balances in programs table
          const currentPaymentProgramId = selectedEncounter.patientPaymentProgram[0]?.patientPaymentProgramId;
          await paymentService.updatePaymentProgram(currentPaymentProgramId);
          // convert error state doesn't work here because this is async. So, we have to use this work-around.
          navigate(`/specialist/dashboard/${selectedEncounter.patientId}`);
      }
  }

  const handleStatusChange = () => {
      if (selectors.patient.patientEncounters) {
          setChangeStatusModalOpen(true);
      }
  };

  const handleSendToMobileQueue = async () => {
      const response = await patientService.sendInstanceOfCareToMobileQueue(
        selectedEncounter.patientEncounterId
      );

      if (response.hasErrors) {
        dispatch(showErrorStatus(response.errorMessage));
      } else if (response.entity.length > 0) {
        //modal with failed requirements
        setSendToMobileQueueRequirementsList(response.entity);
        setIsSendToMobileQueueErrorModalOpen(true);
      } else if (response.entity.length === 0) {
        //success message
        setSendToMobileQueueRequirementsList([]);
        dispatch(showStatus("IOC Sent to Mobile Queue"));
        await dispatch(setRedirectToId(0));
        navigate(`/specialist/dashboard`);
      } else {
        dispatch(
          showErrorStatus(
            "Something went wrong while sending IOC to mobile queue"
          )
        );
    }
  }

  const mobilePaymentWorkflowSubStatusIds = [60, 61, 62, 63, 64, 65];
  const displaySendToMobileButton =
      !selectedEncounter?.isConverted &&
      !mobilePaymentWorkflowSubStatusIds.includes(
          +selectedEncounter?.workflow?.workflowSubStatus?.workflowSubStatusId
      );

  const footerButtons = (
      <Paper
          sx={{position: 'fixed', bottom: 0, left: 0, right: 0}}
          elevation={3}
      >
          <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="py-4"
          >
              <Grid item>
                  {displaySendToMobileButton && (
                      <AccessControl
                          rolePageName={RolePageNames.PatientRecords}
                          actionName="Add Instance of Care"
                          renderNoAccess={() => (
                              <Button type="secondary" marginRight={6} disabled>
                                  Send to Mobile Queue
                              </Button>
                          )}
                      >
                          <Button
                              type="secondary"
                              marginRight={6}
                              disabled={
                                  isLoading || isLoadingIOC || tabIndex === 0
                              }
                              onClick={() => handleSendToMobileQueue()}
                          >
                              Send to Mobile Queue
                          </Button>
                      </AccessControl>
                  )}
                  <AccessControl
                      rolePageName={RolePageNames.PatientRecords}
                      actionName="Change Status"
                      renderNoAccess={() => (
                          <Button disabled marginRight={6}>
                              Change Status
                          </Button>
                      )}
                  >
                      <Button
                          onClick={() => handleStatusChange()}
                          type="secondary"
                          disabled={isLoading || isLoadingIOC || tabIndex === 0}
                          marginRight={6}
                      >
                          Change Status
                      </Button>
                  </AccessControl>
                  <ScriptButton
                      selectedEncounter={selectedEncounter}
                      disable={isLoading || isLoadingIOC || tabIndex === 0}
                      environment={configSettings.app_environment}
                  />
                  {!selectedEncounter?.isConverted && (
                      <AccessControl
                          rolePageName={RolePageNames.PatientRecords}
                          actionName="Convert Account"
                          renderNoAccess={() => (
                              <Button disabled>Convert</Button>
                          )}
                      >
                          <Button
                              loading={isConverting}
                              disabled={false}
                              // disabled={isCovertDisabled}
                              onClick={() => handleConvert()}
                              spinnerLeftPosition={5}
                          >
                              Convert
                          </Button>
                      </AccessControl>
                  )}
              </Grid>
          </Grid>
          {isChangeStatusModalOpen && (
              <ChangeStatusModal
                  open={isChangeStatusModalOpen}
                  handleModalCancel={handleChangeStatusModalCancel}
                  handleModalSubmit={handleChangeStatusModalSubmit}
              />
          )}
      </Paper>
  );

  return (
      <div>
          <main className="patient-details">
              <div className="content extra-padding">
                  {!isLoading ? (
                      <>
                          <Grid container spacing={2}>
                              {patientInfoHeader}
                              {tabs}
                              {tabIndex === 0 && (
                                  <>
                                      <Grid item xs={12}>
                                          <div
                                              style={{
                                                  display: patient?.patientId
                                                      ? 'none'
                                                      : '',
                                              }}
                                          >
                                              <Typography
                                                  component="h1"
                                                  variant="h2"
                                                  align="center"
                                                  color="text.primary"
                                                  gutterBottom
                                              >
                                                  Sorry, you don't have access
                                                  to the 'Demographics' tab.{' '}
                                                  <br />
                                                  Please continue to the
                                                  'Instance of Care' tab. <br />
                                                  If you believe this is an
                                                  error, please reach out to a
                                                  FinPay administrator.
                                              </Typography>
                                          </div>
                                          <div
                                              style={{
                                                  visibility: patient?.patientId
                                                      ? undefined
                                                      : 'hidden',
                                              }}
                                          >
                                              {/* If the get patient data boundary check fails, we want to hide the demographics tab
                        but still want to render the component so all the logic within it runs and prevents things from breaking*/}
                                              <Demographics
                                                  currentPatient={patient}
                                                  openPatientModal={
                                                      triggerPatientModal
                                                  }
                                              />
                                          </div>
                                      </Grid>
                                  </>
                              )}
                              <Grid item xs={12}>
                                  {tabIndex === 1 && (
                                      <InstanceOfCare
                                          doesSelectedPatientEncounterExist={
                                              doesSelectedEncounterExist
                                          }
                                          doesInsuranceExist={
                                              doesInsuranceExist
                                          }
                                          doesChampionExist={doesChampionExist}
                                          doesPaymentProgramExist={
                                              doesPaymentProgramExist
                                          }
                                          shouldReFetchTransactions={
                                              shouldReFetchTransactions
                                          }
                                          shouldReFetchPaymentMethods={
                                              shouldReFetchPaymentMethods
                                          }
                                          doesDownPaymentExist={
                                              doesDownPaymentExist
                                          }
                                          doesRecurringPaymentExist={
                                              doesRecurringPaymentExist
                                          }
                                          isLoadingTransactions={
                                              isLoadingTransactions
                                          }
                                          isLoadingPaymentMethods={
                                              isLoadingPaymentMethods
                                          }
                                          handleDisableConvert={
                                              handleDisableConvert
                                          }
                                      />
                                  )}
                              </Grid>
                          </Grid>
                          <PatientModal
                              open={isPatientModalOpen}
                              isEdit={true}
                              handleModalCancel={handleModalCancel}
                              handleModalSubmit={handleModalSubmit}
                          />
                      </>
                  ) : (
                      <LoadingOverlay />
                  )}
              </div>
          </main>
          {patientEncounters && footerButtons}
          <ConvertErrorHandlerModal
              selectedPatientTab={tabIndex}
              handleModalCancel={handleConvertModalCancel}
              isOpen={isMissingReqModalOpen}
              requirementList={convertRequirementsList}
              isLoadingStatus={isLoading || isLoadingIOC}
          />
          <SendToMobileQueueErrorModal //todo : Need to make a generic component for ConvertErrorHandlerModal and SendToMobileErrorHandlerModal
              isOpen={isSendToMobileQueueErrorModalOpen}
              handleModalCancel={handleSendToMobileQueueModalCancel}
              requirementList={sendToMobileQueueRequirementsList}
          />
          {openPaymentModal
            && <TakePaymentModal paymentData={paymentData} open={openPaymentModal} handleModalCancel={handlePaymentModalCancel} />
          }
      </div>
  );
}
export default PatientDetailsView;
