import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {axiosSaveHelper} from '../../shared/service/axios-save-helper';
import {AxiosSavePayload} from '../../shared/service/axios-save-payload';
import {axiosReadHelper} from '../../shared/service/axios-read-helper';
import {AxiosReadPayload} from '../../shared/service/axios-read-payload';
import {vobPatientPostBody} from '../models/patient';
import {
  PatientViewModel,
} from '../../patient/components/models/patient-view-model';
import {EstLevelOfCare, FacilityLocRlos} from '../models/estimator';
import {Estimate as NewEstimate} from "@finpay/estimation-types"
import {AxiosDeletePayload} from '../../shared/service/axios-delete-payload';
import {axiosDeleteHelper} from '../../shared/service/axios-delete-helper';

class AdmissionsAdvisorService {
  async saveVobPatient(
    newPatientParam: vobPatientPostBody
  ): Promise<AxiosResultStatus> {
    let newPatientPayload: any = { ...newPatientParam };
    delete newPatientPayload.advisorPatientId;
    const payload: AxiosSavePayload = {
      dataToSave: newPatientPayload,
      dataId: -2,
      url: `patient/v2/advisorpatient`,
    };

    return await axiosSaveHelper(payload);
  }

  async getVobPatient(
    paramId: number,
    patientId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: `patient/v2/advisorpatient/${patientId}`,
    };
    return axiosReadHelper(payload);
  }

  async updateVobPatient(
    newPatientParam: vobPatientPostBody
  ): Promise<AxiosResultStatus> {
    let newPatientPayload: any = { ...newPatientParam };
    delete newPatientPayload.advisorPatientId;
    const payload: AxiosSavePayload = {
      dataToSave: newPatientPayload,
      dataId: -2,
      isPatch: true,
      url: `patient/v2/advisorpatient/${newPatientParam.advisorPatientId}`,
    };

    return await axiosSaveHelper(payload);
  }

  async updateFinPassPatient(
    patient: PatientViewModel,
    advisorPatientId: number,
    patientId: number | undefined
  ): Promise<AxiosResultStatus> {
    let newPatientPayload: any = {
      ...patient,
      advisorPatientId: advisorPatientId,
    };

    const payload: AxiosSavePayload = {
      dataToSave: newPatientPayload,
      dataId: -2,
      isPatch: true,
      url: `patient/v2/patient/${patientId}`,
    };

    const result = await axiosSaveHelper(payload);
    return result;
  }

  async saveFinPassPatient(
    patient: PatientViewModel,
    advisorPatientId: number
  ): Promise<AxiosResultStatus> {
    let newPatientPayload: any = {
      ...patient,
      advisorPatientId: advisorPatientId,
    };
    // delete newPatientPayload.finPassPatientId;
    const payload: AxiosSavePayload = {
      dataToSave: newPatientPayload,
      dataId: -2,
      url: `patient/v2/patient`,
    };

    const result = await axiosSaveHelper(payload);
    return result;
  }

  async newVob(newVOBParam: any): Promise<AxiosResultStatus> {
    let newVobPayload: any = { ...newVOBParam };
    const payload: AxiosSavePayload = {
      dataToSave: newVobPayload,
      dataId: -2,
      url: `vob/v2/vob`,
    };

    const result = await axiosSaveHelper(payload);
    return result;
  }

  async updateVob(
    vobId: number,
    updateVOBParam: any
  ): Promise<AxiosResultStatus> {
    let newVobPayload: any = { ...updateVOBParam };
    const payload: AxiosSavePayload = {
      dataToSave: newVobPayload,
      dataId: -2,
      isPatch: true,
      url: `vob/v2/vob/${vobId}`,
    };

    const result = await axiosSaveHelper(payload);
    return result;
  }

  async getVob(paramId: number, vobId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: `vob/v2/vob/${vobId}`,
    };
    return axiosReadHelper(payload);
  }

  async getVobClassifications(paramId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: "configuration/v2/configuration/vobclassification",
    };
    return axiosReadHelper(payload);
  }

  async getEstimatorLevelsOfCare(
    paramId: number,
    facilityId: number,
    planId: number
  ): Promise<AxiosResultStatus> {
    const path = `clients/v2/client/facility/${facilityId}/plan/${planId}/loc`;
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async getClientLevelsOfCare(
    paramId: number,
    clientId: number
  ): Promise<AxiosResultStatus> {
    const path = `clients/v2/client/${clientId}/levelofcare`;
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async addLevelofCare(
    levelOfCare: EstLevelOfCare
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: levelOfCare,
      dataId: 0,
      url: `clients/v2/facility/${levelOfCare.clientFacilityId}/levelofcare`,
    };
    const result = await axiosSaveHelper(payload);
    return result;
  }

  async updateLevelofCare(
    paramId: number,
    levelOfCare: EstLevelOfCare
  ): Promise<AxiosResultStatus> {
    const { cfgLevelOfCareId, clientFacilityId, ...updateLevelOfCare } =
      levelOfCare;
    const payload: AxiosSavePayload = {
      dataToSave: updateLevelOfCare,
      dataId: paramId,
      isPatch: true,
      url: `clients/v2/facility/${levelOfCare.clientFacilityId}/levelofcare/${levelOfCare.cfgLevelOfCareId}`,
    };
    let result: any = await axiosSaveHelper(payload);
    return result;
  }

  async deleteLevelofCare(
    paramId: number,
    levelOfCare: EstLevelOfCare
  ): Promise<AxiosResultStatus> {
    const deletePayload: AxiosDeletePayload = {
      dataId: paramId,
      url: `clients/v2/facility/${levelOfCare.clientFacilityId}/levelofcare/${levelOfCare.facilityLevelOfCareId}`,
    };
    return await axiosDeleteHelper(deletePayload);
  }

  async addRLOS(
    rlos: FacilityLocRlos
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: rlos,
      dataId: 0,
      url: `clients/v2/facility/${rlos.clientFacilityId}/rlos`,
    };
    const result = await axiosSaveHelper(payload);
    return result;
  }

  async updateRLOS(
    paramId: number,
    rlos: FacilityLocRlos
  ): Promise<AxiosResultStatus> {
    const { facilityLocRlosId, clientFacilityId, ...updateRLOS } = rlos;
    const payload: AxiosSavePayload = {
      dataToSave: updateRLOS,
      dataId: paramId,
      isPatch: true,
      url: `clients/v2/facility/${clientFacilityId}/rlos/${facilityLocRlosId}`,
    };
    let result: any = await axiosSaveHelper(payload);
    return result;
  }

  async deleteRLOS(
    paramId: number,
    rlos: FacilityLocRlos
  ): Promise<AxiosResultStatus> {
    const deletePayload: AxiosDeletePayload = {
      dataId: paramId,
      url: `clients/v2/facility/${rlos.clientFacilityId}/rlos/${rlos.facilityLocRlosId}`,
    };
    return await axiosDeleteHelper(deletePayload);
  }

  async getEstimatorFacilityLocPayers(
    paramId: number,
    facilityId: number
  ): Promise<AxiosResultStatus> {
    const path = `clients/v2/client/facility/${facilityId}/plan/loc`;
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async getEstimations(paramId: number): Promise<AxiosResultStatus> {
    const path = `estimation/v2/estimation`;
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async getEstimate(
    paramId: number,
    estimationId: number
  ): Promise<AxiosResultStatus> {
    const path = `estimation/v2/estimation/${estimationId}`;
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async newEstimation(newEstimationParam: any): Promise<AxiosResultStatus> {
    let newEstimationPayload: any = { ...newEstimationParam };
    delete newEstimationPayload.paramId;
    if (newEstimationPayload.estimateId) {
      delete newEstimationPayload.estimateId;
    }
    const payload: AxiosSavePayload = {
      dataToSave: newEstimationPayload,
      dataId: newEstimationParam.paramId,
      url: `estimation/v2/estimation`,
    };
    return await axiosSaveHelper(payload);
  }

  async updateEstimation(newEstimationParam: any): Promise<AxiosResultStatus> {
    let newEstimationPayload: any = { ...newEstimationParam };
    delete newEstimationPayload.paramId;
    if (newEstimationPayload.estimateId) {
      delete newEstimationPayload.estimateId;
    }
    const payload: AxiosSavePayload = {
      dataToSave: newEstimationPayload,
      dataId: newEstimationParam.paramId,
      isPatch: true,
      url: `estimation/v2/estimation/${newEstimationParam.estimateId}`,
    };
    return await axiosSaveHelper(payload);
  }

  async getAAPatientSearch(
    paramId: number,
    searchTerms: string,
    page: number,
    limit: number
  ): Promise<AxiosResultStatus> {
    const path = `search/v2/document?index=advisorPatient,patient&search=${searchTerms}&offset=${page}&limit=${limit}`;
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async getPatientSearch(
    paramId: number,
    searchTerms: string,
    page: number,
    limit: number
  ): Promise<AxiosResultStatus> {
    const path = `search/v2/document?index=all_patients&search=${searchTerms}&offset=${page}&limit=${limit}&applyTimingRiskFilter=true`;
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async createAAPatientIoc(
    patientEncounterToSave: Record<string, any>,
    patientEncounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: patientEncounterToSave,
      dataId: patientEncounterId,
      url: `patient-encounter/v2/patient/encounter?minor_version=1`,
    };
    return await axiosSaveHelper(payload);
  }

  async getCapacityToPay(
    paramId: number,
    income: number,
    zipCode: string,
    zipCode4: string
  ): Promise<AxiosResultStatus> {
    const path = `patient/v2/advisorpatient/capacitytopay?income=${income}&zipCode=${zipCode}&zipCode4=${zipCode4}`;
    const payload: AxiosSavePayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async getNationalPovertyThreshold(
    paramId: number,
    houseHoldSize: number,
    state: string,
    year: number
  ): Promise<AxiosResultStatus> {
    const path = `patient/v2/povertyguideline?householdSize=${houseHoldSize}&state=${state}&year=${year}`;

    const payload: AxiosSavePayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async getClientDetails(
    paramId: number,
    clientId: number
  ): Promise<AxiosResultStatus> {
    const path = `clients/v2/client/${clientId}/detail`;
    const payload: AxiosSavePayload = {
      dataId: paramId,
      url: path,
    };
    return axiosReadHelper(payload);
  }

  async getFacilityRiskThresholds(
    paramId: number,
    facilityId: number
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/facility/${facilityId}/risk-threshold`,
    };
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async getClientClaimRates(
    paramId: number,
    clientId: number
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/client/${clientId}/payor-claimrate`,
    };
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async getClientRiskClassSettings(
    paramId: number,
    clientId: number
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/client/${clientId}/risk-class-setting`,
    };
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async getFacilityRiskClassSettings(
    paramId: number,
    facilityId: number
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/facility/${facilityId}/risk-class-setting`,
    };
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async getPatientEpisodeMarginSettings(
    paramId: number,
    clientId: number
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/client/${clientId}/patient-episode-margin`,
    };
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async getFacilityReferralSourceRevenue(
    paramId: number,
    facilityId: number
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/facility/${facilityId}/referral-source-loc`,
    };
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async callNewEstimatePost(newEstimatePayload: NewEstimate): Promise<AxiosResultStatus> {
    
    const payload: AxiosSavePayload = {
      dataToSave: newEstimatePayload,
      dataId: 0,
      url: `estimation/v2/estimate`,
    };
    return await axiosSaveHelper(payload);
  } 
}

export const admissionsAdvisorService = new AdmissionsAdvisorService();
