import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@mui/material";
import { Button, GenericLevelOfCare, getFacilityLevelsOfCareSummary } from "@finpay-development/shared-components";
import { TableColumn } from "../../../../shared/model/table-column";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../shared/state/root-reducer";
import { AppDispatch } from "../../../../shared/state/store";
import {
  CrossoverPolicies,
  PriorCarePFR,
  emptyPFRSummary,
} from "../../../models/estimator";
import { LoadingOverlay } from "@finpay-development/shared-components";
import {
  clearEstimator,
} from "../../../state/estimator-slice";
import { clearPatient } from '../../../state/vob-patient-slice';
import { clearVOB } from '../../../state/vob-slice';
import { EstSummaryModalCrossover } from "./crossover-summary";
import { formatNumberToUSD } from "../../../../shared/calculators";
import { admissionsAdvisorUtils } from "../../../utils/admission-advisor-utils";
import _ from "lodash";
import { getVOBAndEstimateForFinpass } from "src/patient/state/patient-thunk";

interface EstimateSummaryModalProps {
  open: boolean;
  estimateId?: number;
  handleModalCancel: () => void;
}

export function EstimateSummaryModal(props: EstimateSummaryModalProps) {
  const { open, estimateId, handleModalCancel } = props;
  const [levelsOfCareSummary, setLevelsOfCareSummary] = useState<GenericLevelOfCare[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const paramId = -2;

  const state = {
    fetchedEstimateState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.fetchedEstimate
    ),
    fetchedEstimateLoadingState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading
          .isLoadingGetEstimate
    ),
    fetchedEstimateStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.getEstimateStatus
    ),
    // estimatorState: useSelector(
    //   (state: RootState) =>
    //     state.admissionsAdvisorContext.estimatorContext.estimator
    // ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.patientContext.selectedEstimate.estimator
    ),
    vobState: useSelector(
      (state: RootState) => state.patientContext.selectedVOB
    ),
    // vobState: useSelector(
    //   (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    // ),
    facilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.facilities
    ),
    allClients: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.allClients
    ),
  };
  const {
    estimatorState,
    vobState,
    facilities,
    allClients
  } = state;

  useEffect(() => {
    // const config: EstimateRequestConfig = {
    //   paramId: paramId,
    //   estimationId: estimateId!,
    // };
    setIsLoading(true)
    dispatch(
      getVOBAndEstimateForFinpass({
          estimateId: estimateId!,
      })
  );
  setIsLoading(false)
    // dispatch(getEstimate(config));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLevelsOfCareSummary(
      getFacilityLevelsOfCareSummary(
        estimatorState.selectedLevelsOfCare,
        estimatorState.quoteMethod,
        vobState?.payer?.inNetwork!,
        vobState?.selfPay!
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState, vobState]);

  // useEffect(() => {
	// 	// load estimate and vob into state
  //   setIsLoading(true);
  //   if (!fetchedEstimateLoadingState && fetchedEstimateStatusState === apiStatus.success) {
  //     const vobConfig: any = {
  //       paramId: paramId,
  //       vobId: fetchedEstimateState?.vobId,
  //     };
  //     dispatch(getVob(vobConfig));
  //     const estimatorStateCopy = { ...fetchedEstimateState.estimateBody };
  //     if (estimatorStateCopy) {
  //       estimatorStateCopy.pfrEstimateId = fetchedEstimateState?.pfrEstimateId;
  //       dispatch(clearEstimator());
  //       if (estimatorStateCopy.client?.clientId) {
  //         dispatch(getClientFacilities(estimatorStateCopy.client?.clientId));
  //       }
  //       dispatch(setEstimator(estimatorStateCopy));
	// 			setIsLoading(false);
  //     }
  //     dispatch(clearGetEstimateStatus());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchedEstimateLoadingState]);

  const locTableHeaders: TableColumn[] = [
    { id: "colCode", label: "Code" },
    { id: "colLocName", label: "Level of Care" },
    { id: "colLocDays", label: "Days/Sessions" },
    { id: "colLocDays", label: "Rate per Day" },
    { id: "colCovered", label: "Covered" },
  ];

  const getQuoteMethod = (value: string) => {
    let quoteMethod: string = "";
    const quoteMethods = [
      { name: "Average Length of Stay", value: "avglos" },
      { name: "Recommended Length of Stay", value: "rlos" },
      { name: "Manual", value: "manual" },
      { name: "Single Case Agreement", value: "sca" },
    ];
    const selectedQuoteMethod = quoteMethods.find(
      (quoteMethod) => quoteMethod.value === value
    );
    if (selectedQuoteMethod) {
      quoteMethod = selectedQuoteMethod.name;
    }
    return quoteMethod;
  };

  function handleCancelCallback() {
    dispatch(clearPatient());
    dispatch(clearVOB());
    dispatch(clearEstimator());
    handleModalCancel();
  }

  function adjustedChgs() {
    let charges = 0.0;
    levelsOfCareSummary.forEach((loc) => {
      if(loc.cfgLocType === 1) return
      charges += loc?.rate * loc?.days;
    });
    return formatNumberToUSD(charges);
  }

  function getMissingClientName(clientId: number) {
    const selectedClient = allClients.find(client => client.clientId === clientId);
    return selectedClient?.clientName!;
  };

  function estimatedLos() {
    let los = 0;
    levelsOfCareSummary.forEach((loc) => {
      if(loc.cfgLocType === 1) return
      los += loc?.days;
    });
    return los;
  }

  function NormalPfr(props: { hasCrossover: boolean }) {
    const { hasCrossover } = props;
    const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(
      estimatorState,
      vobState
    );

    console.log('financialSummary :>> ', financialSummary); 

    return (
      <>
        <Typography variant="subtitle2" className="mt-2">
          PFR Details {hasCrossover && <> (with Crossover)</>}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" className="alternating-row">
            <TableBody>
              <TableRow className="py-3">
                <TableCell>Estimated Co-Pay</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(financialSummary.patientTotalCoPays)}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Estimated Deductible</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(financialSummary.deductibleSpent)}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Estimated Co-Insurance</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(financialSummary.patientTotalCoInsurance)}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell></TableCell>
                <TableCell align="right" sx={{ borderTop: "2px solid #999" }}>
                  <strong>
                    {formatNumberToUSD(financialSummary.coveredSvcsPfr)}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Out of Pocket Max Remaining</TableCell>
                <TableCell align="right">
                  {financialSummary.remainingOopMaxFromVob !== -1?
                   formatNumberToUSD(financialSummary.remainingOopMaxFromVob)
                  :
                  <>N/A</>
                  }
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>PFR for Covered Services</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(financialSummary.netPFR)}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Uncovered Services</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(financialSummary.unCoveredCharges)}
                </TableCell>
              </TableRow>
              {financialSummary.totalSurcharges > 0 && (
                <>
                  <TableRow className="py-3">
                    <TableCell>PFR Subtotal</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(financialSummary.subtotalPFR)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>Admission Assessment</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(financialSummary.totalSurcharges)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow className="py-3">
                <TableCell>
                  <Typography variant="subtitle2">
                    Estimated Patient Financial Responsibility
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {formatNumberToUSD(financialSummary.totalPFR)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  function PriorCare() {
    const priorLevelsOfCareSummary =
      admissionsAdvisorUtils.getPriorLevelsOfCare(estimatorState?.priorCare);
    const genericPriorLoc = admissionsAdvisorUtils.getGenericPriorAdjChg(
      priorLevelsOfCareSummary,
      estimatorState,
      vobState
    );
    const priorCareAdjustment: PriorCarePFR =
      admissionsAdvisorUtils.getPriorCareAdjChg(estimatorState, vobState);
    function getDeductibleSpentPct() {
      let spentPct: string =
        (
          (priorCareAdjustment?.priorCarePFR.deductibleSpent /
            priorCareAdjustment?.priorCarePFR.deductible) *
          100
        ).toFixed(0) + "%";
      return spentPct;
    }

    function adjustedPriorChgs() {
      let charges = 0.0;
      genericPriorLoc.forEach((loc) => {
        charges += loc?.rate * loc?.days;
      });
      return formatNumberToUSD(charges);
    }

    function estimatedPriorLos() {
      let los = 0;
      genericPriorLoc.forEach((loc) => {
        los += loc?.days;
      });
      return los;
    }

    function getOopSpentPct() {
      let spentPct: string = "N/A";
      if (priorCareAdjustment?.priorCarePFR?.includeOOPmax) {
        spentPct =
        (
          ((priorCareAdjustment?.priorCarePFR?.oopMaxFromVob -
            priorCareAdjustment?.priorCarePFR?.remainingOopMaxFromVob) /
            priorCareAdjustment?.priorCarePFR?.oopMaxFromVob) *
          100
        ).toFixed(0) + "%";
      }
      return spentPct;
    }

    return (
      <>
        <Typography variant="subtitle2" className="mt-4">
          Estimated Prior Care Adjustment Details
        </Typography>
        <Grid container direction="row">
          <Grid item direction="column" md={5}>
            <Typography variant="body2">
              {priorLevelsOfCareSummary.labelOne}
            </Typography>
            {priorLevelsOfCareSummary.valueOne}
          </Grid>
          <Grid item direction="column" md={5}>
            <Typography variant="body2">
              {priorLevelsOfCareSummary.labelTwo}
            </Typography>
            {priorLevelsOfCareSummary.valueTwo}
          </Grid>
        </Grid>
        <LocTable levelsOfCare={genericPriorLoc} />
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow className="py-3 light-green-row">
                <TableCell>Estimated Prior Care Adjusted Charges</TableCell>
                <TableCell align="right">{adjustedPriorChgs()}</TableCell>
              </TableRow>
              <TableRow className="py-3 light-green-row">
                <TableCell>Estimated Prior Care Length of Stay</TableCell>
                <TableCell align="right">{estimatedPriorLos()}</TableCell>
              </TableRow>
              <TableRow className="py-3 light-green-row">
                <TableCell>{`Estimated Prior Care impact on Deductible (${getDeductibleSpentPct()})`}</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    priorCareAdjustment?.priorCarePFR?.deductibleSpent
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="py-3 light-green-row">
                <TableCell>{`Estimated Prior Care impact on OOP (${getOopSpentPct()})`}</TableCell>
                <TableCell align="right">
                  {priorCareAdjustment?.priorCarePFR?.includeOOPmax?
                    formatNumberToUSD(
                      priorCareAdjustment?.priorCarePFR?.oopMaxFromVob -
                        priorCareAdjustment?.priorCarePFR?.remainingOopMaxFromVob
                    )
										:
										<>N/A</>
									}
                </TableCell>
              </TableRow>
              <>
                  <TableRow className="py-3">
                    <TableCell>Adjusted PFR Subtotal</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(priorCareAdjustment.adjustedCurrentPFR.subtotalPFR)}
                    </TableCell>
                  </TableRow>
                  {priorCareAdjustment.adjustedCurrentPFR.totalSurcharges > 0 && (
                    <TableRow className="py-3">
                      <TableCell>Admission Assessment</TableCell>
                      <TableCell align="right">
                        {formatNumberToUSD(priorCareAdjustment.adjustedCurrentPFR.totalSurcharges)}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              <TableRow className="py-3">
                <TableCell>
                  <Typography variant="subtitle2">
                    Estimated Patient Financial Responsibility
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {formatNumberToUSD(
                      priorCareAdjustment.adjustedCurrentPFR?.totalPFR
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  function CrossOverPfr() {
    const crossoverPolicies: CrossoverPolicies =
      admissionsAdvisorUtils.calculateCrossoverPfr(
        estimatorState?.planYearCrossover?.crossoverDays!,
        estimatorState,
        vobState
      );
    return (
      <>
        {estimatorState.planYearCrossover?.usingPlanYearCrossoverPfr ? (
          <>
            <Typography variant="subtitle2" className="mt-4">
              Plan Year Crossover PFR Adjustment
            </Typography>
            <EstSummaryModalCrossover
              currentYearPFR={crossoverPolicies.currentPolicyFinancialSummary}
              crossOverPFR={crossoverPolicies.nextPolicyFinancialSummary}
            />
          </>
        ) : (
          <NormalPfr hasCrossover={true} />
        )}
      </>
    );
  }

  function FinAssistPFR() {
    // calculate PFR for plan crossover
    let crossoverPolicies: CrossoverPolicies = {
      currentPolicyFinancialSummary: emptyPFRSummary,
      nextPolicyFinancialSummary: emptyPFRSummary,
    };
    if (
      estimatorState.planYearCrossover?.usingPlanYearCrossoverPfr &&
      estimatorState.planYearCrossover?.planYearCrossoverConfirmed
    ) {
      crossoverPolicies = admissionsAdvisorUtils.calculateCrossoverPfr(
        estimatorState.planYearCrossover.crossoverDays,
        estimatorState,
        vobState
      );
    }
    const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(
      estimatorState,
      vobState
    );

    const showScholarshipPct = () => {
      let scholarship = 0;
      if (estimatorState?.financialAssistance?.scholarshipPercentage) {
        scholarship =
          estimatorState?.financialAssistance?.scholarshipPercentage * 100;
      }
      return scholarship.toFixed(0) + "%";
    };

    const showScholarshipAmt = (pfr: number) => {
      let scholarshipPct = 1;
      if (estimatorState?.financialAssistance?.scholarshipPercentage) {
        scholarshipPct =
          estimatorState?.financialAssistance?.scholarshipPercentage;
      }
      return formatNumberToUSD(pfr * scholarshipPct);
    };

    const pfrAdjWithCrossover = () => {
      const scholarshipAmt =
        (crossoverPolicies?.currentPolicyFinancialSummary?.totalPFR! +
          crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR!) *
        estimatorState?.financialAssistance?.scholarshipPercentage!;
      return formatNumberToUSD(
        crossoverPolicies?.currentPolicyFinancialSummary?.totalPFR! +
          crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR! -
          scholarshipAmt
      );
    };

    const pfrAdjustment = () => {
      const scholarshipAmt =
        financialSummary.totalPFR *
        estimatorState?.financialAssistance?.scholarshipPercentage!;
      return formatNumberToUSD(financialSummary.totalPFR - scholarshipAmt);
    };

    return (
      <>
        <Typography variant="subtitle2" className="mt-4">
          Estimated PFR After Financial Assistance
        </Typography>

        <TableContainer component={Paper}>
          <Table size="small" className="alternating-row">
            <TableBody>
              {estimatorState.planYearCrossover?.usingPlanYearCrossoverPfr &&
              estimatorState.planYearCrossover?.planYearCrossoverConfirmed ? (
                <>
                  <TableRow className="py-3">
                    <TableCell>Crossover Year PFR</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(
                        crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>Current Year PFR</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(
                        crossoverPolicies?.currentPolicyFinancialSummary
                          ?.totalPFR
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>PFR Subtotal</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(
                        crossoverPolicies?.nextPolicyFinancialSummary
                          ?.totalPFR! +
                          crossoverPolicies?.currentPolicyFinancialSummary
                            ?.totalPFR!
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>
                      Financial Assistance Scholarship ({showScholarshipPct()})
                    </TableCell>
                    <TableCell align="right">
                      {showScholarshipAmt(
                        crossoverPolicies?.nextPolicyFinancialSummary
                          ?.totalPFR! +
                          crossoverPolicies?.currentPolicyFinancialSummary
                            ?.totalPFR!
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>
                      <Typography variant="subtitle2">
                        PFR After Financial Assistance
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        {pfrAdjWithCrossover()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  <TableRow className="py-3">
                    <TableCell>Total Patient PFR</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(financialSummary.totalPFR)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>
                      Financial Assistance Scholarship ({showScholarshipPct()})
                    </TableCell>
                    <TableCell align="right">
                      {showScholarshipAmt(financialSummary.totalPFR)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                <TableCell>PFR After Financial Assistance</TableCell>
                <TableCell align="right">
								{pfrAdjustment()}
                </TableCell>
              </TableRow>
              {financialSummary.scholarshipAdjustedSurcharge > 0 && (
                  <TableRow className="py-3">
                    <TableCell>Admission Assessment</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(financialSummary.scholarshipAdjustedSurcharge)}
                    </TableCell>
                  </TableRow>
                )}
                  <TableRow className="py-3">
                <TableCell><Typography variant="subtitle2">Total PFR</Typography></TableCell>
                <TableCell align="right">
								<Typography variant="subtitle2">{pfrAdjustment()}</Typography>
                </TableCell>
              </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  function LocTable(props: { levelsOfCare: GenericLevelOfCare[] }) {
    const { levelsOfCare } = props;

    return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {locTableHeaders.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.sortBy(levelsOfCare, "name")
              .filter((loc:GenericLevelOfCare)=>{
                if(loc.cfgLocType === 1) return false
                return true;
              }).map(
                (row: GenericLevelOfCare, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row?.code}</TableCell>
                      <TableCell>
                        {row?.name}
                        <div style={{ fontSize: "8pt" }}>
                          {admissionsAdvisorUtils.getFacilityName(
                            row?.facilityId,
                            facilities
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{row?.days}</TableCell>
                      <TableCell>{row?.rate}</TableCell>
                      <TableCell>{row?.covered ? <>Yes</> : <>No</>}</TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Estimate Details</DialogTitle>
      <DialogContent>
        <>
          {isLoading ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <LoadingOverlay />
            </Grid>
          ) : (
            <>
              <Grid container direction="row" spacing={2}>
                <Grid item direction="column" md={6}>
                  <Typography variant="body2">Client</Typography>
                  {estimatorState?.client?.clientName ? estimatorState?.client?.clientName : getMissingClientName(estimatorState?.client?.clientId!)}
                </Grid>
                <Grid item direction="column" md={6}>
                  <Typography variant="body2">Facility</Typography>
                  {estimatorState?.facility?.facilityName}
                </Grid>
                <Grid item direction="column" md={6}>
                  <Typography variant="body2">Quote Method</Typography>
                  {getQuoteMethod(estimatorState?.quoteMethod!)}
                </Grid>
                <Grid item direction="column" md={6}>
                  <Typography variant="body2">Service Level</Typography>
                  {estimatorState?.serviceLevel}
                </Grid>
                <Grid item direction="column" md={12}>
                  <Typography variant="body2">
                    Anticipated Admission Date
                  </Typography>
                  {new Date(estimatorState?.admissionDate!).toLocaleDateString(
                    "en-US",
                    { timeZone: "UTC" }
                  )}
                </Grid>
              </Grid>

              {levelsOfCareSummary?.length > 0 && (
                <>
                  <Typography variant="subtitle2" className="mt-4">
                    Care Plan Details
                  </Typography>
                  <LocTable levelsOfCare={levelsOfCareSummary} />
                  <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                      <TableBody>
                        <TableRow className="py-3 light-green-row">
                          <TableCell>Estimated Adjusted Charges</TableCell>
                          <TableCell align="right">{adjustedChgs()}</TableCell>
                        </TableRow>
                        <TableRow className="py-3 light-green-row">
                          <TableCell>Estimated Length of Stay</TableCell>
                          <TableCell align="right">{estimatedLos()}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {
                {
                  "Total Estimated PFR": <NormalPfr hasCrossover={false} />,
                  "Prior Care Adjusted PFR": <PriorCare />,
                  "Plan Crossover": <CrossOverPfr />,
                  "Financial Assistance PFR": <FinAssistPFR />,
                }[estimatorState.selectedPFRName!]
              }
            </>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancelCallback()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
