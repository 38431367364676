import '../../scss/components/mobile-payments.scss';
import { Button } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

interface MobilePaymentSPAModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
  }

export function MobilePaymentSPAModal(props: MobilePaymentSPAModalProps) {

    const { isOpen, handleModalCancel } = props;

    return (
        <Dialog
            scroll="paper"
            className="modal user-modal mobile-modal"
            open={isOpen}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
        >
            <DialogTitle className="spa-modal-title">
                Single Payment Agreement
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="row" >
                    <Grid item direction="column" md={12} xs={12} className="spa-content">               
                        <p>Effective on the date of payment, the Patient/Guarantor agrees and promises to pay the Provider all patient financial responsibility (“PFR”) as summarized in this transaction.</p>
                        <p><strong>DEFINITIONS.</strong><br />(a) “Governing Agreements” means any Credit Card Authorization, Authorization, Disclosure, FinPay Website User Agreement, and all other documents or disclosures at any time executed in connection herewith or therewith or related hereto or thereto or otherwise related to the services provided to the Patient by the Provider and evidenced by this Agreement, or any prior Agreements in each case as the same may have been amended, modified, supplemented, extended, renewed, restated or replaced from time to time.</p>
                        <p>(b) “FinPay” shall mean FinPay, LLC, which is the owner of the FinPass Platform and the patient financial management company chosen by the Patient, Guarantor, and Provider to facilitate and manage payments made under this agreement.</p>
                        <p>(c)“Patient” shall mean the individual receiving healthcare services from the Provider.</p>
                        <p>(d)“Provider” shall mean the individuals, organizations, and facilities providing medical and healthcare services to the Patient.</p>
                        <p>(e) “Guarantor” shall mean the individual(s) accepting financial responsibility for the charges and costs associated with services provided to the Patient by the Provider.</p>
                        <p>(f) “Down Payment” shall mean any payment towards the PFR, in whole or in part, made on or about the Effective date.</p>
                        <p>(g) “Payment Program” means any payment program (single payment, monthly installment plan, weekly Pay As You Go Plan, or medical loan) facilitated and managed by FinPay on behalf of the Patient, the Guarantor, and the Provider to process the payments of the PFR owed by the Patient and Guarantor, as applicable, to the Provider.</p>
                        <p>(h)“PFR” shall mean all patient financial responsibility, liabilities and indebtedness of every kind, nature and description owed by the Patient and Guarantor, as applicable, to Provider for medical services rendered, including any principal balance after insurance, applicable interest, charges, fees, costs, expenses, and self-pay amounts however evidenced, arising under this Agreement or the Governing Agreements.</p>
                        <p><strong>PAYMENT AUTHORIZATION.</strong> Patient and Guarantor, as applicable, hereby authorize FinPay to process a single payment for the PFR Amount. Patient and Guarantor, as applicable, acknowledge that the PFR Amount is an estimate based on the levels of care and length of stay for the services the Patient is anticipated to receive. This estimate is subject to change. The actual total cost for services will be summarized in the patient billing statement, which will reflect any changes to the level of care, length of stay, or the utilization of health insurance benefits, if applicable. Applicable refunds will not be processed until the final patient billing statement is determined, and if applicable, until all claims have settled with any third-party health insurance company(s).</p>
                        <p><strong>APPLICATION OF PAYMENTS.</strong> Monies received by Patient/Guarantor from any source for application toward payment of the PFR shall be applied to the PFR in any manner or order deemed appropriate by Provider in its sole and absolute discretion, including; (i) Insurance related costs (Deductible(s), Copay(s), Coinsurance , (ii)Self-Pay Costs associated with ancillary or uncovered services, premiums or travel related charges, and (iii) Fees or non-refundable charges for Missed session(s), appointment(s), late cancellations, admission assessments, or late payments.</p>
                        <p><strong>NON-REFUNDABLE CHARGES.</strong> Patient and Guarantor, as applicable, acknowledge and agree that Provider may, at its sole discretion, charge a non- refundable fee for missed session(s), appointment(s), or late cancellation(s). Any waiver(s) of any non-refundable charge(s) shall not be deemed a waiver of Provider’s right to collect such non-refundable charge or to collect a non- refundable charge for any subsequent missed session(s), appointment(s), or late cancellation(s). Patient and Guarantor, as applicable, acknowledge and agree that any admissions assessment fee that is included in the estimate provided herein may be refundable, at the discretion of Provider, once the Patient is formally admitted as a patient at the Provider’s Healthcare Facility(s). If the Patient fails to be admitted, the admission assessment fee is not refundable and may be set off against any other refunds owed to the Patient or Guarantor, as applicable, by the Provider.</p>
                        <p><strong>PFR ADJUSTMENTS:</strong> Patient/Guarantor, as applicable, acknowledges that the initial PFR Amount, is based on an estimate of Patient’s</p>
                        <p>PFR for healthcare services with Provider and subject to adjustment. Patient and Guarantor, as applicable, acknowledge that the PFR Amount may increase if there is a change to the level(s) of care, duration of services, the purchase of additional medical services, or due to uncovered ancillary services. Patient/Guarantor, as applicable, hereby authorizes Provider to auto adjust (credit or debit) the PFR Amount, once the final patient billing statement is determined, or if applicable, upon the final adjudication of any health insurance claim(s) with Provider. In the event there is an increase of the PFR Amount under this agreement, Patient and Guarantor, as applicable, agree to pay Provider any increased PFR Amount in full or to contact FinPay to determine eligibility for pre-authorized installment programs.</p>
                        <p>Provider will give Patient and Guarantor, as applicable, notice of adjustments to the PFR Amount. Unless Patient/Guarantor contacts FinPay to make alternative payment arrangements upon receiving notice, the increased PFR Amount is due in full.</p>
                        <p>Patient and Guarantor, as applicable, have given Provider a valid email address where Provider shall send notice of any adjustments to the PFR Amount (credit or debit) and Provider shall not be responsible if the email account provided to Provider is changed or canceled without prior written notification toProvider. Patient and Guarantor, as applicable, have access to a FinPay Account on the FinPass Platform where Patient and Guarantor, as applicable, can manage the payment program, make changes to linked funding sources, and make requests to change the payment program terms. Patient and Guarantor, as applicable, agree that Provider may extend, modify or renew this Agreement or make a novation of the Governing Agreements, evidenced by this Agreement, without notice to or consent of Patient or Guarantor, as applicable, and other such person, and without affecting the liability of the Patient or Guarantor, as applicable, and other such person.</p>
                        <p><strong>MISCELLANEOUS PROVISIONS.</strong><br /><strong>Plural; Captions.</strong> All references in the Governing Agreements to Patient/Guarantor, guarantor, person, document, or other nouns of reference mean both the singular and plural form, as the case may be, and the term “person” shall mean any individual, person or entity. The captions contained in the Governing Agreements are inserted for convenience only and shall not affect the meaning or interpretation of the Governing Agreements.</p>
                        <p><strong>Posting of Payments.</strong> All payments received during normal banking hours after 2:00 p.m. local time at the address for payments set forth above shall be deemed received at the opening of the next banking day. </p>
                        <p><strong>Joint and Several PFR.</strong> The Patient and Guarantor are jointly and severally obligated together with all other parties obligated for the PFR.</p>
                        <p><strong>Final Agreement</strong>. This Agreement and the other Governing Agreements represent the final agreement between the parties and may not be contradicted by evidence of prior, contemporaneous or subsequent agreements of the parties. There are no unwritten agreements between the parties.</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ borderTop: "", marginTop: "0", padding: "2rem" }}>
                <Button onClick={handleModalCancel}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

